/*
 * IMPORTS
 */
import { gql } from '@apollo/client' // Npm: Apollo client for handling graphql request.


/*
 * EXPORTS
 */
export default gql`
mutation ContentTranslationCreateMutation(
  $smppId: ID!,
  $regex: String!, 
  $translationKeyword: String!,
  $strategy: CONTENT_TRANSLATION_STRATEGY!
) {
  ContentTranslationCreate(
    smppId: $smppId,
    regex: $regex,
    translationKeyword: $translationKeyword,
    strategy: $strategy
  ) {
    id
    message
    status
  }
}
`
