/*
 * IMPORTS
 */
import { gql } from '@apollo/client' // Npm: Apollo client for handling graphql request.


/*
 * EXPORTS
 */
export default gql`
mutation RouteCreateMutation($displayName: String!) {
  RouteCreate(displayName: $displayName) {
    message
    status
  }
}
`
