/*
 * IMPORTS
 */
import 'react-date-range/dist/styles.css' // Npm: react date range module.
import 'react-date-range/dist/theme/default.css' // Npm: React date range module.
import React from 'react' // Npm import: React.js library.
import PropTypes from 'prop-types' // Npm import: Prop types validator.
import { endOfMonth, startOfMonth, sub, subDays } from 'date-fns' // Npm: date-fns library for handling time.
import { BiChevronDown } from 'react-icons/bi' // Npm import: React icons.
import {
  Button,
  Flex,
  Menu,
  MenuButton,
  MenuList,
  Skeleton
} from '@chakra-ui/react' // Npm import: Chakra UI components.


/*
 * SIBLINGS
 */
import Calendar from './Calendar'


/*
 * STYLES
 */
import './index.style.css'


/*
 * OBJECTS
 */
const Index = ({ setSelectDate, calendarOnly, loading }) => {
  // Hook assignment.
  const [turnOnCompare, setTurnOnCompare] = React.useState(false)
  const [state, setState] = React.useState({
    'selection': {
      'startDate': startOfMonth(new Date()),
      'endDate': endOfMonth(new Date()),
      'key': 'selection'
    }
  })

  // Object assignment.
  const _DateFormat = __state => {
    // Const assignment.
    const _startDate = new Date(__state?.selection?.startDate)
    const _endDate = new Date(__state?.selection?.endDate)

    // Formate date into shorthand style.
    const startFormatted = _startDate.toLocaleDateString('en-US', {
      'month': 'short',
      'day': 'numeric'
    })
    const endFormatted = _endDate.toLocaleDateString('en-US', {
      'month': 'short',
      'day': 'numeric'
    })


    // Return format.
    return `${startFormatted} -  ${endFormatted}, ${_endDate.getFullYear()}`
  }

  // Return component.
  return (
    <Flex alignItems='center' gap='1rem'>
      <Menu>
        {({ isOpen, onClose }) => (
          <>
            {loading ? (
              <Skeleton
                borderRadius='12px'
                width='200px'
                startColor='#ffffff'
                endColor='#f3f2f1'
                height='45px'
              />
            ) : (
              <MenuButton
                isActive={isOpen}
                bg='#F4F7FE'
                border='none'
                fontSize='12px'
                fontWeight='700'
                textAlign='left'
                id='edit_product'
                variant='main'
                h='28px'
                borderRadius='10px'
                as={Button}
                disabled={loading}
                color='#828797'
                rightIcon={<BiChevronDown />}>
                {_DateFormat(state)}
              </MenuButton>
            )}
            <MenuList padding={0} border='none' overflow='hidden' borderRadius={22} boxShadow='2xl' zIndex={2}>
              <Calendar
                setTurnOnCompare={setTurnOnCompare}
                turnOnCompare={turnOnCompare}
                onChange={item => setState({ ...state, ...item })}
                months={1}
                onClose={onClose}
                _handleSetDate={setSelectDate(state)}
                calendarOnly={calendarOnly}
                direction='vertical'
                scroll={true}
                ranges={[state.selection]}
                showDateDisplay={true}
              />
            </MenuList>
          </>
        )}
      </Menu>
    </Flex>
  )
}


/*
 * PROPTYPES
 */
Index.propTypes = {
  'calendarOnly': PropTypes.bool,
  'loading': PropTypes.bool,
  'setSelectDate': PropTypes.func
}


/*
 * EXPORTS
 */
export default Index
