/*
 * IMPORTS
 */
import React from 'react' // Npm: react.js library.
import PropTypes from 'prop-types' // Npm: Prop types for checking props type.
import { HiOutlineArrowSmallLeft, HiOutlineArrowSmallRight } from 'react-icons/hi2' // Npm: React icons.
import {
  Button,
  Flex
} from '@chakra-ui/react' // Npm: Chakra UI components.


/*
 * OBJECTS
 */
const Index = ({ totalCount = 0, itemsPerPage = 0, onPageChange }) => {
  // Hook assignment.
  const [currentPage, setCurrentPage] = React.useState(0)

  // Const assignment.
  const totalPages = Math.ceil(totalCount / itemsPerPage)

  // Object assignment.
  const _GoToPreviousPage = () => {
    // Check if current page is greater than 0.
    if (0 < currentPage) {
      // Update current page.
      setCurrentPage(currentPage - 1)

      // Call on page change.
      onPageChange(currentPage - 1)
    }
  }
  const _GoToNextPage = () => {
    // Check if current page is less than total pages.
    if (currentPage < totalPages - 1) {
      // Update current page.
      setCurrentPage(currentPage + 1)

      // Call on page change.
      onPageChange(currentPage + 1)
    }
  }

  // Return component.
  return (
    <Flex
      position='absolute'
      right='70px'
      bottom='70px'
      zIndex={100}
      mt='1rem'
      justify='flex-end'
      gap='20px'>
      <Button w='55px' h='55px' bg={0 === currentPage ? 'gray.200' : 'rgba(52, 18, 236)'} _hover={{ 'bg': 0 === currentPage ? 'gray.200' : 'rgba(52, 18, 236)' }} color={0 === currentPage ? 'black' : 'white'} borderRadius={100} onClick={_GoToPreviousPage}>
        <HiOutlineArrowSmallLeft size={21} />
      </Button>
      <Button w='55px' h='55px' bg={currentPage >= totalPages - 1 ? 'gray.200' : 'rgba(52, 18, 236)'} _hover={{ 'bg': currentPage >= totalPages - 1 ? 'gray.200' : 'rgba(52, 18, 236)' }} color={currentPage >= totalPages - 1 ? 'black' : 'white'} borderRadius={100} onClick={_GoToNextPage}>
        <HiOutlineArrowSmallRight size={27} />
      </Button>
    </Flex>
  )
}


/*
 * PROPTYPES
 */
Index.propTypes = {
  'onPageChange': PropTypes.func,
  'totalCount': PropTypes.number,
  'itemsPerPage': PropTypes.number
}


/*
 * EXPORT
 */
export default Index
