/*
 * IMPORTS
 */
import { gql } from '@apollo/client' // Npm: Apollo client for handling graphql request.


/*
 * EXPORTS
 */
export default gql`
mutation SmppUpdateMutation(
  $smppId: ID!,
  $displayName: String!,
  $ip: IPv4!,
  $username: String!,
  $password: String!,
  $port: PositiveInt!,
  $sessionTimeoutInterval: Float!,
  $sessionAllowed: Int,
  $tps: Int,
  $enquireLinkInterval: Float!,
  $sourceAddressTon: Int,
  $sourceAddressNpi: Int,
  $destinationAddressTon: Int,
  $destinationAddressNpi: Int
) {
  SmppUpdate(
    smppId: $smppId,
    displayName: $displayName,
    ip: $ip,
    username: $username,
    password: $password,
    port: $port,
    sessionTimeoutInterval: $sessionTimeoutInterval,
    sessionAllowed: $sessionAllowed,
    tps: $tps,
    enquireLinkInterval: $enquireLinkInterval,
    sourceAddressTon: $sourceAddressTon,
    sourceAddressNpi: $sourceAddressNpi,
    destinationAddressTon: $destinationAddressTon,
    destinationAddressNpi: $destinationAddressNpi
  ) {
    id,
    message
    status
  }
}
`
