/*
 * IMPORTS
 */
import React from 'react' // Npm: react.js library.
import PropTypes from 'prop-types' // Npm: Prop types for checking props type.
import _ from 'underscore' // Npm: utility module.
import Moment from 'moment' // Npm: Moment.js library for date and time formatting.
import { HiPlusCircle } from 'react-icons/hi2' // Npm: React icons.
import { connect } from 'react-redux' // Npm: React Redux for state management.
import { toast } from 'react-hot-toast' // Npm: React hot toast.
import { useMutation, useQuery } from '@apollo/client' // Npm: Apollo client for GraphQL queries and mutations.
import {
  Button,
  Flex,
  Spinner,
  Table,
  TableContainer,
  Tbody,
  Td,
  Text,
  Thead,
  Tooltip,
  Tr,
  useDisclosure
} from '@chakra-ui/react' // Npm: Chakra UI components.


/*
 * PACKAGES
 */
import TableSpinner from 'components/TableSpinner'
import DownloadToExcel from 'components/DownloadToExcel'
import Pagination from 'components/Pagination'
import Modal from 'components/Modal'
import VendorAccountAttachRoutePlan from 'components/VendorAccountAttachRoutePlan'
import RoutePlanUpsert from 'components/RoutePlanUpsert'
import RoutePlanDelete from 'components/RoutePlanDelete'
import RoutePlanDropDownOptions from 'components/RoutePlanDropDownOptions'


/*
 * GRAPHS
 */
import RoutePlanReadQuery from './__query__/index.routePlan.read.query'
import RoutePlanPlayPauseMutation from './__mutation__/index.routePlan.playPause.mutation'


/*
 * STYLES
 */
import {
  buttonStyle,
  cellStyle,
  headerStyle,
  rowStyle
} from './index.style'


/*
 * OBJECTS
 */
const Index = ({ passOn }) => {
  // Const assignment.
  const _skipDifference = 10

  // Hook assignment.
  const [skipPage, setSkipPage] = React.useState(0)
  const { 'isOpen': isRoutePlanCreateOpen, 'onOpen': onRoutePlanCreateOpen, 'onClose': onRoutePlanCreateClose } = useDisclosure()
  const { 'isOpen': isRoutePlanUpdateOpen, 'onOpen': onRoutePlanUpdateOpen, 'onClose': onRoutePlanUpdateClose } = useDisclosure()
  const { 'isOpen': isRoutePlanDeleteOpen, 'onOpen': onRoutePlanDeleteOpen, 'onClose': onDeleteModalClose } = useDisclosure()
  const { 'isOpen': isVendorAccountAttachRoutePlanOpen, 'onOpen': onVendorAccountAttachRoutePlanOpen, 'onClose': onVendorAccountAttachRoutePlanClose } = useDisclosure()
  const [MutationRoutePlanPlayPause] = useMutation(RoutePlanPlayPauseMutation)
  const [routePlayPauseIsLoading, setRoutePlayPauseIsLoading] = React.useState([])
  const _QueryRoutePlanRead = useQuery(RoutePlanReadQuery, { 'variables': { 'routeId': passOn?.routeId, 'skip': skipPage * _skipDifference, 'take': _skipDifference }, 'fetchPolicy': 'cache-and-network' })

  // Return component.
  return (
    <Flex
      fontSize={['Clamp(13px, 1.5vw, 15px)']}
      direction={{ 'base': 'column' }}
      display='flex'
      flex={1}
      overflowX='scroll'>
      <Flex
        display='flex'
        flex={1}
        flexDirection='column'
        gap='1rem'
        bg='white'
        minH='calc(100vh - 160px)'
        maxHeight='100%'
        borderRadius='20px'
        p='20px'>
        <Flex pb='0px' justify='space-between'>
          <Flex gap='10px'>
            <Tooltip label='Create new customer.' fontSize='sm'>
              <Button
                onClick={onRoutePlanCreateOpen}
                leftIcon={<HiPlusCircle />}
                style={buttonStyle}>
                Create Connect Mapping
              </Button>
            </Tooltip>
          </Flex>
          <DownloadToExcel
            cellsData={0 === _QueryRoutePlanRead.data?.RoutePlanRead?.length || !_.every(_.pluck(_QueryRoutePlanRead.data?.RoutePlanRead, 'status'), i => 'READ_SUCCESSFUL' === i) ? [] : _QueryRoutePlanRead.data?.RoutePlanRead?.map((item, __index) => ({
              'S.No.': `${(__index + (_skipDifference * (skipPage))) + 1}.`,
              'CreatedAt': item.createdAt,
              'UpdatedAt': item?.updatedAt ?? '-',
              'Mcc': item?.Mcc?.mcc ?? '-',
              'Mnc': item?.Mcc?.mnc?.join(', ') ?? '-',
              'Percentage': item?.percentage ?? '-',
              'isPaused': item?.isPaused ?? '-',
              'SmsType': item?.smsType ?? '-',
              'CountryType': item?.Mcc?.countryCode ?? '-',
              'CountryName': item?.Mcc?.countryName ?? '-',
              'Operator': item?.Mcc?.network?.join(', ') ?? '-',
              'RouteName': _.pluck(item.Route, 'displayName')?.join(', ') ?? '-'
            }))}
            headersData={[
              'S.No.',
              'CreatedAt',
              'UpdatedAt',
              'Mcc',
              'Mnc',
              'Percentage',
              'isPaused',
              'SmsType',
              'CountryType',
              'CountryName',
              'Operator',
              'RouteName'
            ].map(i => ({ 'key': i, 'label': i }))} />
        </Flex>
        <TableContainer
          maxH='calc(100vh - 260px)'
          flex={1}
          display='flex'
          overflowY='scroll'
          borderRadius='15px'
          outline={0 === _QueryRoutePlanRead.data?.RoutePlanRead?.length ? void 0 : '1px solid #C5CFE8'}>
          {_QueryRoutePlanRead.loading && (_.isEmpty(_QueryRoutePlanRead.data?.RoutePlanRead) || 0 === _QueryRoutePlanRead.data?.RoutePlanRead?.length) ? (
            <Flex w='100%' h='100%' display='flex' alignItems='center' justifyContent='center'>
              <TableSpinner isLoading={true} />
            </Flex>
          ) : 0 === _QueryRoutePlanRead.data?.RoutePlanRead?.length || !_.every(_.pluck(_QueryRoutePlanRead.data?.RoutePlanRead, 'status'), i => 'READ_SUCCESSFUL' === i || 'UPDATE_SUCCESSFUL' === i) ? (
            <Flex w='100%' h='100%' display='flex' alignItems='center' justifyContent='center'>
              <TableSpinner isLoading={false} isEmpty={true} />
            </Flex>
          ) : (
            <Table variant='simple' display='block' size='md'>
              <Thead>
                    <Tr style={{ ...headerStyle, position: 'sticky', top: 0, zIndex: 1 }}>
                  <Td style={rowStyle}>S.No.</Td>
                  <Td style={rowStyle}>CreatedAt</Td>
                  <Td style={rowStyle}>UpdatedAt</Td>
                  <Td style={rowStyle}>Mcc</Td>
                  <Td style={rowStyle}>Mnc</Td>
                  <Td style={rowStyle}>Load Percentage %</Td>
                      <Td style={rowStyle}>Status</Td>
                      <Td style={rowStyle}>Sms Type</Td>
                      <Td style={rowStyle}>Country Type</Td>
                      <Td style={rowStyle}>Country Name</Td>
                      <Td style={rowStyle}>Operator</Td>
                      <Td style={rowStyle}>Route Name</Td>
                      <Td style={rowStyle}>Actions</Td>
                </Tr>
              </Thead>
              <Tbody style={cellStyle}>
                {_QueryRoutePlanRead.data?.RoutePlanRead?.map((item, __index) => (
                  <Tr key={String.random(8)}>
                    <Td
                      style={rowStyle}
                      borderRight='1px solid rgba(216, 227, 252, 1)'>
                      {' '}
                      {`${(__index + (_skipDifference * (skipPage))) + 1}.`}
                    </Td>
                    <Td
                      style={rowStyle}
                      borderRight='1px solid rgba(216, 227, 252, 1)'>
                      {Moment(item.createdAt).format('YYYY-MM-DD hh:mm:ss A')}
                    </Td>
                    <Td
                      style={rowStyle}
                      borderRight='1px solid rgba(216, 227, 252, 1)'>
                      {Moment(item.updatedAt).format('YYYY-MM-DD hh:mm:ss A')}
                    </Td>
                    <Td
                      style={rowStyle}
                      borderRight='1px solid rgba(216, 227, 252, 1)'>
                      {item?.Mcc?.mcc}
                    </Td>
                    <Td
                      style={rowStyle}
                      borderRight='1px solid rgba(216, 227, 252, 1)'>
                      {item?.Mnc?.mnc ?? '-'}
                    </Td>
                    <Td
                      style={rowStyle}
                      borderRight='1px solid rgba(216, 227, 252, 1)'>
                      {item?.percentage ?? 0}%
                    </Td>
                    <Td
                      style={rowStyle}
                      borderRight='1px solid rgba(216, 227, 252, 1)'>
                      <Button
                        bg='none'
                        _hover={{ 'bg': 'none' }}
                        _active={{ 'bg': 'none' }}
                        w='55px'
                        h='55px'
                        onClick={() => {
                          // Update loading state with status.
                          setRoutePlayPauseIsLoading(i => [...i, item?.id])

                          // Execute mutation for play pause.
                          MutationRoutePlanPlayPause({ 'variables': { 'routePlanId': item?.id, 'isPaused': !item?.isPaused } })
                            .then(j => {
                              // Set loading state to false.
                              setRoutePlayPauseIsLoading(i => _.without(i, item?.id))

                              // Return on execution.
                              return toast(j?.data?.RoutePlanPlayPause?.message)
                            })
                        }}>
                        {routePlayPauseIsLoading.includes(item?.id) ? <Spinner color='#c1a9f4' size='sm' /> : void 0}
                        {
                          routePlayPauseIsLoading.includes(item?.id) ? void 0 : item?.isPaused ? (
                            <Text
                              width='max-content'
                              fontSize={13}
                              textTransform='uppercase'
                              borderRadius='8px'
                              padding='5px 15px 5px 15px'
                              color='red'
                              bg='red.100'>
                              Paused
                            </Text>
                          ) : (
                            <Text
                              width='max-content'
                              fontSize={13}
                              textTransform='uppercase'
                              borderRadius='8px'
                              padding='5px 15px 5px 15px'
                              color='green'
                              bg='green.100'>
                              Running
                            </Text>
                          )
                        }
                      </Button>
                    </Td>
                    <Td
                      style={rowStyle}
                      borderRight='1px solid rgba(216, 227, 252, 1)'>
                      {item?.smsType ?? '-'}
                    </Td>
                    <Td
                      style={rowStyle}
                      borderRight='1px solid rgba(216, 227, 252, 1)'>
                      {item?.Mcc?.countryCode ?? '-'}
                    </Td>
                    <Td
                      style={rowStyle}
                      borderRight='1px solid rgba(216, 227, 252, 1)'>
                      {item?.Mcc?.countryName ?? '-'}
                    </Td>
                    <Td
                      style={rowStyle}
                      borderRight='1px solid rgba(216, 227, 252, 1)'>
                      {item?.Mnc?.network ?? '-'}
                    </Td>
                    <Td
                      style={rowStyle}
                      borderRight='1px solid rgba(216, 227, 252, 1)'>
                      {item.Route.displayName ?? '-'}
                    </Td>
                    <Td
                      style={rowStyle}>
                      {' '}
                      {
                        <RoutePlanDropDownOptions
                          routePlanId={item.id}
                          routeId={item.Route?.id}
                          onRoutePlanUpdate={onRoutePlanUpdateOpen}
                          onRoutePlanDelete={onRoutePlanDeleteOpen}
                          onVendorAccountAttachRoutePlanOpen={onVendorAccountAttachRoutePlanOpen}
                        />
                      }
                    </Td>
                  </Tr>
                ))}
              </Tbody>
            </Table>
          )}
        </TableContainer>
      </Flex>
      <Pagination itemsPerPage={_skipDifference} totalCount={_.pluck(_QueryRoutePlanRead.data?.RoutePlanRead, '_totalCount')?.[0]} onPageChange={setSkipPage} />
      <Modal
        size='md'
        title='Route Plan Create'
        isOpen={isRoutePlanCreateOpen}
        onClose={onRoutePlanCreateClose}>
        <RoutePlanUpsert isCreateOnly={true} />
      </Modal>
      <Modal
        size='md'
        title='Route Plan Update'
        isOpen={isRoutePlanUpdateOpen}
        onClose={onRoutePlanUpdateClose}>
        <RoutePlanUpsert />
      </Modal>
      <Modal
        size='md'
        title='Route Plan Delete'
        isOpen={isRoutePlanDeleteOpen}
        onClose={onDeleteModalClose}>
        <RoutePlanDelete />
      </Modal>
      <Modal
        size='md'
        title='Attach Vendor Account'
        isOpen={isVendorAccountAttachRoutePlanOpen}
        onClose={onVendorAccountAttachRoutePlanClose}>
        <VendorAccountAttachRoutePlan />
      </Modal>
    </Flex>
  )
}


/*
 * PROPTYPES
 */
Index.propTypes = {
  'passOn': PropTypes.object
}


/*
 * REDUX
 */
const _MapStateToProps = __state => ({ 'passOn': __state.PassOn })


/*
 * EXPORT
 */
export default connect(_MapStateToProps)(Index)
