/*
 * IMPORTS
 */
import { gql } from '@apollo/client' // Npm: Apollo client for handling graphql request.


/*
 * EXPORTS
 */
export default gql`
query BlockDestinationAddressReadQuery($blockDestinationAddressId: ID!) {
  BlockDestinationAddressRead(blockDestinationAddressId: $blockDestinationAddressId) {
    phone,
    reason,
    message,
    status,
    Customer {
      id,
      displayName
    },
  }
}
`
