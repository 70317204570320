/*
 * IMPORTS
 */
import React from 'react' // Npm: react.js library.
import Moment from 'moment' // Npm: moment.js library.
import _ from 'underscore' // Npm: underscore.js library.
import { useQuery } from '@apollo/client' // Npm: Apollo client.
import {
  Flex,
  Table,
  TableContainer,
  Tbody,
  Td,
  Thead,
  Tr
} from '@chakra-ui/react' // Npm: Chakra UI components.


/*
 * PACKAGES
 */
import DownloadToExcel from 'components/DownloadToExcel'
import TableSpinner from 'components/TableSpinner'
import Pagination from 'components/Pagination'
import { MemoizedSelect } from 'components/MemoizedInput'


/*
 * GRAPHS
 */
import CustomerReadQuery from './__query__/index.customer.read.query'


/*
 * STYLES
 */
import './index.css'
import {
  cellStyle,
  headerStyle,
  rowStyle
} from './index.style'


/*
 * OBJECTS
 */
const Index = () => {
  // Hook assignment.
  const [skipDifference, setSkipDifference] = React.useState(10)
  const [skipPage, setSkipPage] = React.useState(0)
  const _QueryCustomerRead = useQuery(CustomerReadQuery, { 'variables': { 'skip': skipPage * skipDifference, 'take': skipDifference } })

  // Return component.
  return (
    <Flex
      fontSize={['Clamp(13px, 1.5vw, 15px)']}
      direction={{ 'base': 'column' }}
      display='flex'
      flex={1}
      overflowX='scroll'>
      <Flex
        display='flex'
        flex={1}
        flexDirection='column'
        gap='1rem'
        bg='white'
        minH='calc(100vh - 150px)'
        maxHeight='100%'
        borderRadius='20px'
        overflow='hidden'
        p='20px'>
        <Flex pb='0px' justify='space-between' alignItems='center'>
          <Flex flex={0.5} flexDir='row' alignItems='center'>
            <Flex width='100%' className='paginationPageSizeSelect' alignItems='center' color='gray.500'>Showing {<MemoizedSelect color='red' _focus={{ 'border': 'none' }} width={30} options={
              [
                10,
                20,
                30,
                40,
                50
              ]
            } onChange={i => setSkipDifference(i.target.value)} />}Records<span className='paginationPageSizeSelectSeparator'>,</span>Total <span className='paginationPageSizeSelectTotalCount'>{_QueryCustomerRead?.data?.CustomerRead?.length ?? 0}</span> Records Found
            </Flex>
          </Flex>
          <Flex flex={0.5} justifyContent='flex-end'>
            <DownloadToExcel
              cellsData={_QueryCustomerRead?.data?.CustomerRead?.map((item, __index) => ({
                'S.No.': `${(__index + (skipDifference * (skipPage))) + 1}.`,
                'CreatedAt': Moment(item.createdAt).format('YYYY-MM-DD HH:mm:ss'),
                'UpdatedAt': Moment(item.updatedAt).format('YYYY-MM-DD HH:mm:ss'),
                'CompanyName': item?.Company?.displayName ?? '-',
                'CompanyReferenceNumber': item.Company?.referenceNumber,
                'Type': item?.type ?? '-',
                'RateEmail': item?.rateEmail?.join(', ') ?? '-',
                'Phone': item.phone ?? '-',
                'BillingCurrency': item.Billing?.currency ?? '-',
                'BillingBalance': item.Billing?.balance ?? '-',
                'BillingAlertAmount': item.Billing?.alertAmount ?? '-',
                'RateName': _.flatten(_.pluck(item.SmsAccount, 'Rate')).map?.(i => i?.displayName)?.join(', ') ?? '-',
                'RouteName': _.flatten(_.pluck(item.SmsAccount, 'Route')).map?.(i => i?.displayName)?.join(', ') ?? '-',
                'AccountManager': item.AccountManager ? item.AccountManager : 'Not Assigned'
              }))}
              headersData={[
                'S.No.',
                'CreatedAt',
                'UpdatedAt',
                'CompanyName',
                'CompanyReferenceNumber',
                'Type',
                'RateEmail',
                'Phone',
                'BillingCurrency',
                'BillingBalance',
                'BillingAlertAmount',
                'RateName',
                'RouteName',
                'AccountManager'
              ].map(i => ({ 'key': i, 'label': i }))} /></Flex>
        </Flex>
        <TableContainer
          maxH='calc(100vh - 260px)'
          flex={1}
          display='flex'
          borderRadius='15px'
          outline='1px solid #C5CFE8'
          overflowY='auto'>
          {_QueryCustomerRead.loading ? (
            <Flex w='100%' h='100%' display='flex' alignItems='center' justifyContent='center'>
              <TableSpinner isLoading={true} />
            </Flex>
          ) : 0 === _QueryCustomerRead?.data?.CustomerRead?.length || (0 < _QueryCustomerRead?.data?.CustomerRead?.length && _.every(_.pluck(_QueryCustomerRead?.data?.CustomerRead, 'status'), j => 'NO_SMS_FOUND' === j)) ? (
            <Flex w='100%' h='100%' display='flex' alignItems='center' justifyContent='center'>
              <TableSpinner isLoading={false} isEmpty={true} />
            </Flex>
          ) : (
            <Table variant='simple' display='block' size='md'>
              <Thead>
                <Tr style={{ ...headerStyle, position: 'sticky', top: 0, zIndex: 1 }}>
                  <Td style={rowStyle}>S.No.</Td>
                  <Td style={rowStyle}>CreatedAt</Td>
                  <Td style={rowStyle}>UpdatedAt</Td>
                  <Td style={rowStyle}>Display Name</Td>
                  <Td style={rowStyle}>Active Balance</Td>
                  <Td style={rowStyle}>Credit Limit</Td>
                  <Td style={rowStyle}>Credited On</Td>
                  <Td style={rowStyle}>Total Sms</Td>
                  <Td style={rowStyle}>Sms Delivered</Td>
                  <Td style={rowStyle}>Sms Failed</Td>
                  <Td style={rowStyle}>Sms Pending</Td>
                </Tr>
              </Thead>
              <Tbody style={cellStyle}>
                {_QueryCustomerRead?.data?.CustomerRead?.map((item, __index) => (
                  <Tr key={String.random(8)}>
                    <Td
                      style={rowStyle}
                      borderRight='1px solid rgba(216, 227, 252, 1)'>
                      {' '}
                      {`${(__index + (skipDifference * (skipPage))) + 1}.`}
                    </Td>
                    <Td
                      style={rowStyle}
                      borderRight='1px solid rgba(216, 227, 252, 1)'>
                      {Moment(item.createdAt).format('YYYY-MM-DD hh:mm:ss A')}
                    </Td>
                    <Td
                      style={rowStyle}
                      borderRight='1px solid rgba(216, 227, 252, 1)'>
                      {Moment(item.updatedAt).format('YYYY-MM-DD hh:mm:ss A')}
                    </Td>
                    <Td
                      width='100%'
                      style={rowStyle}
                      borderRight='1px solid rgba(216, 227, 252, 1)'>
                      {item?.displayName ?? '-'}
                    </Td>
                    <Td
                      width='100%'
                      style={rowStyle}
                      borderRight='1px solid rgba(216, 227, 252, 1)'>
                      {item?.Credit?.balance ?? '-'}
                    </Td>
                    <Td
                      width='100%'
                      style={rowStyle}
                      borderRight='1px solid rgba(216, 227, 252, 1)'>
                      {item?.Credit?.limit ?? '-'}
                    </Td>
                    <Td
                      width='100%'
                      style={rowStyle}
                      borderRight='1px solid rgba(216, 227, 252, 1)'>
                      {Moment(item?.Credit?.updatedAt).format('YYYY-MM-DD hh:mm:ss A')}
                    </Td>
                    <Td
                      style={rowStyle}
                      borderRight='1px solid rgba(216, 227, 252, 1)'>
                      {_.compact(_.flatten(_.pluck(_.flatten(_.pluck(_.flatten(_.pluck(item.CustomerAccount, 'Route')), 'RoutePlan')), 'Sms')))?.length ?? '-'}
                    </Td>
                    <Td
                      style={rowStyle}
                      borderRight='1px solid rgba(216, 227, 252, 1)'>
                      {_.compact(_.flatten(_.pluck(_.flatten(_.pluck(_.flatten(_.pluck(item.CustomerAccount, 'Route')), 'RoutePlan')), 'Sms')))?.filter(j => 'DELIVERED' === j.status)?.length ?? '-'}
                    </Td>
                    <Td
                      style={rowStyle}
                      borderRight='1px solid rgba(216, 227, 252, 1)'>
                      {_.compact(_.flatten(_.pluck(_.flatten(_.pluck(_.flatten(_.pluck(item.CustomerAccount, 'Route')), 'RoutePlan')), 'Sms')))?.filter(j => 'FAILED' === j.status || 'REJECTED' === j.status)?.length ?? '-'}
                    </Td>
                    <Td
                      style={rowStyle}
                      borderRight='1px solid rgba(216, 227, 252, 1)'>
                      {_.compact(_.flatten(_.pluck(_.flatten(_.pluck(_.flatten(_.pluck(item.CustomerAccount, 'Route')), 'RoutePlan')), 'Sms')))?.filter(j => 'ACCEPTED' === j.status || 'REJECTED' === j.status)?.length ?? '-'}
                    </Td>
                  </Tr>
                ))}
              </Tbody>
            </Table>
          )}
        </TableContainer>
      </Flex>
      <Pagination itemsPerPage={skipDifference} totalCount={_.pluck(_QueryCustomerRead?.data?.CustomerRead, '_totalCount')?.[0]} onPageChange={setSkipPage} />
    </Flex>
  )
}


/*
 * EXPORT
 */
export default Index

