/*
 * IMPORTS
 */
import { gql } from '@apollo/client' // Npm: Apollo client.


/*
 * EXPORTS
 */
export default gql`
mutation RoutePlanDeleteMutation($routePlanId: ID!) {
    RoutePlanDelete(routePlanId: $routePlanId) {
        message
        status
    }
} 
`

