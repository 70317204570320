/*
 * IMPORTS
 */
import 'react-circular-progressbar/dist/styles.css' // Npm: React circular progressbar.
import React from 'react' // Npm: react.js library.
import PropTypes from 'prop-types' // Npm: react.js library.
import { useSubscription } from '@apollo/client' // Npm: Apollo client.
import {
  buildStyles,
  CircularProgressbarWithChildren
} from 'react-circular-progressbar' // Npm: React circular progressbar.
import {
  Box,
  Flex,
  Text
} from '@chakra-ui/react' // Npm: Chakra UI components.


/*
 * PACKAGES
 */
import Card from 'components/Card'


/*
 * GRAPHS
 */
import SystemInformationReadSubscription from './__subscription__/index.systemInformation.read.subscription'


/*
 * OBJECTS
 */
const Index = ({ title, color, whatToShowOnChart }) => {
  // Hook assignment.
  const stylesColorMode = {
    'textSize': '0px',
    'textColor': 'transparent',
    'pathTransitionDuration': 0.5,
    'pathColor': color,
    'trailColor': '#E9EDF7',
    'backgroundColor': '#2A2D3E'
  }
  const _SystemReadSubscription = useSubscription(SystemInformationReadSubscription)

  // Const assignment.
  const _percentage = 'memory' === whatToShowOnChart ? (100 - ((_SystemReadSubscription?.data?.SystemInformationRead?.memory?.available ?? 0) / (1024 ** 3)))?.toFixed(1) : (_SystemReadSubscription?.data?.SystemInformationRead?.fsSize?.filter(j => '/' === j.mount)[0]?.use ?? 100)

  // Return component.
  return (
    <Card align='center' direction='column' w='100%' h='22rem' bg='white' justifyContent='space-between'>
      <Flex align='center' justifyContent='space-between'>
        <Text
          fontSize='24px'
          fontWeight='300'
          lineHeight='30px'
          color='secondaryGray.900'>
          {title ?? 'Utilization Chart'}
        </Text>
      </Flex>
      <Box w='14rem' mx='auto' mb='10px'>
        <CircularProgressbarWithChildren
          value={_percentage}
          strokeWidth={1}
          text={`${_percentage}%`}
          styles={buildStyles(stylesColorMode)}>
          <Box>
            <Text fontSize='sm' color='secondaryGray.600' fontWeight='500'>
              {title}
            </Text>
            <Text fontSize='xl' color='secondaryGray.600' fontWeight='700'>
              {_percentage}%
            </Text>
          </Box>
        </CircularProgressbarWithChildren>
      </Box>
    </Card>
  )
}


/*
 * PROPTYPES
 */
Index.propTypes = {
  'title': PropTypes.string,
  'color': PropTypes.string,
  'whatToShowOnChart': PropTypes.string.isRequired
}


/*
 * EXPORTS
 */
export default Index
