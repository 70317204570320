/*
 * IMPORTS
 */
import { gql } from '@apollo/client' // Npm: Apollo client for handling graphql request.


/*
 * EXPORTS
 */
export default gql`
mutation RoutePlanUpdateMutation($routePlanId: ID!, $input: [RoutePlanUpdateInput!]!) {
  RoutePlanUpdate(routePlanId: $routePlanId, input: $input) {
    id,
    message
    status
  }
}
`
