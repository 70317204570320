/*
 * IMPORTS
 */
import { gql } from '@apollo/client' // Npm: Apollo client for handling graphql request.


/*
 * EXPORTS
 */
export default gql`
query CustomerReadQuery($take: PositiveInt, $skip: Int) {
  CustomerRead(take: $take, skip: $skip) {
    id,
    displayName,
    message,
    status
  }
}
`
