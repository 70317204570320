/*
 * IMPORTS
 */
import React from 'react' // Npm: react.js library.
import PropTypes from 'prop-types' // Npm: react.js library.
import Moment from 'moment' // Npm: moment.js library.
import _ from 'underscore' // Npm: underscore library.
import { connect } from 'react-redux' // Npm: React Redux for state management.
import { useQuery } from '@apollo/client' // Npm: Apollo client.
import { HiPlusCircle } from 'react-icons/hi2' // Npm: React icons.
import {
  Button,
  Flex,
  Table,
  TableContainer,
  Tbody,
  Td,
  Text,
  Thead, Tooltip,
  Tr,
  useDisclosure
} from '@chakra-ui/react' // Npm: Chakra UI components.


/*
 * PACKAGES
 */
import TableSpinner from 'components/TableSpinner'
import DownloadToExcel from 'components/DownloadToExcel'
import Pagination from 'components/Pagination'
import Modal from 'components/Modal'
import CustomerAccountUpsert from 'components/CustomerAccountUpsert'
import CustomerAccountDelete from 'components/CustomerAccountDelete'
import CustomerAccountDropDownOptions from 'components/CustomerAccountDropDownOptions'


/*
 * STYLES
 */
import {
  buttonStyle,
  cellStyle,
  headerStyle,
  rowStyle
} from './index.style'


/*
 * GRAPHS
 */
import CustomerAccountReadQuery from './__query__/index.customerAccount.read.query'


/*
 * OBJECTS
 */
const Index = ({ passOn }) => {
  // Const assignment.
  const _skipDifference = 10

  // Hook assignment.
  const [skipPage, setSkipPage] = React.useState(0)
  const { 'onOpen': onCustomerAccountCreateOpen, 'isOpen': isCustomerAccountCreateOpen, 'onClose': onCustomerAccountCreateClose } = useDisclosure()
  const { 'onOpen': onCustomerAccountUpdateOpen, 'isOpen': isCustomerAccountUpdateOpen, 'onClose': onCustomerAccountUpdateClose } = useDisclosure()
  const { 'onOpen': onCustomerAccountDeleteOpen, 'isOpen': isCustomerAccountDeleteOpen, 'onClose': onCustomerAccountDeleteClose } = useDisclosure()
  const _QueryCustomerAccountRead = useQuery(CustomerAccountReadQuery, { 'variables': { 'customerId': passOn?.customerId, 'skip': skipPage * _skipDifference, 'take': _skipDifference }, 'fetchPolicy': 'cache-network' })

  // Return component.
  return (
    <Flex
      fontSize={['Clamp(13px, 1.5vw, 15px)']}
      direction={{ 'base': 'column' }}
      display='flex'
      flex={1}
      overflowX='scroll'>
      <Flex
        display='flex'
        flex={1}
        flexDirection='column'
        gap='1rem'
        bg='white'
        minH='calc(100vh - 160px)'
        maxHeight='100%'
        borderRadius='20px'
        p='20px'>
        <Flex pb='0px' justify='space-between'>
          <Tooltip label='Create Connect Mapping.' fontSize='sm'>
            <Button
              onClick={onCustomerAccountCreateOpen}
              leftIcon={<HiPlusCircle />}
              style={buttonStyle}>
              Create Connect Mapping
            </Button>
          </Tooltip>
          <DownloadToExcel
            cellsData={_QueryCustomerAccountRead?.data?.CustomerAccountRead?.map((item, __index) => item && item.Smpp && ({
              'S.No.': `${__index + 1}.`,
              'CreatedAt': item.Smpp.createdAt,
              'UpdatedAt': item.Smpp.updatedAt,
              'Name': item.Smpp.displayName,
              'SystemId': item.Smpp.username ?? '-',
              'Password': item.Smpp.password ?? '-',
              'Ip': item.Smpp.ip ?? '-',
              'Tps': item.Smpp.tps ?? '-',
              'SessionAllowed': item.Smpp.sessionAllowed ?? '-',
              'SessionTimeoutInterval': item.Smpp.sessionTimeoutInterval ?? '-',
              'isActive': item.Smpp.isActive ? 'Active' : 'Inactive',
              'CreatedBy': item.Smpp.Account?.email ?? '-'
            }))}
            headersData={[
              'S.No.',
              'CreatedAt',
              'UpdatedAt',
              'Name',
              'SystemId',
              'Password',
              'Ip',
              'Tps',
              'SessionAllowed',
              'SessionTimeoutInterval',
              'isActive',
              'CreatedBy'
            ].map(i => ({ 'key': i, 'label': i }))} />
        </Flex>
        <TableContainer
          maxH='calc(100vh - 260px)'
          flex={1}
          display='flex'
          borderRadius='15px'
          outline='1px solid #C5CFE8'
          overflowY='auto'>
          {_QueryCustomerAccountRead?.loading && (_.isEmpty(_QueryCustomerAccountRead?.data?.CustomerAccountRead) || 0 === _QueryCustomerAccountRead?.data?.CustomerAccountRead.length) ? (
            <Flex w='100%' h='100%' display='flex' alignItems='center' justifyContent='center'>
              <TableSpinner isLoading={true} />
            </Flex>
          ) : 0 === _QueryCustomerAccountRead?.data?.CustomerAccountRead.length || !_.every(_.pluck(_QueryCustomerAccountRead?.data?.CustomerAccountRead, 'status'), i => 'READ_SUCCESSFUL' === i || 'UPDATE_SUCCESSFUL' === i) ? (
            <Flex w='100%' h='100%' display='flex' alignItems='center' justifyContent='center'>
              <TableSpinner isLoading={false} isEmpty={true} />
            </Flex>
          ) : (
            <Table variant='simple' display='block' size='lg'>
              <Thead
                color='rgba(141, 151, 182, 1)'
                fontStyle='DM Sans'
                fontWeight='500'
                fontSize='18px'
                lineHeight='24px'>
                <Tr style={{ ...headerStyle, position: 'sticky', top: 0, zIndex: 1 }}>

                  <Td style={rowStyle}>S.No.</Td>
                  <Td style={rowStyle}>CreatedAt</Td>
                  <Td style={rowStyle}>UpdatedAt</Td>
                  <Td style={rowStyle}>Name</Td>
                  <Td style={rowStyle}>System Id</Td>
                  <Td style={rowStyle}>Password</Td>
                  <Td style={rowStyle}>IP</Td>
                  <Td style={rowStyle}>Smpp tps</Td>
                  <Td style={rowStyle}>Session Allowed</Td>
                  <Td style={rowStyle}>Session ttl</Td>
                  <Td style={rowStyle}>Status</Td>
                  <Td style={rowStyle}>Actions</Td>
                </Tr>
              </Thead>
              {_.isEmpty(_QueryCustomerAccountRead?.data?.CustomerAccountRead) ? (<TableSpinner isEmpty={true} isLoading={_QueryCustomerAccountRead?.loading} />) : (<Tbody style={cellStyle}>
                {_QueryCustomerAccountRead?.data?.CustomerAccountRead?.map((__item, __index) => __item && __item.Smpp && (
                  <Tr key={__item.id}>
                    <Td
                      style={rowStyle}
                      borderRight='1px solid rgba(216, 227, 252, 1)'>
                      {' '}
                      {`${(__index + (_skipDifference * (skipPage))) + 1}.`}
                    </Td>
                    <Td
                      style={rowStyle}
                      borderRight='1px solid rgba(216, 227, 252, 1)'>
                      {Moment(__item.Smpp.createdAt).format('DD-MM-YYYY hh:mm:ss A')}
                    </Td>
                    <Td
                      style={rowStyle}
                      borderRight='1px solid rgba(216, 227, 252, 1)'>
                      {Moment(__item.Smpp.updatedAt).format('DD-MM-YYYY hh:mm:ss A')}
                    </Td>
                    <Td
                      style={rowStyle}
                      borderRight='1px solid rgba(216, 227, 252, 1)'>
                      {__item.Smpp.displayName}
                    </Td>
                    <Td
                      style={rowStyle}
                      borderRight='1px solid rgba(216, 227, 252, 1)'>
                      {__item.Smpp.username}
                    </Td>
                    <Td
                      style={rowStyle}
                      borderRight='1px solid rgba(216, 227, 252, 1)'>
                      {__item.Smpp.password}
                    </Td>
                    <Td
                      style={rowStyle}
                      borderRight='1px solid rgba(216, 227, 252, 1)'>
                      {__item.Smpp.ip}
                    </Td>
                    <Td
                      style={rowStyle}
                      borderRight='1px solid rgba(216, 227, 252, 1)'>
                      {__item.Smpp.tps}
                    </Td>
                    <Td
                      style={rowStyle}
                      borderRight='1px solid rgba(216, 227, 252, 1)'>
                      {__item.Smpp.sessionAllowed}
                    </Td>
                    <Td
                      style={rowStyle}
                      borderRight='1px solid rgba(216, 227, 252, 1)'>
                      {__item.Smpp.sessionTimeoutInterval}
                    </Td>
                    <Td
                      style={rowStyle}
                      borderRight='1px solid rgba(216, 227, 252, 1)'>
                      {true === __item.Smpp.isActive ? (
                        <Text
                          width='max-content'
                          borderRadius='10px'
                          padding='5px 15px 5px 15px'
                          color='rgba(117, 81, 255, 1)'
                          bg='rgba(117, 81, 255, .1)'>
                          Running
                        </Text>
                      ) : (
                        <Text
                          width='max-content'
                          borderRadius='8px'
                          padding='5px 15px 5px 15px'
                          color='gray.500'
                          bg='rgba(42, 42, 42, 0.03)'>
                            Stopped
                        </Text>
                      )}
                    </Td>
                    <Td padding='0px' margin='0px'>
                      <Flex align='center' justify='center'>
                        {
                          <CustomerAccountDropDownOptions
                            type={passOn.type}
                            customerAccountId={__item.id}
                            onCustomerAccountUpdate={onCustomerAccountUpdateOpen}
                            onCustomerAccountDelete={onCustomerAccountDeleteOpen}
                          />
                        }
                      </Flex>
                    </Td>
                  </Tr>
                ))}
              </Tbody>)}
            </Table>
          )}
        </TableContainer>
      </Flex>
      <Pagination itemsPerPage={_skipDifference} totalCount={_.pluck(_QueryCustomerAccountRead?.data?.CustomerAccountRead, '_totalCount')?.[0]} onPageChange={setSkipPage} />
      <Modal
        size='2xl'
        title='Connect Mapping Create'
        isOpen={isCustomerAccountCreateOpen}
        onClose={onCustomerAccountCreateClose}>
        <CustomerAccountUpsert isCreateOnly={true} />
      </Modal>
      <Modal
        size='2xl'
        title='Connect Mapping Update'
        isOpen={isCustomerAccountUpdateOpen}
        onClose={onCustomerAccountUpdateClose}>
        <CustomerAccountUpsert />
      </Modal>
      <Modal
        size='md'
        title='Connect Mapping Delete'
        isOpen={isCustomerAccountDeleteOpen}
        onClose={onCustomerAccountDeleteClose}>
        <CustomerAccountDelete />
      </Modal>
    </Flex>
  )
}


/*
 * PROPTYPES
 */
Index.propTypes = {
  'passOn': PropTypes.object
}


/*
 * REDUX
 */
const _MapStateToProps = __state => ({ 'passOn': __state.PassOn })


/*
 * EXPORT
 */
export default connect(_MapStateToProps)(Index)
