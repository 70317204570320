/*
 * IMPORTS
 */
import React from 'react' // Npm: react.js library.
import PropTypes from 'prop-types' // Npm: react.js library.
import { HiCheckCircle } from 'react-icons/hi2' // Npm: React icons.
import {
  Button,
  Flex,
  Spinner,
  Text
} from '@chakra-ui/react' // Npm: Chakra UI components.


/*
 * OBJECTS
 */
const Index = ({ isLoading, onSubmit }) => (
  <Flex
    py='22px'
    justifyContent='space-between'>
    <Flex flexDir='column'>
      <Text fontWeight='500' color='gray.800'>Click to Save your Updates</Text>
      <Text color='gray.400'>Reflection of changes might take time.</Text>
    </Flex>
    <Button
      variant='basic'
      type='submit'
      width='50px'
      height='50px'
      borderRadius='100%'
      bg='brand.500'
      borderWidth='2px'
      borderColor='brand.500'
      borderStyle='solid'
      color='white'
      p='0'
      _hover={{ 'bg': 'brand.500', 'color': 'white' }}
      _active={{ 'bg': 'brand.500', 'color': 'white' }}
      _focus={{ 'bg': 'brand.500', 'color': 'white' }}
      onClick={onSubmit}>
      {isLoading ? <Spinner size='sm' /> : <HiCheckCircle size={19} />}
    </Button>
  </Flex>
)


/*
 * PROPTYPES
 */
Index.propTypes = {
  'isLoading': PropTypes.bool,
  'onSubmit': PropTypes.func
}


/*
 * EXPORT
 */
export default Index
