/*
 * IMPORTS
 */
import { gql } from '@apollo/client' // Npm: Apollo client for handling graphql request.


/*
 * EXPORTS
 */
export default gql`
query AccountReadQuery($accountId: ID!) {
  AccountRead(accountId: $accountId) {
    displayName,
    fullName,
    bio,
    email,
    website,
    isBlocked,
    status,
    message,
  }
}
`
