/*
 * IMPORTS
 */
import React from 'react' // Npm: react.js library.
import PropTypes from 'prop-types' // Npm: react.js library.
import _ from 'underscore' // Npm: Underscore.js library.
import Terminal from 'react-console-emulator'
import { connect } from 'react-redux' // Npm: React Redux for state management.
import { useQuery } from '@apollo/client' // Npm: Apollo client.
import {
  Flex,
  Spinner,
  Text
} from '@chakra-ui/react' // Npm: Chakra UI components.


/*
 * GRAPHS
 */
import RouteReadQuery from './__query__/index.route.read.query'


/*
 * STYLES
 */
import './index.css'


/*
 * OBJECTS
 */
const Index = ({ passOn }) => {
  // Hook assignment.
  const _terminalRef = React.useRef({})
  const _QueryRouteRead = useQuery(RouteReadQuery, { 'variables': { 'routeId': passOn?.routeId } })

  // Object assignment.
  const _commands = {
    'help': {
      'description': 'Shows all commands terminal supports.',
      'usage': 'help',
      'fn': () => (
        <div>
          <Text mt='12px' fontSize='16px' fontWeight={700}>Welcome to SMPP Terminal!</Text>
          <Text fontSize='13px'>Embark on a journey into live dvata and logs. 🌐</Text>
          <Text fontSize='13px'>🚀 Unleash real-time insights and navigate the pulse of your SMPP connections.</Text>
          <Text fontSize='13px' fontWeight={900} mt='2px'>#SMPP #TerminalMagic #MessagingExcellence ✨</Text><br />
          <div>
            <Text mt='0' fontSize='13px' color='brand.500'>Basic Commands</Text>
            <Text fontSize='13px'>whoami - <span>Display connect mapping name.</span></Text>
            <Text fontSize='13px'>route --count - <span>Number of route mapping.</span></Text>
            <Text fontSize='13px'>--count - <span>Number of route plans.</span></Text>
            <Text fontSize='13px'>isPaused.count - <span>Number of paused route plans.</span></Text>
            <Text fontSize='13px'>mcc - <span>Mobile Country Codes.</span></Text>
            <Text fontSize='13px'>mcc.count - <span>Number of Mobile Country Codes.</span></Text>
            <Text fontSize='13px'>mnc - <span>Mobile Network Codes.</span></Text>
            <Text fontSize='13px'>mnc.networks - <span>Mobile Network Operators.</span></Text>
            <Text fontSize='13px'>mnc.count - <span>Number of Mobile Network Codes.</span></Text>
            <Text mt='12px' fontSize='13px' color='brand.500'>Sms Commands</Text>
            <Text fontSize='13px'>sms.count - <span>Total number of SMS.</span></Text>
            <Text fontSize='13px'>sms.delivered.count - <span>Number of delivered SMS.</span></Text>
            <Text fontSize='13px'>sms.accepted.count - <span>Number of accepted SMS.</span></Text>
            <Text fontSize='13px'>sms.rejected.count - <span>Number of rejected SMS.</span></Text>
            <Text fontSize='13px'>sms.unicode.count - <span>Number of unicode SMS.</span></Text>
            <Text fontSize='13px'>sms.text.count - <span>Number of text SMS.</span></Text>
            <Text fontSize='13px'>sms.deliveredThrough.smpp.count - <span>Number of SMS delivered through SMPP.</span></Text>
            <Text fontSize='13px'>sms.deliveredThrough.api.count - <span>Number of SMS delivered through API.</span></Text>
            <Text mt='12px' fontSize='13px' color='brand.500'>Vendor Account Smpp Commands</Text>
            <Text fontSize='13px'>vendor.account.smpp.count - <span>Number of SMPP accounts.</span></Text>
            <Text fontSize='13px'>vendor.account.smpp.ips - <span>SMPP account IPs.</span></Text>
            <Text fontSize='13px'>vendor.account.smpp.uris - <span>SMPP account URIs.</span></Text>
          </div>
        </div>
      )
    },
    'clear()': {
      'description': 'Clear terminal standard out logs in terminal.',
      'usage': 'clear()',
      'fn': () => _terminalRef?.current?.clearStdout?.()
    },
    'whoami': {
      'description': 'Display connect mapping name.',
      'usage': 'whoami',
      'fn': () => _.last(_QueryRouteRead?.data?.RouteRead)?.displayName
    },
    'count': {
      'description': 'Number of route plans.',
      'usage': 'count',
      'fn': () => _.flatten(_.pluck(_QueryRouteRead?.data?.RouteRead, 'RoutePlan'))?.length
    },
    'route': {
      'description': 'Number of route mapping.',
      'usage': 'route.count',
      'fn': e => {
        /*
         * If count flag is passed then return.
         * count of given command.
         */
        if ('--count' === e) return _QueryRouteRead?.data?.RouteRead?.length

        // Return void.
        return void 0
      }
    },
    'mcc': {
      'description': 'Mobile Country Codes.',
      'usage': 'mcc',
      'fn': e => {
        // Const assignment.
        const _routeRead = _.pluck(_.flatten(_.pluck(_QueryRouteRead?.data?.RouteRead, 'RoutePlan')), 'Mcc')

        /*
         * If count flag is passed then return.
         * count of given command.
         */
        if ('--count' === e) return _routeRead?.length?.toString()

        // Return mcc.
        return _routeRead?.map(j => j.mcc)?.join(', ')
      }
    },
    'mnc': {
      'description': 'Mobile Network Codes.',
      'usage': 'mnc',
      'fn': e => {
        // Const assignment.
        const _routeRead = _.pluck(_.flatten(_.pluck(_QueryRouteRead?.data?.RouteRead, 'RoutePlan')), 'Mnc')

        /*
         * If count flag is passed then return.
         * count of given command.
         */
        if ('--count' === e) return _routeRead?.length?.toString()

        // Return mcc.
        return _routeRead?.map(j => j.mnc)?.join(', ')
      }
    },
    'sms': {
      'description': 'Short Messages',
      'usage': 'sms',
      'fn': (j, e) => {
        // Const assignment.
        const _smsRead = _.compact(_.flatten(_.pluck(_.flatten(_.pluck(_QueryRouteRead?.data?.RouteRead, 'RoutePlan')), 'Sms')))

        /*
         * If count flag is passed then return.
         * count of given command.
         */
        if (('--count' === j && '--delivered' === e) || ('--count' === e && '--delivered' === j)) return _smsRead?.filter(r => 'DELIVERED' === r.status)?.length?.toString()
        if (('--count' === j && '--accepted' === e) || ('--count' === e && '--accepted' === j)) return _smsRead?.filter(r => 'ACCEPTED' === r.status)?.length?.toString()
        if (('--count' === j && '--rejected' === e) || ('--count' === e && '--rejected' === j)) return _smsRead?.filter(r => 'REJECTED' === r.status)?.length?.toString()
        if (('--count' === j && 'isUnicode' === e) || ('--count' === e && 'isUnicode' === j)) return _smsRead?.filter(r => r.isUnicodeMessage)?.length?.toString()
        if (('--count' === j && 'isText' === e) || ('--count' === e && 'isText' === j)) return _smsRead?.filter(r => !r.isUnicodeMessage)?.length?.toString()
        if ('--count' === j && _.isEmpty(e)) return _smsRead?.length?.toString()
        if (('--deliveredThrough' === j && !_.isEmpty(e)) || ('--deliveredThrough' === e && !_.isEmpty(j))) return _smsRead?.filter(r => !r.isUnicodeMessage)?.filter(r => j?.toUpperCase?.() === r.deliveredThrough || e?.toUpperCase?.() === r.deliveredThrough)?.length?.toString()

        // Paginated dataset to return.
        _smsRead.length = ('--size' === j && !isNaN(e) && 0 <= Number(e) ? e : 2)

        // Return mcc.
        return _smsRead?.map(q => q.id)?.join(', ')
      }
    },
    'vendor.account': {
      'description': 'Vendor Account',
      'usage': 'vendor.account',
      'fn': (j, e) => {
        // Const assignment.
        const _vendorAccountRead = _.compact(_.flatten(_.pluck(_.compact(_.flatten(_.pluck(_.flatten(_.pluck(_QueryRouteRead?.data?.RouteRead, 'RoutePlan')), 'VendorAccount'))), 'Smpp')))
        const _vendorAccountSmpp = _.compact(_.flatten(_.pluck(_.compact(_.flatten(_.pluck(_.flatten(_.pluck(_QueryRouteRead?.data?.RouteRead, 'RoutePlan')), 'VendorAccount'))), 'Smpp')))

        /*
         * If count flag is passed then return.
         * count of given command.
         */
        if ('--count' === j && _.isEmpty(e)) return _vendorAccountRead?.length?.toString()
        if (('--smpp' === j && !isNaN(Number(e ?? 0))) || ('--smpp' === e && !isNaN(Number(j ?? 0)))) {
          // Paginated dataset to return.
          _vendorAccountSmpp.length = (!isNaN(e) && 0 <= Number(e) ? e : !isNaN(j) && 0 <= Number(j) ? j : 2)

          // Return vendor account smpp's
          return JSON.stringify(_vendorAccountSmpp ?? {})
        }
        if (('--count' === j && '--smpp' === e) || ('--smpp' === e && '--count' === j)) return _vendorAccountSmpp?.length?.toString()
        if (('--ips' === j && '--smpp' === e) || ('--smpp' === e && '--ips' === j)) return _.flatten(_.pluck(_vendorAccountSmpp, 'ip'))?.toString()
        if (('--uris' === j && '--smpp' === e) || ('--smpp' === e && '--uris' === j)) return _vendorAccountSmpp?.map(y => `${y.isSecured ? 'ssmpp' : 'smpp'}://${y.ip}:${y.port}`)?.join(', ')

        // Paginated dataset to return.
        _vendorAccountRead.length = ('--size' === j && !isNaN(e) && 0 <= Number(e) ? e : 2)

        // Return mcc.
        return _.compact(_vendorAccountRead)?.map(q => q.id)?.join(', ')
      }
    }
  }

  // Return component.
  return (
    <Flex className='RouteLogs' w='100%' h='100%' borderTop='1px solid' borderColor='gray.100'>
      {
        _QueryRouteRead.loading ? (
          <Flex alignItems='center' gap='10px' p='22px' color='gray.500'>
            <Spinner size='sm' />
            <Text>Connecting Terminal Please wait..</Text>
          </Flex>
        ) : (
          <Flex flexDir='column'>
            <Terminal
              ref={_terminalRef}
              noDefaults
              commands={_commands}
              promptLabel={`${_.last(_QueryRouteRead?.data?.RouteRead)?.displayName}@smpp:~$`}
            />
          </Flex>
        )
      }
    </Flex>
  )
}


/*
 * PROPTYPES
 */
Index.propTypes = {
  'isCreateOnly': PropTypes.bool,
  'isOpen': PropTypes.bool,
  'onClose': PropTypes.func,
  'passOn': PropTypes.object
}


/*
 * REDUX
 */
const _MapStateToProps = __state => ({ 'passOn': __state.PassOn })


/*
 * EXPORT
 */
export default connect(_MapStateToProps)(Index)
