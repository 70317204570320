/*
 * IMPORTS
 */
import React from 'react' // Npm: react.js library.
import PropTypes from 'prop-types' // Npm: react.js library.
import Moment from 'moment' // Npm: moment.js library.
import _ from 'underscore' // Npm: utility module.
import { connect } from 'react-redux' // Npm: React Redux for state management.
import { useQuery } from '@apollo/client' // Npm: Apollo client.
import { HiPlusCircle } from 'react-icons/hi2' // Npm: React icons.
import {
  Button,
  Flex,
  Table,
  TableContainer,
  Tbody,
  Td,
  Text,
  Thead, Tooltip,
  Tr,
  useDisclosure
} from '@chakra-ui/react' // Npm: Chakra UI components.


/*
 * PACKAGES
 */
import DownloadToExcel from 'components/DownloadToExcel'
import TableSpinner from 'components/TableSpinner'
import Pagination from 'components/Pagination'
import Modal from 'components/Modal'
import SenderID from 'components/SenderID'
import VendorAccountUpsert from 'components/VendorAccountUpsert'
import VendorAccountDelete from 'components/VendorAccountDelete'
import VendorAccountDropDownOptions from 'components/VendorAccountDropDownOptions'
import ContentTranslation from 'components/ContentTranslation'


/*
 * GRAPHS
 */
import VendorAccountReadQuery from './__query__/index.vendorAccount.read.query'


/*
 * STYLES
 */
import {
  buttonStyle,
  cellStyle,
  headerStyle,
  rowStyle
} from './index.style'


/*
 * OBJECTS
 */
const Index = ({ passOn }) => {
  // Const assignment.
  const _skipDifference = 10

  // Hook assignment.
  const [skipPage, setSkipPage] = React.useState(0)
  const { 'onOpen': onVendorAccountCreateOpen, 'isOpen': isVendorAccountCreateOpen, 'onClose': onVendorAccountCreateClose } = useDisclosure()
  const { 'onOpen': onVendorAccountUpdateOpen, 'isOpen': isVendorAccountUpdateOpen, 'onClose': onVendorAccountUpdateClose } = useDisclosure()
  const { 'onOpen': onVendorAccountDeleteOpen, 'isOpen': isVendorAccountDeleteOpen, 'onClose': onVendorAccountDeleteClose } = useDisclosure()
  const { 'onOpen': onSenderIDAddOpen, 'isOpen': isSenderIDAddOpen, 'onClose': onSenderIDAddClose } = useDisclosure()
  const { 'onOpen': onContentTranslationOpen, 'isOpen': isContentTranslatonOpen, 'onClose': onContentTranslationClose } = useDisclosure()
  const _QueryVendorAccountRead = useQuery(VendorAccountReadQuery, { 'variables': { 'vendorId': passOn?.vendorId, 'skip': skipPage * _skipDifference, 'take': _skipDifference } })

  // Event handler.
  React.useEffect(() => {
    // Always refetch when ever modals of close are closed.
    if (!isVendorAccountCreateOpen || !isVendorAccountUpdateOpen || !isVendorAccountDeleteOpen || isSenderIDAddOpen) _QueryVendorAccountRead.refetch()
  }, [isVendorAccountCreateOpen, isVendorAccountUpdateOpen, isVendorAccountDeleteOpen, isSenderIDAddOpen])

  // Return component.
  return (
    <Flex
      fontSize={['Clamp(13px, 1.5vw, 15px)']}
      direction={{ 'base': 'column' }}
      display='flex'
      flex={1}
      overflowX='scroll'>
      <Flex
        display='flex'
        flex={1}
        flexDirection='column'
        gap='1rem'
        bg='white'
        minH='calc(100vh - 160px)'
        maxHeight='100%'
        borderRadius='20px'
        p='20px'>
        <Flex pb='0px' justify='space-between'>
          <Tooltip label='Create new vendor account.' fontSize='sm'>
            <Button
              onClick={onVendorAccountCreateOpen}
              leftIcon={<HiPlusCircle />}
              style={buttonStyle}>
              Create Connect Mapping
            </Button>
          </Tooltip>
          <DownloadToExcel
            cellsData={0 === _QueryVendorAccountRead.data?.VendorAccountRead?.length || !_.every(_.pluck(_QueryVendorAccountRead.data?.VendorAccountRead, 'status'), i => 'READ_SUCCESSFUL' === i) ? [] : _QueryVendorAccountRead.data?.VendorAccountRead?.map((item, __index) => ({
              'S.No.': `${(__index + (_skipDifference * (skipPage))) + 1}.`,
              'CreatedAt': item.createdAt,
              'UpdatedAt': item?.updatedAt ?? '-',
              'Name': item?.displayName ?? '-',
              'Vendor': item?.Vendor?.displayName ?? '-',
              'Rate': item?.RatePlan?.Rate?.displayName ?? '-',
              'Route': 0 < _.pluck(item?.Vendor?.RoutePlan?.Route, 'displayName')?.length ? _.pluck(item?.Vendor?.RoutePlan?.Route, 'displayName')?.join(',') : '-',
              'Currency': item?.Billing?.currency ?? '-',
              'Status': item?.Smpp?.isActive ? 'Active' : 'In Active'
            }))}
            headersData={[
              'S.No.',
              'CreatedAt',
              'UpdatedAt',
              'Name',
              'Vendor',
              'Rate',
              'Route',
              'Currency',
              'Status'
            ].map(i => ({ 'key': i, 'label': i }))}
          />
        </Flex>
        <TableContainer
          maxH='calc(100vh - 260px)'
          flex={1}
          display='flex'
          borderRadius='15px'
          outline='1px solid #C5CFE8'
          overflowY='auto'>
          {_QueryVendorAccountRead.loading ? (
            <Flex w='100%' h='100%' display='flex' alignItems='center' justifyContent='center'>
              <TableSpinner isLoading={true} />
            </Flex>
          ) : 0 === _QueryVendorAccountRead.data?.VendorAccountRead.length || !_.every(_.pluck(_QueryVendorAccountRead.data?.VendorAccountRead, 'status'), i => 'READ_SUCCESSFUL' === i) ? (
            <Flex w='100%' h='100%' display='flex' alignItems='center' justifyContent='center'>
              <TableSpinner isLoading={false} isEmpty={true} />
            </Flex>
          ) : (
            <Table variant='simple' display='block' size='md'>
              <Thead>
                <Tr style={{ ...headerStyle, position: 'sticky', top: 0, zIndex: 1 }}>

                  <Td
                    py='10px'
                    textAlign='center'
                    px='0px'
                    style={rowStyle}>S.No.</Td>
                  <Td style={rowStyle}>CreatedAt</Td>
                  <Td style={rowStyle}>UpdatedAt</Td>
                  <Td width='100%' style={rowStyle}>Name</Td>
                  <Td style={rowStyle}>Ip</Td>
                  <Td style={rowStyle}>Sender Id</Td>
                  <Td style={rowStyle}>Tps</Td>
                  <Td style={rowStyle}>Port</Td>
                  <Td style={rowStyle}>Sessions</Td>
                  <Td style={rowStyle}>Status</Td>
                  <Td style={rowStyle}>Actions</Td>
                </Tr>
              </Thead>
              <Tbody style={cellStyle}>
                {_QueryVendorAccountRead.data?.VendorAccountRead?.map(({ id, createdAt, updatedAt, Vendor, Smpp }, __index) => (
                  <Tr key={String.random(9)}>
                    <Td
                      style={rowStyle}
                      borderRight='1px solid rgba(216, 227, 252, 1)'>
                      {`${(__index + (0 < skipPage ? _skipDifference : 0) + 1)}.`}
                    </Td>
                    <Td
                      style={rowStyle}
                      borderRight='1px solid rgba(216, 227, 252, 1)'>
                      {Moment(createdAt).format('YYYY-MM-DD hh:mm:ss A')}
                    </Td>
                    <Td
                      style={rowStyle}
                      borderRight='1px solid rgba(216, 227, 252, 1)'>
                      {Moment(updatedAt).format('YYYY-MM-DD hh:mm:ss A')}
                    </Td>
                    <Td
                      style={rowStyle}
                      borderRight='1px solid rgba(216, 227, 252, 1)'>
                      {Vendor.displayName}
                    </Td>
                    <Td
                      style={rowStyle}
                      borderRight='1px solid rgba(216, 227, 252, 1)'>
                      {Smpp?.ip}
                    </Td>
                    <Td
                      style={rowStyle}
                      borderRight='1px solid rgba(216, 227, 252, 1)'>
                      {Smpp?.sourceAddress ?? '-'}
                    </Td>
                    <Td
                      style={rowStyle}
                      borderRight='1px solid rgba(216, 227, 252, 1)'>
                      {Smpp?.tps}
                    </Td>
                    <Td
                      style={rowStyle}
                      borderRight='1px solid rgba(216, 227, 252, 1)'>
                      {Smpp?.port}
                    </Td>
                    <Td
                      style={rowStyle}
                      borderRight='1px solid rgba(216, 227, 252, 1)'>
                      {Smpp?.sessionAllowed}
                    </Td>
                    <Td
                      style={rowStyle}
                      borderRight='1px solid rgba(216, 227, 252, 1)'>
                      {
                        Smpp?.isActive ? (
                          <Text
                            width='max-content'
                            borderRadius='10px'
                            padding='5px 15px 5px 15px'
                            color='rgba(117, 81, 255, 1)'
                            bg='rgba(117, 81, 255, .1)'>
                            Running
                          </Text>
                        ) : (
                          <Text
                            width='max-content'
                            borderRadius='8px'
                            padding='5px 15px 5px 15px'
                            color='gray.500'
                            bg='rgba(42, 42, 42, 0.03)'>
                            Stopped
                          </Text>
                        )
                      }
                    </Td>
                    <Td
                      style={rowStyle}>
                      {' '}
                      {
                        <VendorAccountDropDownOptions
                          vendorAccountId={id}
                          smppId={Smpp?.id}
                          contentTranslationId={_.first(Smpp?.ContentTranslation)?.id}
                          onVendorAccountUpdate={onVendorAccountUpdateOpen}
                          onVendorAccountDelete={onVendorAccountDeleteOpen}
                          onAddSenderID={onSenderIDAddOpen}
                          onContentTranslation={onContentTranslationOpen}
                        />
                      }
                    </Td>
                  </Tr>
                ))}
              </Tbody>
            </Table>
          )
          }
        </TableContainer>
      </Flex>
      <Pagination itemsPerPage={_skipDifference} totalCount={_.pluck(_QueryVendorAccountRead.data?.VendorAccountRead, '_totalCount')?.[0]} onPageChange={setSkipPage} />
      <Modal
        size='2xl'
        title='Connect Mapping Create'
        isOpen={isVendorAccountCreateOpen}
        onClose={onVendorAccountCreateClose}
        isCentered={false}>
        <VendorAccountUpsert isCreateOnly={true} />
      </Modal>
      <Modal
        size='2xl'
        title='Connect Mapping Update'
        isOpen={isVendorAccountUpdateOpen}
        onClose={onVendorAccountUpdateClose}
        isCentered={false}>
        <VendorAccountUpsert />
      </Modal>
      <Modal
        size='md'
        title='Content Translation'
        isOpen={isContentTranslatonOpen}
        onClose={onContentTranslationClose}>
        <ContentTranslation />
      </Modal>
      <Modal
        size='md'
        title='Add Sender ID'
        isOpen={isSenderIDAddOpen}
        onClose={onSenderIDAddClose}>
        <SenderID />
      </Modal>
      <Modal
        size='md'
        title='Connect Mapping Delete'
        isOpen={isVendorAccountDeleteOpen}
        onClose={onVendorAccountDeleteClose}>
        <VendorAccountDelete />
      </Modal>
    </Flex>
  )
}


/*
 * PROPTYPES
 */
Index.propTypes = {
  'passOn': PropTypes.object,
  'PassOnUpdate': PropTypes.func
}


/*
 * REDUX
 */
const _MapStateToProps = __state => ({ 'passOn': __state.PassOn })
const _MapDispatchToProps = __dispatch => ({ 'PassOnUpdate': __payload => __dispatch({ 'type': 'PASS_ON_UPDATE', 'PassOn': __payload }) })


/*
 * EXPORT
 */
export default connect(_MapStateToProps, _MapDispatchToProps)(Index)
