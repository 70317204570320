/*
 * IMPORTS
 */
import { gql } from '@apollo/client' // Npm: Apollo client for handling graphql request.


/*
 * EXPORTS
 */
export default gql`
query SmppReadQuery($smppId: ID) {
  SmppRead(smppId: $smppId) {
    sourceAddress,
    message,
    status
  }
}
`
