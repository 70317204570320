/*
 * IMPORTS
 */
import React from 'react' // Npm: react.js library.
import PropTypes from 'prop-types' // Npm: react.js library.
import { HiCheckCircle } from 'react-icons/hi2' // Npm: React icons.
import {
  Button,
  Flex,
  Spinner,
  Text
} from '@chakra-ui/react' // Npm: Chakra UI components.


/*
 * PACKAGES
 */
import { MemoizedInput } from 'components/MemoizedInput'


/*
 * OBJECTS
 */
const Index = ({ subHeading, onConfirm, isLoading }) => (
  <>
    <Flex
      direction='column'
      align='center'
      justifyContent='space-between'
      pb='1rem'
      gap='1rem'>
      <Flex direction='row'
        align='center'
        justifyContent='space-between'
        width='100%'>
        <Flex
          direction='column'
          align='left'
          justifyContent='left'>
          <Text fontSize='21px'>Are you sure ?</Text>
          <Text color='red.500'>{subHeading}</Text>
        </Flex>
        <Button
          variant='basic'
          type='submit'
          width='50px'
          height='50px'
          borderRadius='100%'
          bg='brand.500'
          borderWidth='2px'
          borderColor='brand.500'
          borderStyle='solid'
          color='white'
          p='0'
          _hover={{ 'bg': 'brand.500', 'color': 'white' }}
          _active={{ 'bg': 'brand.500', 'color': 'white' }}
          _focus={{ 'bg': 'brand.500', 'color': 'white' }}
          onClick={onConfirm}>
          {isLoading ? <Spinner size='sm' /> : <HiCheckCircle size={19} />}
        </Button>
      </Flex>
    </Flex>
  </>
)


/*
 * PROPTYPES
 */
Index.propTypes = {
  'icon': PropTypes.any,
  'subHeading': PropTypes.string,
  'onConfirm': PropTypes.func,
  'isLoading': PropTypes.bool,
  'onClose': PropTypes.func
}
Index.defaultProps = {
  'subHeading': 'That you want to delete this.'
}


/*
 * EXPORT
 */
export default Index
