/*
 * IMPORTS
 */
import { mode } from '@chakra-ui/theme-tools' // Npm: Chakra UI theme tools.


/*
 * EXPORTS
 */
export const sliderStyles = {
  'components': {
    'RangeSlider': {
      'variants': {
        'main': props => ({
          'thumb': {
            'bg': mode('brand.500', 'brand.400')(props)
          }
        })
      }
    }
  }
}
