/*
 * IMPORTS
 */
import React from 'react' // Npm: react.js library.
import PropTypes from 'prop-types' // Npm: react.js library.
import JoiBrowser from 'joi-browser' // Npm: Joi for frontend validation.
import _ from 'underscore' // Npm: Underscore.js library.
import { connect } from 'react-redux' // Npm: React Redux for state management.
import { useLazyQuery, useMutation } from '@apollo/client' // Npm: Apollo client.
import { toast } from 'react-hot-toast' // Npm: React hot toast.
import {
  Flex,
  FormControl
} from '@chakra-ui/react' // Npm: Chakra UI components.



/*
 * PACKAGES
 */
import ModalFooter from 'components/ModalFooter'
import { MemoizedInput, MemoizedSelect } from 'components/MemoizedInput'


/*
 * GRAPHS
 */
import VendorAccountDeleteMutation from './__mutation__/index.vendorAccount.delete.mutation'
import VendorAccountReadQuery from './__query__/index.vendorAccount.read.query'
import VendorAccountUpdateMutation from './__mutation__/index.vendorAccount.update.mutation'
import VendorAccountCreateMutation from './__mutation__/index.vendorAccount.create.mutation'
import VendorAccountAttachSmppMutation from './__mutation__/index.vendorAccount.attachSmpp.mutation'
import SmppUpdateMutation from './__mutation__/index.smpp.update.mutation'
import SmppCreateMutation from './__mutation__/index.smpp.create.mutation'


/*
 * STYLES
 */
import './index.style.css'


/*
 * OBJECTS
 */
const Index = ({
  isOpen,
  isCreateOnly,
  onClose,
  passOn
}) => {
  // Hook assignment.
  const [error, setError] = React.useState('')
  const [forceReRender, setForceReRender] = React.useState('')
  const [MutationVendorAccountCreate, MutationVendorAccountCreateResponse] = useMutation(VendorAccountCreateMutation)
  const [MutationVendorAccountUpdate, MutationVendorAccountUpdateResponse] = useMutation(VendorAccountUpdateMutation)
  const [QueryVendorAccountRead] = useLazyQuery(VendorAccountReadQuery)
  const [MutationVendorAccountDelete] = useMutation(VendorAccountDeleteMutation)
  const [MutationSmppCreate, MutationSmppCreateResponse] = useMutation(SmppCreateMutation)
  const [MutationSmppUpdate, MutationSmppUpdateResponse] = useMutation(SmppUpdateMutation)
  const [MutationAttachSmpp, MutationAttachSmppResponse] = useMutation(VendorAccountAttachSmppMutation)
  const _formDataRef = React.useRef({})
  const [editable, setEditable] = React.useState(false) // State to track whether data has been fetched

  // Object assignment.
  const _SubmitForm = async e => {
    // Prevent default behavior.
    e?.preventDefault()

    // Const assignment.
    const _JoiSchema = JoiBrowser.object({
      'displayName': JoiBrowser.string().required(),
      'ip': JoiBrowser.string().max(45).ip({ 'version': ['ipv4', 'ipv6', 'ipvfuture'], 'cidr': 'optional' }).required(),
      'username': JoiBrowser.string().required(),
      'password': JoiBrowser.string().required(),
      'txPort': JoiBrowser.number().required(),
      'rxPort': JoiBrowser.number().required(),
      'sessionTimeoutInterval': JoiBrowser.number().greater(2999).required(),
      'sessionAllowed': JoiBrowser.number().greater(0).less(5).optional(),
      'tps': JoiBrowser.number().greater(0).optional(),
      'enquireLinkInterval': JoiBrowser.number().greater(2999).required(),
      'maxReconnectAttempts': JoiBrowser.number().optional(),
      'reconnectBackoffFactor': JoiBrowser.number().optional(),
      'initialReconnectDelay': JoiBrowser.number().optional(),
      'sourceAddressTon': JoiBrowser.number().optional(),
      'sourceAddressNpi': JoiBrowser.number().optional(),
      'destinationAddressTon': JoiBrowser.number().optional(),
      'destinationAddressNpi': JoiBrowser.number().optional()
    }).options({ 'allowUnknown': true })

    // Remove all keys from _formDataRef.current which are undefined.
    _formDataRef.current = _.pick(_formDataRef.current, _.identity)

    // Validate form data.
    const _JoiSchemaValidate = _JoiSchema.validate(_formDataRef.current)

    // If error exists then report failure.
    if (_JoiSchemaValidate.error) return setError(_JoiSchemaValidate.error?.message)

    // Reset error.
    setError('')

    // Create VendorAccount for given Vendor.
    const _MutationVendorAccount = await [isCreateOnly ? MutationVendorAccountCreate : MutationVendorAccountUpdate]?.[0]({
      'variables': {
        [isCreateOnly ? 'vendorId' : 'vendorAccountId']: isCreateOnly ? passOn?.vendorId : passOn?.vendorAccountId,
        'displayName': _formDataRef?.current?.displayName
      }
    })

    // If creating or updating vendor account caught an exception then report failure.
    if (_MutationVendorAccount instanceof Error) return _MutationVendorAccount

    // Execute update mutation.
    const _MutationSmpp = await [isCreateOnly ? MutationSmppCreate : MutationSmppUpdate]?.[0]({
      'variables': {
        'smppId': isCreateOnly || _.isEmpty(_MutationVendorAccount?.data?.VendorAccountUpdate?.Smpp) ? 'UN_KNOWN' : _MutationVendorAccount?.data?.VendorAccountUpdate?.Smpp?.id,
        'displayName': _formDataRef?.current?.displayName,
        'ip': _formDataRef?.current?.ip,
        'username': _formDataRef?.current?.username,
        'password': _formDataRef?.current?.password,
        'port': _formDataRef?.current?.txPort,
        'sessionTimeoutInterval': _formDataRef?.current?.sessionTimeoutInterval,
        'sessionAllowed': _formDataRef?.current?.sessionAllowed,
        'tps': _formDataRef?.current?.tps,
        'enquireLinkInterval': _formDataRef?.current?.enquireLinkInterval,
        'maxReconnectAttempts': _formDataRef?.current?.maxReconnectAttempts,
        'reconnectBackoffFactor': _formDataRef?.current?.reconnectBackoffFactor,
        'initialReconnectDelay': _formDataRef?.current?.initialReconnectDelay,
        'sourceAddressTon': _formDataRef?.current?.sourceAddressTon,
        'sourceAddressNpi': _formDataRef?.current?.sourceAddressNpi,
        'destinationAddressTon': _formDataRef?.current?.destinationAddressTon,
        'destinationAddressNpi': _formDataRef?.current?.destinationAddressNpi
      }
    })

    // If mutation caught an exception then report failure.
    if (_MutationSmpp instanceof Error) return _MutationSmpp
    if (isCreateOnly && 'HOST_DOWN_OR_UNREACHABLE' === _MutationSmpp?.data?.SmppCreate?.status) return MutationVendorAccountDelete({ 'variables': { 'vendorAccountId': _MutationVendorAccount?.data.VendorAccountCreate?.id } })

    // Style Guide.
    toast(_MutationSmpp?.data?.SmppCreate?.message ?? _MutationSmpp?.data?.SmppUpdate?.message)

    // On Successful response from the mutation.
    if ('CREATE_SUCCESSFUL' === _MutationSmpp?.data?.SmppCreate?.status) {
      // Attach given vendor with smpp.
      const _MutationAttachSmpp = await MutationAttachSmpp({ 'variables': { 'vendorAccountId': _MutationVendorAccount?.data[isCreateOnly ? 'VendorAccountCreate' : 'VendorAccountUpdate']?.id, 'smppId': _MutationSmpp?.data?.SmppCreate?.id } })

      // If mutation caught an exception then report failure.
      if (_MutationAttachSmpp instanceof Error) return _MutationAttachSmpp

      // Only close if attach is successful.
      if ('UPDATE_SUCCESSFUL' === _MutationAttachSmpp?.data?.VendorAccountAttachSmpp?.status) return onClose?.(_MutationAttachSmpp?.data?.VendorAccountAttachSmpp, _formDataRef?.current)
    }

    // Only close if attach is successful.
    if ('UPDATE_SUCCESSFUL' === _MutationSmpp?.data?.SmppUpdate?.status) return onClose?.(_MutationSmpp?.data?.SmppUpdate, _formDataRef?.current)

    // Return void 0.
    return void 0
  }

  // Event handler.
  React.useEffect(() => {
    // _Async handler.
    const _Async = async () => {
      // Const assignment.
      const _QueryVendorAccountReadQuery = await QueryVendorAccountRead({ 'variables': { 'vendorAccountId': isCreateOnly ? 'UN_KNOWN' : passOn?.vendorAccountId } })

      // If query caught an exception then report failure.
      if (_QueryVendorAccountReadQuery instanceof Error) return _QueryVendorAccountReadQuery

      // Const assignment.
      const _vendorAccountRead = _.first(_QueryVendorAccountReadQuery?.data?.VendorAccountRead)

      /*
       * If smpp details fetch complete then
       * update its value.
       */
      if (!_.isEmpty(_vendorAccountRead)) {
        // Update form data.
        _formDataRef.current = {
          'displayName': _vendorAccountRead?.Smpp?.displayName,
          'ip': _vendorAccountRead?.Smpp?.ip,
          'username': _vendorAccountRead?.Smpp?.username,
          'password': _vendorAccountRead?.Smpp?.password,
          'txPort': _vendorAccountRead?.Smpp?.port,
          'rxPort': _vendorAccountRead?.Smpp?.port,
          'sessionTimeoutInterval': _vendorAccountRead?.Smpp?.sessionTimeoutInterval,
          'sessionAllowed': _vendorAccountRead?.Smpp?.sessionAllowed,
          'tps': _vendorAccountRead?.Smpp?.tps,
          'enquireLinkInterval': _vendorAccountRead?.Smpp?.enquireLinkInterval,
          'maxReconnectAttempts': _vendorAccountRead?.Smpp?.maxReconnectAttempts,
          'reconnectBackoffFactor': _vendorAccountRead?.Smpp?.reconnectBackoffFactor,
          'initialReconnectDelay': _vendorAccountRead?.Smpp?.initialReconnectDelay,
          'sourceAddressTon': _vendorAccountRead?.Smpp?.sourceAddressTon,
          'sourceAddressNpi': _vendorAccountRead?.Smpp?.sourceAddressNpi,
          'destinationAddressTon': _vendorAccountRead?.Smpp?.destinationAddressTon,
          'destinationAddressNpi': _vendorAccountRead?.Smpp?.destinationAddressNpi
        }

        setEditable(true)

        // Update state.
        return setForceReRender(String.random(8))
      }

      // Report failure.
      return void 0
    } ;_Async().catch(i => i)
  }, [passOn, isOpen])

  // Return component.
  return (
    <form onSubmit={_SubmitForm} key={forceReRender}>
      <Flex>
        <Flex gap='20px' flexDir='column' w='100%'>
          <Flex gap='20px'>
            <FormControl>
              <MemoizedInput
                isRequired={true}
                name='displayName'
                label='Display name'
                placeholder='e.g. "Rocking Smpp"'
                onChange={({ target }) => {
                  // Over spreading.
                  const { name, value } = target

                  // Update form data.
                  _formDataRef.current = {
                    ..._formDataRef?.current,
                    [name]: value
                  }
                }}
                isInvalid={error?.includes('displayName')}
                error={error}
                data={_formDataRef?.current?.displayName}
                disabled={!editable} // Disable input if data is fetched
              />
            </FormControl>
            <FormControl>
              <MemoizedInput
                name='ip'
                label='ip'
                placeholder='e.g. "0.0.0.0"'
                onChange={({ target }) => {
                  // Over spreading.
                  const { name, value } = target

                  // Update form data.
                  _formDataRef.current = {
                    ..._formDataRef?.current,
                    [name]: value
                  }
                }}
                isRequired={true}
                error={error}
                isInvalid={error?.includes('ip')}
                data={_formDataRef?.current?.ip}
                disabled={!editable}

              />
            </FormControl>
          </Flex>
          <Flex gap='20px'>
            <FormControl>
              <MemoizedInput
                name='username'
                label='SystemId'
                placeholder='e.g. "RockSmpp"'
                onChange={({ target }) => {
                  // Over spreading.
                  const { name, value } = target

                  // Update form data.
                  _formDataRef.current = {
                    ..._formDataRef?.current,
                    [name]: value
                  }
                }}
                isRequired={true}
                error={error}
                isInvalid={error?.includes('username')}
                data={_formDataRef?.current?.username}
                disabled={!editable}

              />
            </FormControl>
            <FormControl>
              <MemoizedInput
                name='password'
                label='Password'
                placeholder='e.g. "*******"'
                onChange={({ target }) => {
                  // Over spreading.
                  const { name, value } = target

                  // Update form data.
                  _formDataRef.current = {
                    ..._formDataRef?.current,
                    [name]: value
                  }
                }}
                isRequired={true}
                error={error}
                isInvalid={error?.includes('password')}
                data={_formDataRef?.current?.password}
                disabled={!editable}

              />
            </FormControl>
          </Flex>
          <Flex gap='20px'>
            <FormControl>
              <MemoizedInput
                name='txPort'
                label='Transmitter Port'
                placeholder='e.g. "2775"'
                onChange={({ target }) => {
                  // Over spreading.
                  const { name, value } = target

                  // Update form data.
                  _formDataRef.current = {
                    ..._formDataRef?.current,
                    [name]: Number(value)
                  }
                }}
                isRequired={true}
                error={error}
                isInvalid={error?.includes('txPort')}
                data={_formDataRef?.current?.txPort}
                disabled={!editable}

              />
            </FormControl>
            <FormControl>
              <MemoizedInput
                name='rxPort'
                label='Receiver Port'
                placeholder='e.g. "2775"'
                onChange={({ target }) => {
                  // Over spreading.
                  const { name, value } = target

                  // Update form data.
                  _formDataRef.current = {
                    ..._formDataRef?.current,
                    [name]: Number(value)
                  }
                }}
                isRequired={true}
                error={error}
                isInvalid={error?.includes('rxPort')}
                data={_formDataRef?.current?.rxPort}
                disabled={!editable}

              />
            </FormControl>
          </Flex>
          <Flex gap='20px'>
            <FormControl>
              <MemoizedInput
                isRequired={true}
                name='sessionTimeoutInterval'
                label='Response Time '
                placeholder='e.g. "300"'
                onChange={({ target }) => {
                  // Over spreading.
                  const { name, value } = target

                  // Update form data.
                  _formDataRef.current = {
                    ..._formDataRef?.current,
                    [name]: Number(value)
                  }
                }}
                error={error}
                isInvalid={error?.includes('sessionTimeoutInterval')}
                data={_formDataRef?.current?.sessionTimeoutInterval}
                disabled={!editable}

              />
            </FormControl>
            <FormControl>
              <MemoizedSelect
                name='sessionAllowed'
                label='Session Allowed'
                placeholder='e.g. "2"'
                onChange={({ target }) => {
                  // Over spreading.
                  const { name, value } = target

                  // Update form data.
                  _formDataRef.current = {
                    ..._formDataRef?.current,
                    [name]: Number(value)
                  }
                }}
                error={error}
                options={[
                  1,
                  2,
                  3,
                  4,
                  5
                ].map(i => i)}
                isInvalid={error?.includes('sessionAllowed')}
                data={_formDataRef?.current?.sessionAllowed}
                disabled={!editable}

              />
            </FormControl>
          </Flex>
          <Flex gap='20px'>
            <FormControl>
              <MemoizedInput
                name='tps'
                label='Submit Speed Per Second'
                placeholder='e.g. "1000"'
                onChange={({ target }) => {
                  // Over spreading.
                  const { name, value } = target

                  // Update form data.
                  _formDataRef.current = {
                    ..._formDataRef?.current,
                    [name]: Number(value)
                  }
                }}
                error={error}
                isInvalid={error?.includes('tps')}
                data={_formDataRef?.current?.tps}
                disabled={!editable}

              />
            </FormControl>
            <FormControl>
              <MemoizedInput
                isRequired={true}
                name='enquireLinkInterval'
                label='Enquire link '
                placeholder='e.g. "60"'
                onChange={({ target }) => {
                  // Over spreading.
                  const { name, value } = target

                  // Update form data.
                  _formDataRef.current = {
                    ..._formDataRef?.current,
                    [name]: Number(value)
                  }
                }}
                error={error}
                isInvalid={error?.includes('enquireLinkInterval')}
                data={_formDataRef?.current?.enquireLinkInterval}
                disabled={!editable}

              />
            </FormControl>
          </Flex>
          <Flex gap='20px'>
            <FormControl>
              <MemoizedInput
                name='sourceAddressTon'
                label='SourceAddress ton'
                placeholder='e.g. "0"'
                onChange={({ target }) => {
                  // Over spreading.
                  const { name, value } = target

                  // Update form data.
                  _formDataRef.current = {
                    ..._formDataRef?.current,
                    [name]: Number(value)
                  }
                }}
                error={error}
                isInvalid={error?.includes('sourceAddressTon')}
                data={_formDataRef?.current?.sourceAddressTon}
                disabled={!editable}

              />
            </FormControl>
            <FormControl>
              <MemoizedInput
                name='sourceAddressNpi'
                label='SourceAddress npi'
                placeholder='e.g. "0"'
                onChange={({ target }) => {
                  // Over spreading.
                  const { name, value } = target

                  // Update form data.
                  _formDataRef.current = {
                    ..._formDataRef?.current,
                    [name]: Number(value)
                  }
                }}
                error={error}
                isInvalid={error?.includes('sourceAddressNpi')}
                data={_formDataRef?.current?.sourceAddressNpi}
                disabled={!editable}

              />
            </FormControl>
          </Flex>
          <Flex gap='20px'>
            <FormControl>
              <MemoizedInput
                name='destinationAddressTon'
                label='DestinationAddress Ton'
                placeholder='e.g. "1"'
                onChange={({ target }) => {
                  // Over spreading.
                  const { name, value } = target

                  // Update form data.
                  _formDataRef.current = {
                    ..._formDataRef?.current,
                    [name]: Number(value)
                  }
                }}
                error={error}
                isInvalid={error?.includes('destinationAddressTon')}
                data={_formDataRef?.current?.destinationAddressTon}
                disabled={!editable}

              />
            </FormControl>
            <FormControl>
              <MemoizedInput
                name='destinationAddressNpi'
                label='DestinationAddress npi'
                placeholder='e.g. "1"'
                onChange={({ target }) => {
                  // Over spreading.
                  const { name, value } = target

                  // Update form data.
                  _formDataRef.current = {
                    ..._formDataRef?.current,
                    [name]: Number(value)
                  }
                }}
                error={error}
                isInvalid={error?.includes('destinationAddressNpi')}
                data={_formDataRef?.current?.destinationAddressNpi}
                disabled={!editable}

              />
            </FormControl>
          </Flex>
        </Flex>
      </Flex>
      <ModalFooter isLoading={MutationAttachSmppResponse.loading || MutationVendorAccountCreateResponse.loading || MutationVendorAccountUpdateResponse.loading || MutationSmppCreateResponse.loading || MutationSmppUpdateResponse.loading} onSubmit={_SubmitForm} />
    </form >
  )
}


/*
 * PROPTYPES
 */
Index.propTypes = {
  'onClose': PropTypes.func,
  'passOn': PropTypes.object,
  'isOpen': PropTypes.bool,
  'isCreateOnly': PropTypes.bool
}
Index.defaultProps = {}


/*
 * REDUX
 */
const _MapStateToProps = __state => ({ 'passOn': __state.PassOn })


/*
 * EXPORT
 */
export default connect(_MapStateToProps)(Index)
