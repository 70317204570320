/*
 * IMPORTS
 */
import React from 'react' // Npm: react.js library.
import PropTypes from 'prop-types' // Npm: react.js library.
import JoiBrowser from 'joi-browser' // Npm: Joi for frontend validation.
import _ from 'underscore' // Npm: Underscore.js library.
import { connect } from 'react-redux' // Npm: React Redux for state management.
import { useLazyQuery, useMutation } from '@apollo/client' // Npm: Apollo client.
import { toast } from 'react-hot-toast' // Npm: React hot toast.
import {
  Flex,
  FormControl
} from '@chakra-ui/react' // Npm: Chakra UI components.


/*
 * PACKAGES
 */
import ModalFooter from 'components/ModalFooter'
import { MemoizedInput } from 'components/MemoizedInput'


/*
 * GRAPHS
 */
import VendorReadQuery from './__query__/index.vendor.read.query'
import VendorUpdateMutation from './__mutation__/index.vendor.update.mutation'
import VendorCreateMutation from './__mutation__/index.vendor.create.mutation'


/*
 * OBJECTS
 */
const Index = ({ isOpen, isCreateOnly, onClose, passOn }) => {
  // Hook assignment.
  const [error, setError] = React.useState('')
  const [forceReRender, setForceReRender] = React.useState('')
  const [QueryVendorRead] = useLazyQuery(VendorReadQuery, { 'variables': { 'vendorId': passOn?.vendorId } })
  const [MutationVendorCreate, MutationVendorCreateResponse] = useMutation(VendorCreateMutation)
  const [MutationVendorUpdate, MutationVendorUpdateResponse] = useMutation(VendorUpdateMutation)
  const _formDataRef = React.useRef({})
  const [editable, setEditable] = React.useState(false) // State to track whether data has been fetched

  // Object assignment.
  const _SubmitForm = async e => {
    // Prevent default behavior.
    e.preventDefault()

    // Const assignment.
    const _JoiSchema = JoiBrowser.object({
      'vendorId': JoiBrowser.string().optional(),
      'displayName': JoiBrowser.string().required(),
      'website': JoiBrowser.string().optional()
    }).options({ 'allowUnknown': true })

    // Remove all keys from _formDataRef.current which are undefined.
    _formDataRef.current = _.pick(_formDataRef.current, _.identity)

    // Validate form data.
    const _JoiSchemaValidate = _JoiSchema.validate(_formDataRef.current)

    // If error exists then report failure.
    if (_JoiSchemaValidate.error) return setError(_JoiSchemaValidate.error?.message)

    /*
     * Push vendorId if not found on the _formDataRef.
     * also make sure that isCreateOnly is false.
     */
    if (!isCreateOnly && _.isEmpty(_formDataRef.current?.vendorId)) _formDataRef.current = { ..._formDataRef.current, 'vendorId': passOn?.vendorId }

    // Execute update mutation.
    const _MutationVendorUpdate = await [isCreateOnly ? MutationVendorCreate : MutationVendorUpdate]?.[0]({ 'variables': _.omit(_formDataRef?.current, isCreateOnly ? 'vendorId' : void 0) })

    // If mutation caught an exception then report failure.
    if (_MutationVendorUpdate instanceof Error) return _MutationVendorUpdate

    // Style Guide.
    toast(_MutationVendorUpdate?.data?.VendorCreate?.message || _MutationVendorUpdate?.data?.VendorUpdate?.message)

    // Execute onClose if response is successful.
    if ('CREATE_SUCCESSFUL' === _MutationVendorUpdate?.data?.VendorCreate?.status || 'UPDATE_SUCCESSFUL' === _MutationVendorUpdate?.data?.VendorUpdate?.status) return onClose?.()

    // Report void 0.
    return void 0
  }

  // Event handler.
  React.useEffect(() => {
    // _Async handler.
    const _Async = async () => {
      // Const assignment.
      const _QueryVendorReadQuery = await QueryVendorRead({ 'variables': { 'vendorId': isCreateOnly ? 'UN_KNOWN' : passOn?.vendorId } })

      // If query caught an exception then report failure.
      if (_QueryVendorReadQuery instanceof Error) return _QueryVendorReadQuery

      /*
       * If vendor details fetch complete then
       * update its value.
       */
      if (0 < _QueryVendorReadQuery?.data?.VendorRead?.length) {
        // Const assignment.
        const _data = _.first(_QueryVendorReadQuery?.data?.VendorRead)

        // Update form data.
        _formDataRef.current = {
          'displayName': _data.displayName,
          'website': _data.website
        }

        setEditable(true)

        // Update state.
        return setForceReRender(String.random(8))
      }

      // Report failure.
      return void 0
    } ;_Async().catch(i => i)
  }, [passOn, isOpen])

  // Return component.
  return (
    <form onSubmit={_SubmitForm} key={forceReRender}>
      <Flex>
        <Flex gap='20px' flexDir='column' w='100%'>
          <Flex gap='20px'>
            <FormControl isRequired>
              <MemoizedInput
                isRequired={true}
                name='displayName'
                label='Display Name'
                placeholder='e.g. "Something Amazing"'
                onChange={ ({ target }) => {
                  // Over spreading.
                  const { name, value } = target

                  // Update form data.
                  _formDataRef.current = {
                    ..._formDataRef?.current,
                    [name]: value
                  }
                }}
                isInvalid={error?.includes('displayName')}
                error={error}
                data={_formDataRef?.current?.displayName}
                disabled={!editable}
              />
            </FormControl>
          </Flex>
          <Flex gap='20px'>
            <FormControl isRequired>
              <MemoizedInput
                isRequired={true}
                name='website'
                label='Website'
                placeholder='e.g. "https://www.rockstargames.com"'
                onChange={ ({ target }) => {
                  // Over spreading.
                  const { name, value } = target

                  // Update form data.
                  _formDataRef.current = {
                    ..._formDataRef?.current,
                    [name]: value
                  }
                }}
                error={error}
                isInvalid={error?.includes('website')}
                data={_formDataRef?.current?.website}
                disabled={!editable}

              />
            </FormControl>
          </Flex>
        </Flex>
      </Flex>
      <ModalFooter isLoading={MutationVendorUpdateResponse.loading || MutationVendorCreateResponse.loading} onSubmit={_SubmitForm} />
    </form>
  )
}


/*
 * PROPTYPES
 */
Index.propTypes = {
  'isCreateOnly': PropTypes.bool,
  'isOpen': PropTypes.bool,
  'onClose': PropTypes.func,
  'passOn': PropTypes.object
}


/*
 * REDUX
 */
const _MapStateToProps = __state => ({ 'passOn': __state.PassOn })


/*
 * EXPORT
 */
export default connect(_MapStateToProps)(Index)
