/*
 * IMPORTS
 */
import { gql } from '@apollo/client' // Npm: Apollo client.


/*
 * EXPORTS
 */
export default gql`
mutation RouteDelete($routeId: ID!) {
    RouteDelete(routeId: $routeId) {
        message
        status
    }
} 
`

