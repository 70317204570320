/*
 * IMPORTS
 */
import React from 'react' // Npm: React.js library
import PropTypes from 'prop-types' // Npm: Prop types validator.
import ClassName from 'classnames' // Npm: Classnames utility.


/*
 * UTILS
 */
import styles from '../../util/index.style'



/*
 * SIBLINGS
 */
import { defaultInputRanges, defaultStaticRanges } from '../DefaultRange'



class DefinedRange extends React.Component {
  constructor(props) {
    // Call parent constructor.
    super(props)

    // State assignment.
    this.state = {
      'rangeOffset': 0,
      'focusedInput': -1
    }
  }

  /*
   * HandleRangeChange
   * Method for handling range change.
   */
  handleRangeChange = range => {
    // Const assignment.
    const { onChange, ranges, focusedRange } = this.props
    const selectedRange = ranges[focusedRange[0]]

    // OnChange or selectedRange check.
    if (!onChange || !selectedRange) return

    // Call onChange function.
    onChange({
      [selectedRange.key || `range${focusedRange[0] + 1}`]: { ...selectedRange, ...range }
    })
  }

  /*
   * GetRangeOptionValue
   * Method for getting range option value.
   */
  getRangeOptionValue(option) {
    // Const assignment.
    const { ranges = [], focusedRange = [] } = this.props

    // If statement for checking option.
    if ('function' !== typeof option.getCurrentValue) return ''

    // Const assignment.
    const selectedRange = ranges[focusedRange[0]] || {}

    // Return value.
    return option.getCurrentValue(selectedRange) || ''
  }

  /*
   * GetSelectedRange
   */
  // eslint-disable-next-line class-methods-use-this
  getSelectedRange(ranges, staticRange) {
    // Const assignment.
    const focusedRangeIndex = ranges.findIndex(range => {
      // If statement for checking range.
      if (!range.startDate || !range.endDate || range.disabled) return false

      // Return value.
      return staticRange.isSelected(range)
    })
    const selectedRange = ranges[focusedRangeIndex]

    //  Return value.
    return { selectedRange, focusedRangeIndex }
  }

  /*
   * Render
   * @return {object}
   * @description Render component.
   */
  render() {
    // Const assignment.
    const {
      headerContent,
      footerContent,
      onPreviewChange,
      staticRanges,
      ranges,
      renderStaticRangeLabel,
      rangeColors,
      className
    } = this.props

    // Return component.
    return (
      <div className={ClassName(styles.definedRangesWrapper, className)}>
        {headerContent}
        <div className={styles.staticRanges}>
          {staticRanges.map((staticRange, i) => {
            // Local variable.
            let labelContent

            // Destructuring assignment.
            const { selectedRange, focusedRangeIndex } = this.getSelectedRange(ranges, staticRange)

            // If statement for checking static range.
            if (staticRange.hasCustomRendering) {
              // Variable assignment.
              labelContent = renderStaticRangeLabel(staticRange)
            } else {
              // Variable assignment.
              labelContent = staticRange.label
            }

            // Return component.
            return (
              <button
                type='button'
                className={ClassName(styles.staticRange, { [styles.staticRangeSelected]: Boolean(selectedRange) })}
                style={{ 'color': selectedRange ? selectedRange.color || rangeColors[focusedRangeIndex] : null }}
                key={i}
                onClick={() => this.handleRangeChange(staticRange.range(this.props))}
                onFocus={() => onPreviewChange && onPreviewChange(staticRange.range(this.props))}
                onMouseOver={() => onPreviewChange && onPreviewChange(staticRange.range(this.props))}
                onMouseLeave={() => { onPreviewChange && onPreviewChange() }}>
                <span tabIndex={-1} className={styles.staticRangeLabel}>
                  {labelContent}
                </span>
              </button>
            )
          })}
        </div>
        {footerContent}
      </div>
    )
  }
}


/*
 * PROPTYPES
 */
DefinedRange.propTypes = {
  'inputRanges': PropTypes.array,
  'staticRanges': PropTypes.array,
  'ranges': PropTypes.arrayOf(PropTypes.shape({
    'startDate': PropTypes.object,
    'endDate': PropTypes.object,
    'color': PropTypes.string,
    'key': PropTypes.string,
    'autoFocus': PropTypes.bool,
    'disabled': PropTypes.bool,
    'showDateDisplay': PropTypes.bool
  })),
  'focusedRange': PropTypes.arrayOf(PropTypes.number),
  'onPreviewChange': PropTypes.func,
  'onChange': PropTypes.func,
  'footerContent': PropTypes.any,
  'headerContent': PropTypes.any,
  'rangeColors': PropTypes.arrayOf(PropTypes.string),
  'className': PropTypes.string,
  'renderStaticRangeLabel': PropTypes.func
}
DefinedRange.defaultProps = {
  'inputRanges': defaultInputRanges,
  'staticRanges': defaultStaticRanges,
  'ranges': [],
  'rangeColors': ['#511df6', '#ffac71', '#fed14c'],
  'focusedRange': [0, 0]
}


/*
 * EXPORTS
 */
export default DefinedRange
