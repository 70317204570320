/*
 * IMPORTS
 */
import React, { useRef, useState } from 'react' // Npm import: React.js library
import PropTypes from 'prop-types' // Npm import: Prop types validator.
import Classnames from 'classnames' // Npm import: Classnames utility.
import {
  addDays,
  differenceInCalendarDays,
  isBefore,
  isWithinInterval,
  max,
  min
} from 'date-fns' // Npm date-fns library.
import { Flex } from '@chakra-ui/react' // Npm: Chakra UI components.


/*
 * UTILS
 */
import Calendar from './Calendar/index'
import coreStyles from '../../util/index.style'
import { findNextRangeIndex, generateStyles } from '../../util'


/*
 * OBJECTS
 */
const DateRange = props => {
  // Hook assignment.
  const [focusedRange, setFocusedRange] = useState(props.initialFocusedRange || [findNextRangeIndex(props.ranges), 0])
  const [preview, setPreview] = useState(null)
  const styles = generateStyles([coreStyles, props.classNames])
  const calendarRef = useRef(null)

  // Object assignment.
  const calcNewSelection = (value, isSingleValue = true) => {
    // Local variable.
    let isStartDateSelected, nextFocusRange

    // Const assignment.
    const currentFocusedRange = props.focusedRange || focusedRange
    const {
      ranges,
      maxDate,
      moveRangeOnFirstSelection,
      retainEndDateOnFirstSelection,
      disabledDates
    } = props
    const focusedRangeIndex = currentFocusedRange[0]
    const selectedRange = ranges[focusedRangeIndex]

    // If statement for checking selected range.
    if (!selectedRange) return {}

    // Destructure assignment.
    // eslint-disable-next-line one-var
    let { startDate, endDate } = selectedRange

    // Const assignment.
    const now = new Date()

    // If statement for checking if single value.
    if (!isSingleValue) {
      // Const assignment.
      startDate = value.startDate
      endDate = value.endDate
    } else if (0 === currentFocusedRange[1]) {
      // StartDate selection
      const dayOffset = differenceInCalendarDays(endDate || now, startDate)

      // Object assignment.
      const calculateEndDate = () => {
        if (moveRangeOnFirstSelection) {
          return addDays(value, dayOffset)
        }
        if (retainEndDateOnFirstSelection) {
          if (!endDate || isBefore(value, endDate)) {
            return endDate
          }

          return value
        }

        return value || now
      }

      // Variable assignment.
      startDate = value
      endDate = calculateEndDate()

      // If statement for checking if max date.
      if (maxDate) endDate = min([endDate, maxDate])

      // If statement for checking if max date.
      nextFocusRange = [currentFocusedRange[0], 1]
    } else {
      // EndDate selection
      endDate = value
    }

    // If statement for checking if start date is selected.
    if (isBefore(endDate, startDate)) {
      // Swap dates.
      [startDate, endDate] = [endDate, startDate]
    }

    // If statement for checking if max date.
    const inValidDatesWithinRange = disabledDates.filter(disabledDate => isWithinInterval(disabledDate, { 'start': startDate, 'end': endDate }))

    // If statement for checking if max date.
    if (0 < inValidDatesWithinRange.length) {
      // If statement for checking if max date.
      if (isStartDateSelected) {
        // Variable assignment.
        startDate = addDays(max(inValidDatesWithinRange), 1)
      } else {
        // Variable assignment.
        endDate = addDays(min(inValidDatesWithinRange), -1)
      }
    }

    // If statement for checking if max date.
    if (!nextFocusRange) {
      // Variable assignment.
      const nextFocusRangeIndex = findNextRangeIndex(props.ranges, currentFocusedRange[0])
      nextFocusRange = [nextFocusRangeIndex, 0]
    }

    // Return value.
    return {
      'wasValid': !(0 < inValidDatesWithinRange.length),
      'range': { startDate, endDate },
      nextFocusRange
    }
  }
  const setSelection = (value, isSingleValue) => {
    // Const assignment.
    const { ranges, onRangeFocusChange } = props
    const currentFocusedRange = props.focusedRange || focusedRange
    const focusedRangeIndex = currentFocusedRange[0]
    const selectedRange = ranges[focusedRangeIndex]

    // If statement for checking selected range.
    if (!selectedRange) return

    // Variable assignment.
    const newSelection = calcNewSelection(value, isSingleValue)

    // If statement for checking new selection.
    if (props.onChange) {
      // Call onChange function.
      props.onChange({
        [selectedRange.key || `range${focusedRangeIndex + 1}`]: {
          ...selectedRange,
          ...newSelection.range
        }
      })
    }

    // If statement for checking new selection.
    setFocusedRange(newSelection.nextFocusRange)
    setPreview(null)
    onRangeFocusChange && onRangeFocusChange(newSelection.nextFocusRange)
  }

  // Object assignment.
  const handleRangeFocusChange = newFocusedRange => {
    // If statement for checking new focused range.
    setFocusedRange(newFocusedRange)

    // If statement for checking new focused range.
    props.onRangeFocusChange && props.onRangeFocusChange(newFocusedRange)
  }
  const updatePreview = val => {
    // If statement for checking value.
    if (!val) {
      // Set preview.
      setPreview(null)

      // Return.
      return
    }

    // Const assignment.
    const { rangeColors, ranges } = props
    const currentFocusedRange = props.focusedRange || focusedRange
    const color = ranges[currentFocusedRange[0]]?.color || rangeColors[currentFocusedRange[0]]

    // Set preview.
    setPreview({ ...val.range, color })
  }

  // Return component.
  return (
    <Flex borderRadius={12} overflow='hidden' flexDirection='column' justifyContent='space-between'>
      <Calendar
        focusedRange={focusedRange}
        onRangeFocusChange={handleRangeFocusChange}
        preview={preview}
        onPreviewChange={value => { updatePreview(value ? calcNewSelection(value) : null) }}
        {...props}
        displayMode='dateRange'
        className={Classnames(styles.dateRangeWrapper, props.className)}
        onChange={setSelection}
        updateRange={val => setSelection(val, false)}
        ref={calendarRef}
      />
    </Flex >
  )
}


/*
 * PROPTYPES
 */
DateRange.propTypes = {
  ...Calendar.propTypes,
  'onChange': PropTypes.func,
  'onRangeFocusChange': PropTypes.func,
  'className': PropTypes.string,
  'ranges': PropTypes.arrayOf(PropTypes.shape({
    'startDate': PropTypes.object,
    'endDate': PropTypes.object,
    'color': PropTypes.string,
    'key': PropTypes.string,
    'autoFocus': PropTypes.bool,
    'disabled': PropTypes.bool,
    'showDateDisplay': PropTypes.bool
  })),
  'moveRangeOnFirstSelection': PropTypes.bool,
  'retainEndDateOnFirstSelection': PropTypes.bool
}
DateRange.defaultProps = {
  'classNames': {},
  'ranges': [],
  'moveRangeOnFirstSelection': false,
  'retainEndDateOnFirstSelection': false,
  'rangeColors': ['#3e11f6', '#8c45f2', '#f9b176'],
  'disabledDates': []
}


/*
 * EXPORTS
 */
export default DateRange
