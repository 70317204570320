/*
 * IMPORTS
 */
import { gql } from '@apollo/client' // Npm: Apollo client for handling graphql request.


/*
 * EXPORTS
 */
export default gql`
mutation CustomerUpdateMutation(
  $customerId: ID!,
  $type: CUSTOMER_TYPE!,
  $phone: String!
) {
  CustomerUpdate(
    customerId: $customerId,
    type: $type,
    phone: $phone
  ) {
    message
    status
  }
}
`
