/*
 * IMPORTS
 */
import React from 'react' // Npm: react.js library.
import Moment from 'moment' // Npm: moment.js library.
import _ from 'underscore' // Npm: underscore.js library.
import { useQuery } from '@apollo/client' // Npm: Apollo client.
import { HiPlusCircle } from 'react-icons/hi2' // Npm: React icons.
import {
  Button,
  Flex,
  Table,
  TableContainer,
  Tbody,
  Td,
  Text,
  Thead,
  Tooltip,
  Tr,
  useDisclosure
} from '@chakra-ui/react' // Npm: Chakra UI components.


/*
 * PACKAGES
 */
import DownloadToExcel from 'components/DownloadToExcel'
import TableSpinner from 'components/TableSpinner'
import Pagination from 'components/Pagination'
import Modal from 'components/Modal'
import CustomerUpsert from 'components/CustomerUpsert'
import CustomerDelete from 'components/CustomerDelete'
import AttachRouteAndRate from 'components/AttachRouteAndRate'
import AttachAccountManager from 'components/AttachAccountManager'
import CustomerDropDownOptions from 'components/CustomerDropDownOptions'
import CreditManagementUpsert from 'components/CreditManagementUpsert'


/*
 * GRAPHS
 */
import CustomerReadQuery from './__query__/index.customer.read.query'


/*
 * STYLES
 */
import {
  buttonStyle,
  cellStyle,
  headerStyle,
  rowStyle
} from './index.style'


/*
 * OBJECTS
 */
const Index = () => {
  // Const assignment.
  const _skipDifference = 10

  // Hook assignment.
  const [skipPage, setSkipPage] = React.useState(0)
  const { 'onOpen': onCustomerCreateOpen, 'isOpen': isCustomerCreateOpen, 'onClose': onCustomerCreateClose } = useDisclosure()
  const { 'onOpen': onCustomerUpdateOpen, 'isOpen': isCustomerUpdateOpen, 'onClose': onCustomerUpdateClose } = useDisclosure()
  const { 'onOpen': onCreditManagementUpsertOpen, 'isOpen': isCreditManagementUpsertOpen, 'onClose': onCreditManagementUpsertClose } = useDisclosure()
  const { 'onOpen': onCustomerDeleteOpen, 'isOpen': isCustomerDeleteOpen, 'onClose': onCustomerDeleteClose } = useDisclosure()
  const { 'onOpen': onCustomerAttachAccountManagerOpen, 'isOpen': isCustomerAttachAccountManagerOpen, 'onClose': onCustomerAttachAccountManagerClose } = useDisclosure()
  const { 'onOpen': onCustomerRatePlanAndRoutePlanAttachFormOpen, 'isOpen': isCustomerRatePlanAndRoutePlanAttachFormOpen, 'onClose': onCustomerRatePlanAndRoutePlanAttachFormClose } = useDisclosure()
  const _QueryCustomerRead = useQuery(CustomerReadQuery, { 'variables': { 'skip': skipPage * _skipDifference, 'take': _skipDifference }, 'fetchPolicy': 'cache-and-network' })

  // Return component.
  return (
    <Flex
      fontSize={['Clamp(13px, 1.5vw, 15px)']}
      direction={{ 'base': 'column' }}
      display='flex'
      flex={1}
      overflowX='scroll'>
      <Flex
        display='flex'
        flex={1}
        flexDirection='column'
        gap='1rem'
        bg='white'
        minH='calc(100vh - 160px)'
        maxHeight='100%'
        borderRadius='20px'
        p='20px'>
        <Flex pb='0px' justify='space-between'>
          <Tooltip label='Create new customer.' fontSize='sm'>
            <Button
              onClick={onCustomerCreateOpen}
              leftIcon={<HiPlusCircle />}
              style={buttonStyle}>
              Create Customer Mapping
            </Button>
          </Tooltip>
          <DownloadToExcel
            cellsData={_QueryCustomerRead?.data?.CustomerRead?.map((item, __index) => ({
              'S.No.': `${(__index + (_skipDifference * (skipPage))) + 1}.`,
              'CreatedAt': Moment(item.createdAt).format('YYYY-MM-DD HH:mm:ss'),
              'UpdatedAt': Moment(item.updatedAt).format('YYYY-MM-DD HH:mm:ss'),
              'CompanyName': item?.Company?.displayName ?? '-',
              'CompanyReferenceNumber': item.Company?.referenceNumber,
              'Type': item?.type ?? '-',
              'RateEmail': item?.rateEmail?.join(', ') ?? '-',
              'Phone': item.phone ?? '-',
              'BillingCurrency': item.Billing?.currency ?? '-',
              'BillingBalance': item.Billing?.balance ?? '-',
              'BillingAlertAmount': item.Billing?.alertAmount ?? '-',
              'RateName': _.flatten(_.pluck(item.CustomerAccount, 'Rate')).map?.(i => i?.displayName)?.join(', ') ?? '-',
              'RouteName': _.flatten(_.pluck(item.CustomerAccount, 'Route')).map?.(i => i?.displayName)?.join(', ') ?? '-',
              'AccountManager': item.AccountManager ? item.AccountManager : 'Not Assigned'
            }))}
            headersData={[
              'S.No.',
              'CreatedAt',
              'UpdatedAt',
              'CompanyName',
              'CompanyReferenceNumber',
              'Type',
              'RateEmail',
              'Phone',
              'BillingCurrency',
              'BillingBalance',
              'BillingAlertAmount',
              'RateName',
              'RouteName',
              'AccountManager'
            ].map(i => ({ 'key': i, 'label': i }))} />
        </Flex>
        <TableContainer
          maxH='calc(100vh - 260px)'
          flex={1}
          display='flex'
          borderRadius='15px'
          outline='1px solid #C5CFE8'
          overflowY='auto'>
          {_QueryCustomerRead.loading && (_.isEmpty(_QueryCustomerRead?.data?.CustomerRead) || (0 === _QueryCustomerRead?.data?.CustomerRead?.length)) ? (
            <Flex w='100%' h='100%' display='flex' alignItems='center' justifyContent='center'>
              <TableSpinner isLoading={true} />
            </Flex>
          ) : 0 === _QueryCustomerRead?.data?.CustomerRead?.length || (0 < _QueryCustomerRead?.data?.CustomerRead?.length && _.every(_.pluck(_QueryCustomerRead?.data?.CustomerRead, 'status'), j => 'READ_SUCCESSFUL' !== j)) ? (
            <Flex w='100%' h='100%' display='flex' alignItems='center' justifyContent='center'>
              <TableSpinner isLoading={false} isEmpty={true} />
            </Flex>
          ) : (
            <Table variant='simple' display='block' size='md'>
              <Thead>
                <Tr style={{ ...headerStyle, position: 'sticky', top: 0, zIndex: 1 }}>

                  <Td style={rowStyle}>S.No.</Td>
                  <Td style={rowStyle}>CreatedAt</Td>
                  <Td style={rowStyle}>UpdatedAt</Td>
                  <Td style={rowStyle}>Type</Td>
                  <Td style={rowStyle}>Phone</Td>
                  <Td style={rowStyle}>Currency</Td>
                  <Td style={rowStyle}>Balance</Td>
                  <Td style={rowStyle}>Alert Amount</Td>
                  <Td style={rowStyle}>Credit Limit</Td>
                  <Td style={rowStyle}>Route Name</Td>
                  <Td style={rowStyle}>Account Manager</Td>
                  <Td style={rowStyle}>Actions</Td>
                </Tr>
              </Thead>
              <Tbody style={cellStyle}>
                {_QueryCustomerRead?.data?.CustomerRead?.map((item, __index) => (
                  <Tr key={String.random(8)}>
                    <Td
                      style={rowStyle}
                      borderRight='1px solid rgba(216, 227, 252, 1)'>
                      {' '}
                      {`${(__index + (_skipDifference * (skipPage))) + 1}.`}
                    </Td>
                    <Td
                      style={rowStyle}
                      borderRight='1px solid rgba(216, 227, 252, 1)'>
                      {Moment(item.createdAt).format('YYYY-MM-DD hh:mm:ss A')}
                    </Td>
                    <Td
                      style={rowStyle}
                      borderRight='1px solid rgba(216, 227, 252, 1)'>
                      {Moment(item.updatedAt).format('YYYY-MM-DD hh:mm:ss A')}
                    </Td>
                    <Td
                      style={rowStyle}
                      borderRight='1px solid rgba(216, 227, 252, 1)'>
                      {item?.type ?? '-'}
                    </Td>
                    <Td
                      style={rowStyle}
                      borderRight='1px solid rgba(216, 227, 252, 1)'>
                      {item.phone ?? '-'}
                    </Td>
                    <Td
                      style={rowStyle}
                      borderRight='1px solid rgba(216, 227, 252, 1)'>
                      {(item.Billing?.currency ?? item.Credit?.currency) ?? '-'}
                    </Td>
                    <Td
                      style={rowStyle}
                      borderRight='1px solid rgba(216, 227, 252, 1)'>
                      {(item.Billing?.balance ?? item.Credit?.balance) ?? '-'}
                    </Td>
                    <Td
                      style={rowStyle}
                      borderRight='1px solid rgba(216, 227, 252, 1)'>
                      {(item.Billing?.alertAmount ?? item.Credit?.alertAmount) ?? '-'}
                    </Td>
                    <Td
                      style={rowStyle}
                      borderRight='1px solid rgba(216, 227, 252, 1)'>
                      {item.Credit?.limit ?? '-'}
                    </Td>
                    <Td
                      style={rowStyle}
                      borderRight='1px solid rgba(216, 227, 252, 1)'>
                      {_.compact(_.flatten(_.pluck(item.CustomerAccount, 'Route'))).map?.(i => i?.displayName)?.join?.(', ') ?? '-'}
                    </Td>
                    <Td
                      style={rowStyle}
                      borderRight='1px solid rgba(216, 227, 252, 1)'>
                      {item.AccountManager ? (
                        <Text
                          borderRadius='10px'
                          padding='5px 15px 5px 15px'
                          color='rgba(117, 81, 255, 1)'
                          bg='rgba(117, 81, 255, .1)'
                          textTransform='capitalize'>{item.AccountManager.displayName ?? '-'}</Text>
                      ) : (
                        <Text
                          borderRadius='10px'
                          padding='5px 15px 5px 15px'
                          color='gray.500'
                          bg='rgba(42, 42, 42, 0.03)'>
                          Not Assigned
                        </Text>
                      )}
                    </Td>
                    <Td
                      style={rowStyle}>
                      {' '}
                      {
                        <CustomerDropDownOptions
                          customerId={item.id}
                          type={item.type}
                          onCreditManagementUpsert={onCreditManagementUpsertOpen}
                          onCustomerUpdate={onCustomerUpdateOpen}
                          onCustomerDelete={onCustomerDeleteOpen}
                          onRateAndRoutePlanAttach={onCustomerRatePlanAndRoutePlanAttachFormOpen}
                          onAttachAccountManager={onCustomerAttachAccountManagerOpen}
                        />
                      }
                    </Td>
                  </Tr>
                ))}
              </Tbody>
            </Table>
          )}
        </TableContainer>
      </Flex>
      <Pagination itemsPerPage={_skipDifference} totalCount={_.pluck(_QueryCustomerRead?.data?.CustomerRead, '_totalCount')?.[0]} onPageChange={setSkipPage} />
      <Modal
        size='md'
        title='Customer Mapping Create'
        isOpen={isCustomerCreateOpen}
        onClose={onCustomerCreateClose}>
        <CustomerUpsert isCreateOnly={true} />
      </Modal>
      <Modal
        size='md'
        title='Customer Mapping Update'
        isOpen={isCustomerUpdateOpen}
        onClose={onCustomerUpdateClose}>
        <CustomerUpsert />
      </Modal>
      <Modal
        size='md'
        title='Attach Route and Rate'
        isOpen={isCustomerRatePlanAndRoutePlanAttachFormOpen}
        onClose={onCustomerRatePlanAndRoutePlanAttachFormClose}>
        <AttachRouteAndRate />
      </Modal>
      <Modal
        size='md'
        title='Customer Mapping Delete'
        isOpen={isCustomerDeleteOpen}
        onClose={onCustomerDeleteClose}>
        <CustomerDelete />
      </Modal>
      <Modal
        size='md'
        title='Attach Account Manager'
        isOpen={isCustomerAttachAccountManagerOpen}
        onClose={onCustomerAttachAccountManagerClose}>
        <AttachAccountManager />
      </Modal>
      <Modal
        size='md'
        title='Credit Management'
        isOpen={isCreditManagementUpsertOpen}
        onClose={onCreditManagementUpsertClose}>
        <CreditManagementUpsert />
      </Modal>
    </Flex>
  )
}


/*
 * EXPORT
 */
export default Index

