/*
 * IMPORTS
 */
import React from 'react' // Npm: react.js library.
import PropTypes from 'prop-types' // Npm: react.js library.
import _ from 'underscore' // Npm: underscore.js library.
import { connect } from 'react-redux' // Npm: React Redux for state management.
import { useMutation, useQuery } from '@apollo/client' // Npm: Apollo client.
import { toast } from 'react-hot-toast' // Npm: React hot toast.
import { IoIosSave } from 'react-icons/io' // Npm: React icons.
import {
  Button,
  Flex,
  FormLabel,
  Spinner
} from '@chakra-ui/react' // Npm: Chakra UI components.


/*
 * PACKAGES
 */
import { MemoizedSearchSelect } from 'components/MemoizedInput'


/*
 * GRAPHS
 */
import AttachRouteMutation from './__mutation__/index.customer.attachRoute.mutation'
import AttachRateMutation from './__mutation__/index.customer.attachRate.mutation'
import RateReadQuery from './__query__/index.rate.read.query'
import RouteReadQuery from './__query__/index.route.read.query'


/*
 * STYLES
 */
import './index.style.css'
import { labelStyle, selectStyle } from './index.style'


/*
 * OBJECTS
 */
const Index = ({ onClose, passOn }) => {
  // Hook assignment.
  const [rate, setRate] = React.useState('')
  const [route, setRoute] = React.useState('')
  const [MutationAttachRoute, MutationAttachRouteResponse] = useMutation(AttachRouteMutation)
  const [MutationAttachRate, MutationAttachRateResponse] = useMutation(AttachRateMutation)
  const _RateReadQuery = useQuery(RateReadQuery, { 'variables': { 'type': passOn?.type, 'take': 1000, 'skip': 0 } })
  const _RouteReadQuery = useQuery(RouteReadQuery, { 'variables': { 'type': passOn?.type, 'take': 1000, 'skip': 0 } })

  // Object assignment.
  const _AttachRateAndRouteToCustomer = async () => {
    // Local variable.
    let _MutationAttachRateToCustomer, _MutationAttachRouteToCustomer

    // Only Execute if route has data.
    if (!_.isEmpty(route)) {
      // Execute mutation.
      _MutationAttachRouteToCustomer = await MutationAttachRoute({
        'variables': {
          'customerId': passOn?.customerId,
          'routeId': route
        }
      })

      // If mutation caught an exception then report failure.
      if (_MutationAttachRouteToCustomer instanceof Error) {
        // Report failure.
        return _MutationAttachRouteToCustomer
      }

      // Style Guide.
      toast(_MutationAttachRouteToCustomer?.data?.CustomerAttachRoute?.message)
    }

    // Only proceed if rate has data.
    if (!_.isEmpty(rate)) {
      // Bind Rate to given customer.
      _MutationAttachRateToCustomer = await MutationAttachRate({
        'variables': {
          'customerId': passOn?.customerId,
          'rateId': rate
        }
      })

      // If mutation caught an exception then report failure.
      if (_MutationAttachRateToCustomer instanceof Error) {
        // Report failure.
        return _MutationAttachRateToCustomer
      }

      // Style Guide.
      toast(_MutationAttachRateToCustomer?.data?.CustomerAttachRate?.message)
    }

    // Report failure if both route and rate are empty.
    if (!_.isEmpty(_MutationAttachRouteToCustomer) && 'UPDATE_SUCCESSFUL' === _MutationAttachRouteToCustomer?.data?.CustomerAttachRoute?.status) return onClose?.()
    if (!_.isEmpty(_MutationAttachRateToCustomer) && 'UPDATE_SUCCESSFUL' === _MutationAttachRateToCustomer?.data?.CustomerAttachRate?.status) return onClose?.()

    // Style Guide.
    toast('Failed to attach the route and rate.')

    // Play toast on completion.
    return void 0
  }

  // Return component.
  return (
    <>
      <Flex className='attachRouteAndRate' direction='column' gap='30px'>
        <Flex flex='1' direction='column'>
          <FormLabel style={labelStyle}>Assign Rate</FormLabel>
          <MemoizedSearchSelect
            style={selectStyle}
            data={rate}
            placeholder='e.g. "Awesome Rate"'
            onChange={e => e.target.value?.includes('(') && e.target.value?.includes(')') ? setRate(e.target.value?.split('(')[1]?.split(')')[0]) : e}
            options={_RateReadQuery?.data?.RateRead?.map(({ id, displayName }) => `${displayName} (${id})`)}
          />
        </Flex>
        <Flex flex='1' direction='column'>
          <FormLabel style={labelStyle}>Assign Route</FormLabel>
          <MemoizedSearchSelect
            style={selectStyle}
            data={route}
            placeholder='e.g. "Amazing Route"'
            onChange={e => e.target.value?.includes('(') && e.target.value?.includes(')') ? setRoute(e.target.value?.split('(')[1]?.split(')')[0]) : e.target.value}
            options={_RouteReadQuery?.data?.RouteRead?.map(({ id, displayName }) => `${displayName} (${id})`)}
          />
        </Flex>
      </Flex>
      <Flex justifyContent='space-between' mt='20px' mb='20px'>
        <Button
          width='100%'
          height='42px'
          mt={5}
          borderRadius='10px'
          bg='rgba(117, 81, 255, 1)'
          color='rgba(255, 255, 255, 1)'
          _hover={{ 'bg': 'rgba(117, 81, 255, 1)' }}
          disabled={_.isEmpty(rate) && _.isEmpty(route)}
          onClick={_AttachRateAndRouteToCustomer}>
                    Save Setting {MutationAttachRouteResponse.loading || MutationAttachRateResponse.loading ? <Spinner color='#c1a9f4' size='sm' ml='10px' /> : <IoIosSave size={16} style={{ 'marginLeft': '10px' }} />}
        </Button>
      </Flex>
    </>
  )
}


/*
 * PROPTYPES
 */
Index.propTypes = {
  'onClose': PropTypes.func,
  'passOn': PropTypes.object
}


/*
 * REDUX
 */
const _MapStateToProps = __state => ({ 'passOn': __state.PassOn })


/*
 * EXPORT
 */
export default connect(_MapStateToProps)(Index)
