/*
 * IMPORTS
 */
import { gql } from '@apollo/client' // Npm: Apollo client for handling graphql request.


/*
 * EXPORTS
 */
export default gql`
query OperatorPrefixReadQuery($operatorPrefixId: ID) {
  OperatorPrefixRead(operatorPrefixId: $operatorPrefixId) {
    id,
    prefix
  }
}
`
