/*
 * IMPORTS
 */
import React from 'react' // Npm: react.js library.
import PropTypes from 'prop-types' // Npm: react.js library.
import { HiOutlineArrowRightOnRectangle } from 'react-icons/hi2' // Npm: React icons.
import { connect } from 'react-redux' // Npm: React Redux for state management.
import { toast } from 'react-hot-toast' // Npm: React hot toast.
import { useMutation } from '@apollo/client' // Npm: Apollo client.


/*
 * PACKAGES
 */
import DeleteCard from 'components/DeleteCard'


/*
 * GRAPHS
 */
import AccountLogoutMutation from './__mutation__/index.account.logout.mutation'


/*
 * OBJECTS
 */
const Index = ({ passOn, onClose }) => {
  // Hook assignment.
  const [MutationAccountLogout, MutationAccountLogoutResponse] = useMutation(AccountLogoutMutation)

  // Object assignment.
  const _Logout = () => MutationAccountLogout({ 'variables': { 'accountId': passOn?.accountId } }).then(k => {
    // Style Guide.
    toast(k.data.AccountLogout.message)

    // Close modal.
    onClose?.()
  }).catch(() =>
    // Style Guide.
    toast('Something went wrong please try after some time.'))

  // Return Component.
  return (
    <DeleteCard icon={<HiOutlineArrowRightOnRectangle size={31} />} subHeading='That you want to logout this account.' onClose={onClose} onConfirm={_Logout} isLoading={MutationAccountLogoutResponse.loading} />
  )
}


/*
 * PROPTYPES
 */
Index.propTypes = {
  'passOn': PropTypes.object,
  'onClose': PropTypes.func
}


/*
 * REDUX
 */
const _MapStateToProps = __state => ({ 'passOn': __state.PassOn })


/*
 * EXPORT
 */
export default connect(_MapStateToProps)(Index)
