/*
 * IMPORTS
 */
import { gql } from '@apollo/client' // Npm: Apollo client for handling graphql request.


/*
 * EXPORTS
 */
export default gql`
query CustomerReadQuery($take: PositiveInt, $skip: Int) {
  CustomerRead(take: $take, skip: $skip) {
    id,
    createdAt,
    displayName,
    message,
    phone,
    status,
    country,
    type,
    _totalCount
    AccountManager {
      displayName
    }
    CustomerAccount {
      Rate {
          displayName
      }
      Route {
          displayName
      }
    }
    Credit {
      limit,
      currency,
      balance,
      alertAmount
    }
  }
}
`
