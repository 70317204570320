/*
 * IMPORT
 */
import React from 'react' // Npm: react.js library.
import PropTypes from 'prop-types' // Npm: Prop types for React.
import { IoMenuOutline } from 'react-icons/io5' // Npm: React icons.
import { Scrollbars } from 'react-custom-scrollbars-2' // Npm: React custom scrollbars.
import {
  Box,
  Drawer,
  DrawerBody,
  DrawerCloseButton,
  DrawerContent,
  DrawerOverlay,
  Flex,
  Icon,
  useColorModeValue,
  useDisclosure
} from '@chakra-ui/react' // Npm: Chakra UI components.


/*
 * PACKAGES
 */
import {
  renderThumb,
  renderTrack,
  renderView
} from './ScrollBar'


/*
 * SIBLINGS
 */
import Content from './Content'


/*
 * OBJECTS
 */
const Index = ({ routes }) => {
  // Hook assignment.
  const _sidebarBgRef = useColorModeValue('white', 'navy.800')

  // Return component.
  return (
    <Box
      w='20%'
      bg='#F5F8FE'
      position='fixed'
      borderRight='12px solid #3B2CF5'
      minH='100%'>
      <Box
        bg={_sidebarBgRef}
        h='calc(100vh - 0px)'
        overflowX='hidden'>
        <Scrollbars
          autoHide
          overflowy='hidden'
          renderTrackVertical={renderTrack}
          renderThumbVertical={renderThumb}
          renderView={renderView}>
          <Content routes={routes} />
        </Scrollbars>
      </Box>
    </Box>
  )
}
const SidebarResponsive = ({ routes }) => {
  // Hook assignment.
  const _sidebarBackgroundColorRef = useColorModeValue('white', 'navy.800')
  const _menuColorRef = useColorModeValue('gray.400', 'white')
  const _buttonRef = React.useRef()
  const { isOpen, onOpen, onClose } = useDisclosure()

  // Return component.
  return (
    <Flex display={{ 'sm': 'flex', 'xl': 'none' }} alignItems='center'>
      <Flex ref={_buttonRef} w='max-content' h='max-content' onClick={onOpen}>
        <Icon
          as={IoMenuOutline}
          color={_menuColorRef}
          my='auto'
          w='20px'
          h='20px'
          me='10px'
          m='inherit'
          _hover={{ cursor: 'pointer' }}
        />
      </Flex>
      <Drawer
        isOpen={isOpen}
        onClose={onClose}
        placement={'rtl' === document.documentElement.dir ? 'right' : 'left'}
        finalFocusRef={_buttonRef}>
        <DrawerOverlay />
        <DrawerContent
          w='285px'
          maxW='285px'
          ms={{ 'sm': '16px' }}
          my={{ 'sm': '16px' }}
          borderRadius='16px'
          bg={_sidebarBackgroundColorRef}>
          <DrawerCloseButton
            zIndex='3'
            onClose={onClose}
            _focus={{ 'boxShadow': 'none' }}
            _hover={{ 'boxShadow': 'none' }}
          />
          <DrawerBody maxW='285px' px='0rem' pb='0'>
            <Scrollbars
              autoHide
              renderTrackVertical={renderTrack}
              renderThumbVertical={renderThumb}
              renderView={renderView}>
              <Content routes={routes} />
            </Scrollbars>
          </DrawerBody>
        </DrawerContent>
      </Drawer>
    </Flex>
  )
}


/*
 * PROPTYPES
 */
Index.propTypes = {
  'logoText': PropTypes.string,
  'routes': PropTypes.arrayOf(PropTypes.object),
  'variant': PropTypes.string
}
SidebarResponsive.propTypes = {
  'routes': PropTypes.arrayOf(PropTypes.object)
}


/*
 * EXPORTS
 */
export default Index
export { SidebarResponsive }
