/*
 * IMPORTS
 */
import React from 'react' // Npm: react.js library.
import PropTypes from 'prop-types' // Npm: react.js library.
import JoiBrowser from 'joi-browser' // Npm: Joi for frontend validation.
import _ from 'underscore' // Npm: Underscore.js library.
import { toast } from 'react-hot-toast' // Npm: React hot toast.
import { connect } from 'react-redux' // Npm: React Redux for state management.
import { useLazyQuery, useMutation } from '@apollo/client' // Npm: Apollo client.
import {
  Flex,
  FormControl
} from '@chakra-ui/react' // Npm: Chakra UI components.



/*
 * PACKAGES
 */
import ModalFooter from 'components/ModalFooter'
import { MemoizedInput, MemoizedSelect } from 'components/MemoizedInput'


/*
 * GRAPHS
 */
import CreditReadQuery from './__query__/index.credit.read.query'
import CreditUpsertMutation from './__mutation__/index.credit.upsert.mutation'


/*
 * STYLES
 */
import './index.style.css'


/*
 * OBJECTS
 */
const Index = ({
  isOpen,
  onClose,
  passOn
}) => {
  // Hook assignment.
  const [MutationCreditUpsert, MutationCreditUpsertResponse] = useMutation(CreditUpsertMutation)
  const [QueryCreditRead] = useLazyQuery(CreditReadQuery)
  const [error, setError] = React.useState('')
  const [forceReRender, setForceReRender] = React.useState('')
  const _formDataRef = React.useRef({})

  // Object assignment.
  const _SubmitForm = async e => {
    // Prevent default behavior.
    e?.preventDefault()

    // Const assignment.
    const _JoiSchema = JoiBrowser.object({
      'limit': JoiBrowser.number().required(),
      'currency': JoiBrowser.string().required(),
      'superKey': JoiBrowser.string().required()
    }).options({ 'allowUnknown': true })

    // Remove all keys from _formDataRef.current which are undefined.
    _formDataRef.current = _.pick(_formDataRef.current, _.identity)

    // Validate form data.
    const _JoiSchemaValidate = _JoiSchema.validate(_formDataRef.current)

    console.log(_JoiSchemaValidate)

    // If error exists then report failure.
    if (_JoiSchemaValidate.error) return setError(_JoiSchemaValidate.error?.message)

    // Reset error.
    setError('')

    // Create CustomerAccount for given Customer.
    const _MutationCreditUpsert = await MutationCreditUpsert({
      'variables': {
        'customerId': passOn?.customerId,
        ..._formDataRef?.current
      }
    })

    // If creating or updating customer account caught an exception then report failure.
    if (_MutationCreditUpsert instanceof Error) return _MutationCreditUpsert

    // Style Guide.
    toast(_MutationCreditUpsert?.data?.CreditUpsert?.message)

    // On Successful response from the mutation.
    if ('UPSERT_SUCCESSFUL' === _MutationCreditUpsert?.data?.CreditUpsert?.status) onClose?.()

    // Return void 0.
    return void 0
  }

  // Event handler.
  React.useEffect(() => {
    // _Async handler.
    const _Async = async () => {
      // Const assignment.
      const _QueryCreditReadQuery = await QueryCreditRead({ 'variables': { 'customerId': passOn?.customerId } })

      console.log(_QueryCreditReadQuery, passOn)
      // If query caught an exception then report failure.
      if (_QueryCreditReadQuery instanceof Error) return _QueryCreditReadQuery

      // Const assignment.
      const _data = _.first(_QueryCreditReadQuery?.data?.CreditRead ?? [])

      /*
       * If smpp details fetch complete then
       * update its value.
       */
      if (!_.isEmpty(_data)) {
        // Update form data.
        _formDataRef.current = {
          'limit': _data.limit,
          'currency': _data.currency,
          'superKey': _data.superKey
        }

        // Update state.
        return setForceReRender(String.random(8))
      }

      // Report failure.
      return void 0
    }; _Async().catch(i => i)
  }, [passOn, isOpen])

  console.log(error)

  // Return component.
  return (
    <form onSubmit={_SubmitForm} key={forceReRender}>
      <Flex>
        <Flex gap='20px' flexDir='column' w='100%'>
          <Flex gap='20px'>
            <FormControl>
              <MemoizedInput
                isRequired={true}
                name='limit'
                label='Credit Limit'
                placeholder='e.g. "1000"'
                onChange={({ target }) => {
                  // Over spreading.
                  const { name, value } = target

                  // Update form data.
                  _formDataRef.current = {
                    ..._formDataRef?.current,
                    [name]: parseFloat(value)
                  }
                }}
                isInvalid={error?.includes('limit')}
                error={error}
                data={_formDataRef?.current?.limit}
              />
            </FormControl>
            <FormControl>
              <MemoizedSelect
                name='currency'
                label='Currency'
                placeholder='e.g. "USD"'
                onChange={({ target }) => {
                  // Over spreading.
                  const { name, value } = target

                  // Update form data.
                  _formDataRef.current = {
                    ..._formDataRef?.current,
                    [name]: value
                  }
                }}
                isRequired={true}
                error={error}
                options={Object.React.App.enums.CURRENCY_LIST.enums?.map(i => i.key)}
                isInvalid={error?.includes('currency')}
                data={_formDataRef?.current?.currency}
              />
            </FormControl>
          </Flex>
          <Flex gap='20px'>
            <FormControl>
              <MemoizedInput
                isRequired={true}
                name='superKey'
                label='Super Key'
                placeholder='e.g. "Jamai Raja"'
                onChange={({ target }) => {
                  // Over spreading.
                  const { name, value } = target

                  // Update form data.
                  _formDataRef.current = {
                    ..._formDataRef?.current,
                    [name]: value
                  }
                }}
                isInvalid={error?.includes('superKey')}
                error={error}
                data={_formDataRef?.current?.superKey}
              />
            </FormControl>
          </Flex>
        </Flex>
      </Flex>
      <ModalFooter isLoading={MutationCreditUpsertResponse.loading} onSubmit={_SubmitForm} />
    </form>
  )
}


/*
 * PROPTYPES
 */
Index.propTypes = {
  'passOn': PropTypes.object,
  'onClose': PropTypes.func,
  'isOpen': PropTypes.bool
}
Index.defaultProps = {}


/*
 * REDUX
 */
const _MapStateToProps = __state => ({ 'passOn': __state.PassOn })


/*
 * EXPORT
 */
export default connect(_MapStateToProps)(Index)
