/*
 * EXPORTS
 */
export const labelStyle = {
  fontFamily: 'DM Sans',
  fontWeight: '700',
  fontSize: '15px',
  lineHeight: '24px',
  color: 'rgba(43, 54, 116, 1)'
}
export const selectStyle = {
  fontFamily: 'DM Sans',
  fontWeight: '500',
  fontSize: '16px',
  lineHeight: '24px',
  height: '42px',
  borderRadius: '10px',
  color: 'rgba(43, 54, 116, 0.4)',
  border: '1px solid rgba(216, 227, 252, 1)'
}
