/*
 * IMPORTS
 */
import { gql } from '@apollo/client' // Npm: Apollo client for handling graphql request.


/*
 * EXPORTS
 */
export default gql`
mutation CustomerCreateMutation(
    $type: CUSTOMER_TYPE!,
    $phone: String!
) {
  CustomerCreate(
    type: $type,
    phone: $phone
  ) {
    message
    status
  }
}
`
