/*
 * EXPORTS
 */
export const labelStyle = {
  'fontFamily': 'DM Sans',
  'fontWeight': '600',
  'fontSize': '16px',
  'lineHeight': '24px',
  'color': 'rgba(43, 54, 116, 1)'
}
export const inputStyle = {
  'fontFamily': 'DM Sans',
  'fontWeight': '400',
  'fontSize': '15px',
  'lineHeight': '24px',
  'height': '45px',
  'borderRadius': '10px',
  'borderWidth': 2,
  'backgroundColor': 'none',
  'borderColor': 'rgba(163, 174, 208, 0.34)'
}
export const errorStyle = {
  'color': '#2B3674',
  'fontFamily': 'DM Sans',
  'fontWeight': '400',
  'fontSize': '14px',
  'lineHeight': '24px',
  'height': '38px',
  'borderRadius': '10px',
  'border': '1px solid',
  'borderColor': 'red'
}
