/*
 * IMPORTS
 */
import React, { PureComponent } from 'react' // Npm import: React.js library
import PropTypes from 'prop-types' // Npm import: Prop types validator.
import {
  eachDayOfInterval,
  endOfWeek,
  format,
  isSameDay,
  isWeekend,
  isWithinInterval,
  startOfWeek
} from 'date-fns' // Npm date-fns library.


/*
 * UTILS
 */
import DayCell from '../../../../util/DayCell'
import { getMonthDisplayRange } from '../../../../util'


/*
 * PACKAGES
 */
function renderWeekdays(styles, dateOptions, weekdayDisplayFormat) {
  // Const component.
  const now = new Date()

  // Return component.
  return (
    <div className={styles.weekDays}>
      {eachDayOfInterval({
        start: startOfWeek(now, dateOptions),
        end: endOfWeek(now, dateOptions)
      }).map((day, i) => (
        <span className={styles.weekDay} key={i}>
          {format(day, weekdayDisplayFormat, dateOptions)}
        </span>
      ))}
    </div>
  )
}


/*
 * CLASS
 */
class Month extends PureComponent {
  /*
   * Render
   * @return {object}
   * @description Render component.
   * @see https://reactjs.org/docs/react-component.html#render
   */
  render() {
    // Local variable.
    let ranges

    // Const assignment.
    const now = new Date()
    const { displayMode, focusedRange, drag, styles } = this.props
    const monthDisplay = getMonthDisplayRange(this.props.month, this.props.dateOptions, this.props.fixedHeight)

    // Variable assignment.
    ranges = this.props.ranges

    // If date range.
    if ('dateRange' === displayMode && drag.status) {
      // Destructuring assignment.
      const { startDate, endDate } = drag.range

      // Variable assignment.
      ranges = ranges.map((range, i) => {
        // If index is not focused range.
        if (i !== focusedRange[0]) return range

        // Return value.
        return {
          ...range,
          startDate,
          endDate
        }
      })
    }

    // Const assignment.
    const showPreview = this.props.showPreview && !drag.disablePreview

    // Return component.
    return (
      <div className={styles.month} style={this.props.style}>
        {this.props.showMonthName ? (
          <div className={styles.monthName}>
            {format(this.props.month, this.props.monthDisplayFormat, this.props.dateOptions)}
          </div>
        ) : null}
        {this.props.showWeekDays && renderWeekdays(styles, this.props.dateOptions, this.props.weekdayDisplayFormat)}
        <div className={styles.days} onMouseLeave={this.props.onMouseLeave}>
          {eachDayOfInterval({ start: monthDisplay.start, end: monthDisplay.end }).map(
            (day, index) => {
              // Const assignment.
              const isStartOfMonth = isSameDay(day, monthDisplay.startDateOfMonth)
              const isEndOfMonth = isSameDay(day, monthDisplay.endDateOfMonth)

              // Return component.
              return (
                <DayCell
                  {...this.props}
                  ranges={ranges}
                  day={day}
                  preview={showPreview ? this.props.preview : null}
                  isWeekend={isWeekend(day, this.props.dateOptions)}
                  isToday={isSameDay(day, now)}
                  isStartOfWeek={isSameDay(day, startOfWeek(day, this.props.dateOptions))}
                  isEndOfWeek={isSameDay(day, endOfWeek(day, this.props.dateOptions))}
                  isStartOfMonth={isStartOfMonth}
                  isEndOfMonth={isEndOfMonth}
                  key={index}
                  isPassive={
                    !isWithinInterval(day, {
                      start: monthDisplay.startDateOfMonth,
                      end: monthDisplay.endDateOfMonth
                    })
                  }
                  styles={styles}
                  onMouseDown={this.props.onDragSelectionStart}
                  onMouseUp={this.props.onDragSelectionEnd}
                  onMouseEnter={this.props.onDragSelectionMove}
                  dragRange={drag.range}
                  drag={drag.status}
                />
              )
            }
          )}
        </div>
      </div>
    )
  }
}


/*
 * PROPTYPES
 */
Month.defaultProps = {}
Month.propTypes = {
  'style': PropTypes.object,
  'styles': PropTypes.object,
  'month': PropTypes.object,
  'drag': PropTypes.object,
  'dateOptions': PropTypes.object,
  'disabledDates': PropTypes.array,
  'disabledDay': PropTypes.func,
  'preview': PropTypes.shape({
    'startDate': PropTypes.object,
    'endDate': PropTypes.object
  }),
  'showPreview': PropTypes.bool,
  'displayMode': PropTypes.oneOf(['dateRange', 'date']),
  'minDate': PropTypes.object,
  'maxDate': PropTypes.object,
  'ranges': PropTypes.arrayOf(PropTypes.shape({
    'startDate': PropTypes.object,
    'endDate': PropTypes.object,
    'color': PropTypes.string,
    'key': PropTypes.string,
    'autoFocus': PropTypes.bool,
    'disabled': PropTypes.bool,
    'showDateDisplay': PropTypes.bool
  })),
  'focusedRange': PropTypes.arrayOf(PropTypes.number),
  'onDragSelectionStart': PropTypes.func,
  'onDragSelectionEnd': PropTypes.func,
  'onDragSelectionMove': PropTypes.func,
  'onMouseLeave': PropTypes.func,
  'monthDisplayFormat': PropTypes.string,
  'weekdayDisplayFormat': PropTypes.string,
  'dayDisplayFormat': PropTypes.string,
  'showWeekDays': PropTypes.bool,
  'showMonthName': PropTypes.bool,
  'fixedHeight': PropTypes.bool
}


/*
 * EXPORTS
 */
export default Month
