/*
 * IMPORTS
 */
import { gql } from '@apollo/client' // Npm: Apollo client for handling graphql request.


/*
 * EXPORTS
 */
export default gql`
mutation RoutePlanCreateMutation($input: [RoutePlanCreateInput!]!) {
  RoutePlanCreate(input: $input) {
    id,
    message
    status
  }
}
`
