/*
 * IMPORTS
 */
import { gql } from '@apollo/client' // Npm: Apollo client for handling graphql request.


/*
 * EXPORTS
 */
export default gql`
mutation CustomerAccountAttachSmppMutation($customerAccountId: ID!, $smppId: ID!) {
  CustomerAccountAttachSmpp(
    customerAccountId: $customerAccountId,
    smppId: $smppId
  ) {
    message
    status
  }
}
`
