/*
 * IMPORTS
 */
import { gql } from '@apollo/client' // Npm: Apollo client for handling graphql request.


/*
 * EXPORTS
 */
export default gql`
mutation BlockDestinationAddressUpsertMutation($customerId: ID!, $phone: String!, $reason: String!) {
  BlockDestinationAddressUpsert(customerId: $customerId, phone: $phone, reason: $reason) {
    id,
    message
    status
  }
}
`
