/*
 * IMPORTS
 */
import { gql } from '@apollo/client' // Npm: Apollo client for handling graphql request.


/*
 * EXPORTS
 */
export default gql`
  query RoutePlanReadQuery($routeId: ID!, $take: PositiveInt, $skip: Int) {
    RoutePlanRead(routeId: $routeId, take: $take, skip: $skip) {
      id
      createdAt
      updatedAt
      percentage,
      isPaused,
      smsType,
      message
      status,
      _totalCount,
      Mcc {
        createdAt,
        updatedAt,
        mcc,
        countryName
      },
      Mnc {
        createdAt,
        updatedAt,
        mnc,
        network
      }
      Route {
        id
        displayName
      }
    }
  }
`
