/*
 * IMPORTS
 */
import { gql } from '@apollo/client' // Npm: Apollo client for handling graphql request.


/*
 * EXPORTS
 */
export default gql`
    query MccReadQuery($take: PositiveInt, $skip: Int) {
    MccRead(take: $take, skip: $skip) {
        id,
        createdAt,
        updatedAt,
        dialingCode,
        countryName,
        mcc,
        status,
        message,
        _totalCount
    }
  }
`
