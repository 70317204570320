/*
 * IMPORTS
 */
import { gql } from '@apollo/client' // Npm: Apollo client for handling graphql request.


/*
 * EXPORTS
 */
export default gql`
mutation OperatorPrefixDeleteMutation($operatorPrefixId: ID!) {
  OperatorPrefixDelete(operatorPrefixId: $operatorPrefixId) {
    id
    message
    status
  }
}
`
