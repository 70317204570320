/*
 * IMPORTS
 */
import { gql } from '@apollo/client' // Npm: Apollo client for handling graphql request.


/*
 * EXPORTS
 */
export default gql`
mutation CreditUpsertMutation($customerId: ID!, $limit: Float!, $currency: String!, $superKey: String!) {
  CreditUpsert(customerId: $customerId, limit: $limit, currency: $currency, superKey: $superKey) {
    id,
    message
    status
  }
}
`
