/*
 * IMPORTS
 */
import React from 'react' // Npm: react.js library.
import PropTypes from 'prop-types' // Npm: react.js library.
import { connect } from 'react-redux' // Npm: React Redux for state management.
import { HiOutlineArrowRightOnRectangle, HiPencil, HiWrenchScrewdriver } from 'react-icons/hi2' // Npm: React icons.
import {
  Button,
  Flex,
  Menu,
  MenuButton,
  MenuItem,
  MenuList
} from '@chakra-ui/react' // Npm: Chakra UI components.


/*
 * STYLES
 */
import { menuStyle } from './index.style'


/*
 * OBJECTS
 */
const Index = ({
  accountId,
  onAccountUpdate,
  onAccountLogout,
  PassOnUpdate
}) => (
  <Flex>
    <Menu placement='left'>
      <MenuButton
        as={Button}
        w='100%'
        borderRadius='8px'
        color='brand.500'
        _hover={{ 'bg': 'brand.500', 'color': 'white' }}
        _active={{ 'bg': 'brand.500', 'color': 'white' }}
        _focused={{ 'bg': 'brand.500', 'color': 'white' }}
        bg='transparent'>
        <HiWrenchScrewdriver size={18} />
      </MenuButton>
      <MenuList
        style={menuStyle}
        maxHeight='400px'
        overflow='auto'>
        <MenuItem
          gap='10px'
          display='flex'
          pl='20px'
          pr='20px'
          color='gray.500'
          onClick={() => {
            // Push customer id to url.
            PassOnUpdate({ accountId })

            // Execute handler.
            onAccountUpdate()
          }}
          minH='40px'>
          <HiPencil size={18} />
          <span>Edit Account</span>
        </MenuItem>
        <MenuItem
          gap='10px'
          display='flex'
          pl='20px'
          pr='20px'
          color='gray.500'
          onClick={() => {
            // Push customer id to url.
            PassOnUpdate({ accountId })

            // Execute handler.
            onAccountLogout()
          }}
          minH='40px'>
          <HiOutlineArrowRightOnRectangle />
          <span>Logout Account</span>
        </MenuItem>
      </MenuList>
    </Menu>
  </Flex>
)


/*
 * PROPTYPES
 */
Index.propTypes = {
  'accountId': PropTypes.string,
  'onAccountUpdate': PropTypes.func,
  'onAccountLogout': PropTypes.func,
  'PassOnUpdate': PropTypes.func
}


/*
 * REDUX
 */
const _MapDispatchToProps = __dispatch => ({
  'PassOnUpdate': u => __dispatch({ 'type': 'PASS_ON_UPDATE', 'PassOn': u })
})


/*
 * EXPORT
 */
export default connect(void 0, _MapDispatchToProps)(Index)
