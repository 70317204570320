/*
 * IMPORTS
 */
import React from 'react' // Npm: react.js library.
import PropTypes from 'prop-types' // Npm: react.js library.
import { connect } from 'react-redux' // Npm: React Redux for state management.
import { HiAdjustmentsHorizontal, HiTrash, HiWrench, HiWrenchScrewdriver } from 'react-icons/hi2' // Npm: React icons.
import {
  Button,
  Flex,
  Menu,
  MenuButton,
  MenuItem,
  MenuList
} from '@chakra-ui/react' // Npm: Chakra UI components.


/*
 * STYLES
 */
import { menuStyle } from './index.style'


/*
 * OBJECTS
 */
const Index = ({
  contentTranslationId,
  smppId,
  vendorAccountId,
  onVendorAccountUpdate,
  onVendorAccountDelete,
  onAddSenderID,
  onContentTranslation,
  PassOnUpdate
}) => (
  <Flex>
    <Menu placement='left'>
      <MenuButton
        as={Button}
        w='100%'
        borderRadius='8px'
        color='brand.500'
        _hover={{ 'bg': 'brand.500', 'color': 'white' }}
        _active={{ 'bg': 'brand.500', 'color': 'white' }}
        _focused={{ 'bg': 'brand.500', 'color': 'white' }}
        bg='transparent'>
        <HiWrenchScrewdriver size={18} />
      </MenuButton>
      <MenuList
        style={menuStyle}
        maxHeight='400px'
        overflow='auto'>
        <MenuItem
          gap='10px'
          display='flex'
          pl='20px'
          pr='20px'
          color='gray.500'
          onClick={() => {
            // Push vendor id to url.
            PassOnUpdate({ vendorAccountId })

            // Execute handler.
            onVendorAccountUpdate()
          }}
          minH='40px'>
          <HiWrenchScrewdriver size={18} />
          <span>Edit Connect Mapping</span>
        </MenuItem>
        <MenuItem
          gap='10px'
          display='flex'
          pl='20px'
          pr='20px'
          color='gray.500'
          onClick={() => {
            // Push vendor id to url.
            PassOnUpdate({ vendorAccountId, contentTranslationId, smppId })

            // Execute handler.
            onContentTranslation()
          }}
          minH='40px'>
          <HiAdjustmentsHorizontal size={18} />
          <span>Content Translation</span>
        </MenuItem>
        <MenuItem
          gap='10px'
          display='flex'
          pl='20px'
          pr='20px'
          color='gray.500'
          onClick={() => {
            // Push vendor id to url.
            PassOnUpdate({ vendorAccountId })

            // Execute handler.
            onAddSenderID()
          }}
          minH='40px'>
          <HiWrench size={18} />
          <span>Add Sender ID</span>
        </MenuItem>
        <MenuItem
          gap='10px'
          display='flex'
          pl='20px'
          pr='20px'
          color='gray.500'
          onClick={() => {
            // Push vendor id to url.
            PassOnUpdate({ vendorAccountId })

            // Execute handler.
            onVendorAccountDelete()
          }}
          minH='40px'>
          <HiTrash />
          <span>Delete Connect Mapping</span>
        </MenuItem>
      </MenuList>
    </Menu>
  </Flex>
)



/*
 * PROPTYPES
 */
Index.propTypes = {
  'vendorAccountId': PropTypes.string,
  'onVendorAccountUpdate': PropTypes.func,
  'onVendorAccountDelete': PropTypes.func,
  'onAddSenderID': PropTypes.func,
  'PassOnUpdate': PropTypes.func,
  'onContentTranslation': PropTypes.func,
  'contentTranslationId': PropTypes.string,
  'smppId': PropTypes.string
}


/*
 * REDUX
 */
const _MapDispatchToProps = __dispatch => ({
  'PassOnUpdate': u => __dispatch({ 'type': 'PASS_ON_UPDATE', 'PassOn': u })
})


/*
 * EXPORT
 */
export default connect(void 0, _MapDispatchToProps)(Index)
