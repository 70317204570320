/*
 * IMPORTS
 */
import React from 'react' // Npm: react.js library.
import PropTypes from 'prop-types' // Npm: react.js library.
import { Link as ReactRouterLink } from 'react-router-dom' // Npm: React router dom.
import { connect } from 'react-redux' // Npm: React Redux for state management.
import { MdInfoOutline, MdNotificationsNone } from 'react-icons/md' // Npm: React icons.
import {
  Avatar,
  Link as ChakraLink,
  Flex,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Text,
  useColorModeValue
} from '@chakra-ui/react' // Npm: Chakra UI components.


/*
 * PACKAGES
 */
import { SidebarResponsive } from 'components/SideBar'


/*
 * OBJECTS
 */
function Index({ ClearEverything, account }) {
  // Hook assignment.
  const _menuBg = useColorModeValue('white', 'navy.800')
  const _textColor = useColorModeValue('secondaryGray.900', 'white')
  const _textColorBrand = useColorModeValue('brand.700', 'brand.400')
  const _borderColor = useColorModeValue('#E6ECFA', 'rgba(135, 140, 189, 0.3)')
  const _shadow = useColorModeValue('14px 17px 40px 4px rgba(112, 144, 176, 0.18)', '14px 17px 40px 4px rgba(112, 144, 176, 0.06)')

  // Return component.
  return (
    <Flex
      w='100%'
      justifyContent='space-between'
      bg='white'
      p='10px'
      borderRadius='20px'
      boxShadow={_shadow}>
      <SidebarResponsive routes={[]} />
      <Flex>
        <Menu>
          <MenuButton display='flex' alignItems='center' p='0px' m='10px' color='red.400'>
            <MdNotificationsNone size='19px' />
          </MenuButton>
          <MenuList
            boxShadow={_shadow}
            p='20px'
            borderRadius='20px'
            bg={_menuBg}
            border='none'
            mt='22px'
            me={{ 'base': '30px', 'md': 'unset' }}
            minW={{ 'base': 'unset', 'md': '400px', 'xl': '450px' }}
            maxW={{ 'base': '360px', 'md': 'unset' }}>
            <Flex jusitfy='space-between' w='100%' mb='20px'>
              <Text fontSize='md' fontWeight='600' color={_textColor}>
                Notifications
              </Text>
              <Text
                fontSize='sm'
                fontWeight='500'
                color={_textColorBrand}
                ms='auto'
                cursor='pointer'>
                Mark all read
              </Text>
            </Flex>
          </MenuList>
        </Menu>
        <Menu>
          <MenuButton
            p='0px'
            mx='10px'
            marginLeft='5px'
            color='brand.400'>
            <MdInfoOutline size='18px' />
          </MenuButton>
          <MenuList
            boxShadow={_shadow}
            p='20px'
            me={{ 'base': '30px', 'md': 'unset' }}
            borderRadius='20px'
            bg={_menuBg}
            border='none'
            mt='22px'
            minW={{ 'base': 'unset' }}
            maxW={{ 'base': '360px', 'md': 'unset' }}>
            <Flex flexDirection='column'></Flex>
          </MenuList>
        </Menu>
        <Menu>
          <MenuButton
            p='0px'
            marginLeft='5px'>
            <Avatar
              _hover={{ 'cursor': 'pointer' }}
              color={Object.React.CreateColorBasedOnAlphabet(account.displayName).bgColor}
              name={account.displayName}
              borderColor={Object.React.CreateColorBasedOnAlphabet(account.displayName).bgColor}
              size='sm'
              bg='none'
              borderWidth='2px'
              borderStyle='solid'
              w='40px'
              h='40px'
            />
          </MenuButton>
          <MenuList
            boxShadow={_shadow}
            p='0px'
            mt='10px'
            borderRadius='20px'
            bg={_menuBg}
            border='none'>
            <Flex w='100%' mb='0px'>
              <Text
                ps='20px'
                pt='16px'
                pb='10px'
                w='100%'
                borderBottom='1px solid'
                borderColor={_borderColor}
                fontSize='sm'
                fontWeight='700'
                color={_textColor}
              >  👋&nbsp; Hey,{account.displayName}</Text>
            </Flex>
            <Flex flexDirection='column' p='10px'>
              <ChakraLink as={ReactRouterLink} to='/Profile'>
                <MenuItem
                  _hover={{ 'bg': 'none' }}
                  _focus={{ 'bg': 'none' }}
                  borderRadius='8px'
                  px='14px'>
                  <Text fontSize='sm'>Profile Settings</Text>
                </MenuItem>
              </ChakraLink>
              <MenuItem
                _hover={{ 'bg': 'none' }}
                _focus={{ 'bg': 'none' }}
                color='red.400'
                borderRadius='8px'
                px='14px'
                onClick={() => ClearEverything()}>
                <Text fontSize='sm'>Log out</Text>
              </MenuItem>
            </Flex>
          </MenuList>
        </Menu>
      </Flex>
    </Flex>
  )
}


/*
 * PROPTYPES
 */
Index.propTypes = {
  'account': PropTypes.object,
  'ClearEverything': PropTypes.func
}


/*
 * REDUX
 */
const _MapStateToProps = __state => ({ 'account': __state.Account })
const _MapDispatchToProps = __dispatch => ({
  'ClearEverything': () => {
    // Clear all states.
    __dispatch({ 'type': 'ACCOUNT_CLEAR' })
    __dispatch({ 'type': 'PASS_ON_CLEAR' })
  }
})


/*
 * EXPORT
 */
export default connect(_MapStateToProps, _MapDispatchToProps)(Index)



