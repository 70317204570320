/*
 * IMPORTS
 */
import React from 'react' // Npm: react.js library.
import PropTypes from 'prop-types' // Npm: react.js library.
import _ from 'underscore' // Npm: underscore.js library.
import { connect } from 'react-redux' // Npm: React Redux for state management.
import { useMutation, useQuery } from '@apollo/client' // Npm: Apollo client.
import { toast } from 'react-hot-toast' // Npm: React hot toast.
import { IoIosSave } from 'react-icons/io' // Npm: React icons.
import {
  Button,
  Flex,
  FormLabel,
  Spinner
} from '@chakra-ui/react' // Npm: Chakra UI components.


/*
 * PACKAGES
 */
import { MemoizedSearchSelect } from 'components/MemoizedInput'


/*
 * GRAPHS
 */
import AttachAccountManagerMutation from './__mutation__/index.customer.attachAccountManager.mutation'
import AccountReadQuery from './__query__/index.account.read.query'


/*
 * STYLES
 */
import './index.style.css'
import { labelStyle, selectStyle } from './index.style'


/*
 * OBJECTS
 */
const Index = ({ onClose, passOn }) => {
  // Hook assignment.
  const [accountManager, setAccountManager] = React.useState('')
  const [MutationAttachAccountManager, MutationAttachAccountManagerResponse] = useMutation(AttachAccountManagerMutation)
  const _QueryAccountManagerRead = useQuery(AccountReadQuery)

  // Object assignment.
  const _AttachAccountManagerAndAccountManagerToCustomer = async () => {
    // Local variable.
    let _MutationAttachAccountManagerToCustomer

    // Only proceed if accountManager has data.
    if (!_.isEmpty(accountManager)) {
      // Bind AccountManager to given customer.
      _MutationAttachAccountManagerToCustomer = await MutationAttachAccountManager({
        'variables': {
          'customerId': passOn?.customerId,
          'accountManagerId': accountManager
        }
      })

      // If mutation caught an exception then report failure.
      if (_MutationAttachAccountManagerToCustomer instanceof Error) {
        // Report failure.
        return _MutationAttachAccountManagerToCustomer
      }
    }

    // Style Guide.
    toast(_MutationAttachAccountManagerToCustomer?.data?.CustomerAttachAccountManager?.message)

    // Report failure if both route and accountManager are empty.
    if (!_.isEmpty(_MutationAttachAccountManagerToCustomer) && 'UPDATE_SUCCESSFUL' === _MutationAttachAccountManagerToCustomer?.data?.CustomerAttachAccountManager?.status) return onClose?.()

    // Play toast on completion.
    return void 0
  }

  // Return component.
  return (
    <>
      <Flex className='attachAccountManagerAndAccountManager' direction='column' gap='30px'>
        <Flex flex='1' direction='column'>
          <FormLabel style={labelStyle}>Assign AccountManager</FormLabel>
          <MemoizedSearchSelect
            style={selectStyle}
            data={accountManager}
            placeholder='e.g. "Awesome AccountManager"'
            onChange={e => e.target.value?.includes('(') && e.target.value?.includes(')') ? setAccountManager(e.target.value?.split('(')[1]?.split(')')[0]) : e.target.value}
            options={_QueryAccountManagerRead?.data?.AccountRead?.map(({ id, displayName }) => `${displayName} (${id})`)}
          />
        </Flex>
      </Flex>
      <Flex justifyContent='space-between' mt='20px' mb='20px'>
        <Button
          width='100%'
          height='42px'
          mt={5}
          borderRadius='10px'
          bg='rgba(117, 81, 255, 1)'
          _hover={{ 'bg': 'rgba(117, 81, 255, 1)' }}
          color='rgba(255, 255, 255, 1)'
          disabled={_.isEmpty(accountManager)}
          onClick={_AttachAccountManagerAndAccountManagerToCustomer}>
                    Save Setting {MutationAttachAccountManagerResponse.loading || MutationAttachAccountManagerResponse.loading ? <Spinner color='#c1a9f4' size='sm' ml='10px' /> : <IoIosSave size={16} style={{ 'marginLeft': '10px' }} />}
        </Button>
      </Flex>
    </>
  )
}


/*
 * PROPTYPES
 */
Index.propTypes = {
  'passOn': PropTypes.object,
  'onClose': PropTypes.func
}


/*
 * REDUX
 */
const _MapStateToProps = __state => ({ 'passOn': __state.PassOn })


/*
 * EXPORT
 */
export default connect(_MapStateToProps)(Index)
