/*
 * IMPORTS
 */
import { gql } from '@apollo/client' // Npm: Apollo client for handling graphql request.


/*
 * EXPORTS
 */
export default gql`
mutation AccountCreateMutation(
  $email: EmailAddress!,
  $password: String!,
  $displayName: String!,
) {
  AccountRegisterWithEmail(
    email: $email,
    password: $password,
    displayName: $displayName,
  ) {
    message
    status
  }
}
`
