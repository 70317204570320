/*
 * IMPORTS
 */
import { gql } from '@apollo/client' // Npm: Apollo client for handling graphql request.


/*
 * EXPORTS
 */
export default gql`
mutation AccountSettingMutation($accountId: ID!, $accountType: ACCOUNT_TYPE){
  AccountSetting(accountId: $accountId, accountType: $accountType) {
    message,
    status
  }
}`
