/*
 * IMPORTS
 */
import { gql } from '@apollo/client' // Npm: Apollo client for handling graphql request.


/*
 * EXPORTS
 */
export default gql`
mutation OperatorPrefixCreateMutation(
  $mncId: ID!,
  $prefix: PositiveInt!
) {
  OperatorPrefixCreate(
    mncId: $mncId,
    prefix: $prefix
  ) {
    id
    message
    status
  }
}
`
