/*
 * IMPORTS
 */
import React from 'react' // Npm: react.js library.
import Moment from 'moment' // Npm: moment.js library.
import _ from 'underscore' // Npm: underscore library.
import { useQuery } from '@apollo/client' // Npm: Apollo client.
import { HiPlusCircle } from 'react-icons/hi2' // Npm: React icons.
import {
  Button,
  Flex,
  Table,
  TableContainer,
  Tbody,
  Td,
  Thead,
  Tooltip,
  Tr,
  useDisclosure
} from '@chakra-ui/react' // Npm: Chakra UI components.


/*
 * PACKAGES
 */
import DownloadToExcel from 'components/DownloadToExcel'
import TableSpinner from 'components/TableSpinner'
import Pagination from 'components/Pagination'
import Modal from 'components/Modal'
import VendorUpsert from 'components/VendorUpsert'
import VendorDelete from 'components/VendorDelete'
import VendorDropDownOptions from 'components/VendorDropDownOptions'


/*
 * GRAPHS
 */
import VendorReadQuery from './__query__/index.vendor.read.query'


/*
 * STYLES
 */
import {
  buttonStyle,
  cellStyle,
  headerStyle,
  rowStyle
} from './index.style'


/*
 * OBJECTS
 */
const Index = () => {
  // Const assignment.
  const _skipDifference = 10

  // Hook assignment.
  const [skipPage, setSkipPage] = React.useState(0)
  const { 'onOpen': onVendorCreateOpen, 'isOpen': isVendorCreateOpen, 'onClose': onVendorCreateClose } = useDisclosure()
  const { 'onOpen': onVendorUpdateOpen, 'isOpen': isVendorUpdateOpen, 'onClose': onVendorUpdateClose } = useDisclosure()
  const { 'onOpen': onVendorDeleteOpen, 'isOpen': isVendorDeleteOpen, 'onClose': onVendorDeleteClose } = useDisclosure()
  const _QueryVendorRead = useQuery(VendorReadQuery, { 'variables': { 'skip': skipPage * _skipDifference, 'take': _skipDifference } })

  // Event handler.
  React.useEffect(() => {
    // Always refetch when ever modals of close are closed.
    if (!isVendorCreateOpen || !isVendorUpdateOpen || !isVendorDeleteOpen) _QueryVendorRead.refetch()
  }, [isVendorCreateOpen, isVendorUpdateOpen, isVendorDeleteOpen])

  // Return component.
  return (
    <Flex
      fontSize={['Clamp(13px, 1.5vw, 15px)']}
      direction={{ 'base': 'column' }}
      display='flex'
      flex={1}
      overflowX='scroll'>
      <Flex
        display='flex'
        flex={1}
        flexDirection='column'
        gap='1rem'
        bg='white'
        minH='calc(100vh - 160px)'
        maxHeight='100%'
        borderRadius='20px'
        p='20px'>
        <Flex pb='0px' justify='space-between'>
          <Tooltip label='Create Vendor Mapping.' fontSize='sm'>
            <Button
              onClick={onVendorCreateOpen}
              leftIcon={<HiPlusCircle />}
              style={buttonStyle}>
              Create Vendor Mapping
            </Button>
          </Tooltip>
          <DownloadToExcel
            cellsData={0 === _QueryVendorRead.data?.VendorRead.length || !_.every(_.pluck(_QueryVendorRead.data?.VendorRead, 'status'), i => 'READ_SUCCESSFUL' === i) ? [] : _QueryVendorRead.data?.VendorRead?.map((item, __index) => ({
              'S.No.': `${(__index + (_skipDifference * (skipPage))) + 1}.`,
              'CreatedAt': item.createdAt,
              'UpdatedAt': item?.updatedAt ?? '-',
              'Name': item?.displayName ?? '-',
              'Website': item?.website ?? '-'
            }))}
            headersData={[
              'S.No.',
              'CreatedAt',
              'UpdatedAt',
              'Name',
              'Website'
            ].map(i => ({ 'key': i, 'label': i }))} />
        </Flex>
        <TableContainer
          maxH='calc(100vh - 260px)'
          flex={1}
          display='flex'
          borderRadius='15px'
          outline='1px solid #C5CFE8'
          overflowY='auto'>
          {_QueryVendorRead.loading ? (
            <Flex w='100%' h='100%' display='flex' alignItems='center' justifyContent='center'>
              <TableSpinner isLoading={true} />
            </Flex>
          ) : 0 === _QueryVendorRead.data?.VendorRead?.length || !_.every(_.pluck(_QueryVendorRead.data?.VendorRead, 'status'), i => 'READ_SUCCESSFUL' === i) ? (
            <Flex w='100%' h='100%' display='flex' alignItems='center' justifyContent='center'>
              <TableSpinner isLoading={false} isEmpty={true} />
            </Flex>
          ) : (
            <Table variant='simple' display='block' size='md'>
              <Thead>
                <Tr style={{ ...headerStyle, position: 'sticky', top: 0, zIndex: 1 }}>

                  <Td style={rowStyle}>S.No</Td>
                  <Td style={rowStyle}>CreatedAt</Td>
                  <Td style={rowStyle}>UpdatedAt</Td>
                  <Td style={rowStyle}>Name</Td>
                  <Td style={rowStyle}>Website</Td>
                  <Td style={rowStyle}>Actions</Td>
                </Tr>
              </Thead>
              <Tbody style={cellStyle}>
                {_QueryVendorRead.data?.VendorRead?.map(({ id, updatedAt, displayName, website, createdAt }, __index) => (
                  <Tr key={String.random(9)}>
                    <Td style={rowStyle} borderRight='1px solid rgba(216, 227, 252, 1)'>{`${(__index + (_skipDifference * (skipPage))) + 1}.`}</Td>
                    <Td style={rowStyle} borderRight='1px solid rgba(216, 227, 252, 1)'>{Moment(createdAt).format('YYYY-MM-DD hh:mm:ss A')}</Td>
                    <Td style={rowStyle} borderRight='1px solid rgba(216, 227, 252, 1)'>{Moment(updatedAt).format('YYYY-MM-DD hh:mm:ss A')}</Td>
                    <Td style={rowStyle} borderRight='1px solid rgba(216, 227, 252, 1)' width='100%'>{displayName}</Td>
                    <Td style={rowStyle} borderRight='1px solid rgba(216, 227, 252, 1)'>{website}</Td>
                    <Td style={rowStyle} borderRight='1px solid rgba(216, 227, 252, 1)'>
                      {
                        <VendorDropDownOptions
                          vendorId={id}
                          onVendorUpdate={onVendorUpdateOpen}
                          onVendorDelete={onVendorDeleteOpen} />
                      }
                    </Td>
                  </Tr>
                ))}
              </Tbody>
            </Table>
          )}
        </TableContainer>
      </Flex>
      <Pagination itemsPerPage={_skipDifference} totalCount={_.pluck(_QueryVendorRead.data?.VendorRead, '_totalCount')?.[0]} onPageChange={setSkipPage} />
      <Modal
        size='md'
        title='Vendor Mapping Create'
        isOpen={isVendorCreateOpen}
        onClose={onVendorCreateClose}>
        <VendorUpsert isCreateOnly={true} />
      </Modal>
      <Modal
        size='md'
        title='Vendor Mapping Update'
        isOpen={isVendorUpdateOpen}
        onClose={onVendorUpdateClose}>
        <VendorUpsert />
      </Modal>
      <Modal
        size='md'
        title='Vendor Mapping Delete'
        isOpen={isVendorDeleteOpen}
        onClose={onVendorDeleteClose}>
        <VendorDelete />
      </Modal>
    </Flex>
  )
}


/*
 * EXPORT
 */
export default Index
