/*
 * IMPORTS
 */
import { gql } from '@apollo/client' // Npm: Apollo client for handling graphql request.


/*
 * EXPORTS
 */
export default gql`
  query RouteReadQuery($take: PositiveInt, $skip: Int) {
    RouteRead(take: $take, skip: $skip) {
      id,
      createdAt,
      updatedAt,
      displayName,
      type,
      message,
      status,
      _totalCount,
      Account {
        email
        displayName
      }
      RoutePlan {
        Mcc {
          mcc
        },
        Mnc {
          mnc,
          network
        }
      }
    }
  }
`
