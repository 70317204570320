/*
 * IMPORTS
 */
import { gql } from '@apollo/client' // Npm: Apollo client for handling graphql request.


/*
 * EXPORTS
 */
export default gql`
query SmsReadQuery($customerId: ID, $take: PositiveInt, $skip: Int) {
  SmsRead(customerId: $customerId, take: $take, skip: $skip) {
    id,
    createdAt,
    updatedAt,
    sourceAddress,
    destinationAddress,
    registeredDelivery,
    deliveredThrough,
    isUnicodeMessage,
    messageLength,
    messageChunkSize,
    message,
    status,
    _totalCount,
    RoutePlan {
      Route {
        displayName
      }
    }
  }
}
`
