/*
 * IMPORTS
 */
import React from 'react' // Npm: react.js library.
import PropTypes from 'prop-types' // Npm: react.js library.
import JoiBrowser from 'joi-browser' // Npm: Joi for frontend validation.
import _ from 'underscore' // Npm: Underscore.js library.
import { toast } from 'react-hot-toast' // Npm: React hot toast.
import { connect } from 'react-redux' // Npm: React Redux for state management.
import { useLazyQuery, useMutation, useQuery } from '@apollo/client' // Npm: Apollo client.
import {
  Flex,
  FormControl
} from '@chakra-ui/react' // Npm: Chakra UI components.



/*
 * PACKAGES
 */
import ModalFooter from 'components/ModalFooter'
import { MemoizedInput, MemoizedSearchSelect, MemoizedSelect } from 'components/MemoizedInput'


/*
 * GRAPHS
 */
import RouteReadQuery from './__query__/index.route.read.query'
import CustomerAccountQuery from './__query__/index.customerAccount.read.query'
import CustomerAccountAttachSmppMutation from './__mutation__/index.customerAccount.attachSmpp.mutation'
import CustomerAccountUpdateMutation from './__mutation__/index.customerAccount.update.mutation'
import CustomerAccountCreateMutation from './__mutation__/index.customerAccount.create.mutation'
import CustomerAccountAttachRouteMutation from './__mutation__/index.customerAccount.attachRoute.mutation'
import SmppUpdateMutation from './__mutation__/index.smpp.update.mutation'
import SmppCreateMutation from './__mutation__/index.smpp.create.mutation'


/*
 * STYLES
 */
import './index.style.css'
import { selectStyle } from './index.style'


/*
 * OBJECTS
 */
const Index = ({
  isCreateOnly,
  isOpen,
  onClose,
  passOn
}) => {
  // Hook assignment.
  const [route, setRoute] = React.useState('')
  const [MutationCustomerAccountAttachRoute, MutationCustomerAccountAttachRouteResponse] = useMutation(CustomerAccountAttachRouteMutation)
  const [MutationCustomerAccountCreate, MutationCustomerAccountCreateResponse] = useMutation(CustomerAccountCreateMutation)
  const [MutationCustomerAccountUpdate, MutationCustomerAccountUpdateResponse] = useMutation(CustomerAccountUpdateMutation)
  const [QueryCustomerAccountRead] = useLazyQuery(CustomerAccountQuery)
  const [error, setError] = React.useState('')
  const [forceReRender, setForceReRender] = React.useState('')
  const [MutationSmppCreate, MutationSmppCreateResponse] = useMutation(SmppCreateMutation)
  const [MutationSmppUpdate, MutationSmppUpdateResponse] = useMutation(SmppUpdateMutation)
  const [MutationCustomerAccountAttachSmpp] = useMutation(CustomerAccountAttachSmppMutation)
  const _RouteReadQuery = useQuery(RouteReadQuery, { 'variables': { 'take': 1000, 'skip': 0 } })
  const _formDataRef = React.useRef({})

  // Object assignment.
  const _SubmitForm = async e => {
    // Local variable.
    let _MutationCustomerAccountAttachRouteToCustomer

    // Prevent default behavior.
    e?.preventDefault()

    // Object assignment.
    const _CustomerAccountAttachRouteAndRate = async () => {
      // Only Execute if route has data.
      if (!_.isEmpty(route)) {
        // Execute mutation.
        _MutationCustomerAccountAttachRouteToCustomer = await MutationCustomerAccountAttachRoute({
          'variables': {
            'customerAccountId': passOn?.customerAccountId,
            'routeId': route
          }
        })

        // If mutation caught an exception then report failure.
        if (_MutationCustomerAccountAttachRouteToCustomer instanceof Error) throw _MutationCustomerAccountAttachRouteToCustomer
      }

      // Style Guide.
      (!_.isEmpty(route)) && toast(_MutationCustomerAccountAttachRouteToCustomer?.data?.CustomerAccountAttachRoute?.message)
    }

    // Const assignment.
    const _JoiSchema = JoiBrowser.object({
      'displayName': JoiBrowser.string().required(),
      'ip': JoiBrowser.string().max(45).ip({ 'version': ['ipv4', 'ipv6', 'ipvfuture'], 'cidr': 'optional' }).required(),
      'username': JoiBrowser.string().required(),
      'password': JoiBrowser.string().required(),
      'sessionTimeoutInterval': JoiBrowser.number().required(),
      'sessionAllowed': JoiBrowser.number().optional(),
      'tps': JoiBrowser.number().optional(),
      'enquireLinkInterval': JoiBrowser.number().required(),
      'routePlan': JoiBrowser.string().optional()
    }).options({ 'allowUnknown': true })

    // Remove all keys from _formDataRef.current which are undefined.
    _formDataRef.current = _.pick(_formDataRef.current, _.identity)

    // Validate form data.
    const _JoiSchemaValidate = _JoiSchema.validate(_formDataRef.current)

    // If error exists then report failure.
    if (_JoiSchemaValidate.error) return setError(_JoiSchemaValidate.error?.message)

    // Reset error.
    setError('')

    // Create CustomerAccount for given Customer.
    const _MutationCustomerAccount = await [isCreateOnly ? MutationCustomerAccountCreate : MutationCustomerAccountUpdate]?.[0]({
      'variables': {
        [isCreateOnly ? 'customerId' : 'customerAccountId']: isCreateOnly ? passOn?.customerId : passOn?.customerAccountId,
        'displayName': _formDataRef?.current?.displayName
      }
    })

    // If creating or updating customer account caught an exception then report failure.
    if (_MutationCustomerAccount instanceof Error) return _MutationCustomerAccount

    // Execute update mutation.
    const _MutationSmppUpdate = await [isCreateOnly ? MutationSmppCreate : MutationSmppUpdate]?.[0]({
      'variables': {
        ..._.omit(_formDataRef?.current, isCreateOnly ? 'smppId' : void 0),
        'smppId': isCreateOnly ? void 0 : _formDataRef.current.smppId
      }
    })

    // If mutation caught an exception then report failure.
    if (_MutationSmppUpdate instanceof Error) return _MutationSmppUpdate

    // Style Guide.
    toast(_MutationSmppUpdate?.data?.SmppCreate?.message ?? _MutationSmppUpdate?.data?.SmppUpdate?.message)

    // Attach route and rate to given customer.
    await _CustomerAccountAttachRouteAndRate()

    // On Successful response from the mutation.
    if (isCreateOnly && 'CREATE_SUCCESSFUL' === _MutationSmppUpdate?.data?.SmppCreate?.status) {
      // Attach given customer with smpp.
      const _MutationCustomerAccountAttachSmpp = await MutationCustomerAccountAttachSmpp({ 'variables': { 'customerAccountId': _MutationCustomerAccount?.data[isCreateOnly ? 'CustomerAccountCreate' : 'CustomerAccountUpdate']?.id, 'smppId': _MutationSmppUpdate?.data?.SmppCreate?.id } })

      // If mutation caught an exception then report failure.
      if (_MutationCustomerAccountAttachSmpp instanceof Error) return _MutationCustomerAccountAttachSmpp

      // Only close if attach is successful.
      if ('UPDATE_SUCCESSFUL' === _MutationCustomerAccountAttachSmpp?.data?.CustomerAccountAttachSmpp?.status) return onClose?.(_MutationCustomerAccountAttachSmpp?.data?.CustomerAccountAttachSmpp, _formDataRef?.current)
    }
    if ('UPDATE_SUCCESSFUL' === _MutationSmppUpdate?.data?.SmppUpdate?.status) return onClose?.(_MutationSmppUpdate?.data?.SmppUpdate, _formDataRef?.current)


    // Return void 0.
    return void 0
  }

  // Event handler.
  React.useEffect(() => {
    // _Async handler.
    const _Async = async () => {
      // Const assignment.
      const _QueryCustomerAccountReadQuery = await QueryCustomerAccountRead({ 'variables': { 'customerAccountId': isCreateOnly ? 'UN_KNOWN' : passOn?.customerAccountId } })

      // If query caught an exception then report failure.
      if (_QueryCustomerAccountReadQuery instanceof Error) return _QueryCustomerAccountReadQuery

      /*
       * If smpp details fetch complete then
       * update its value.
       */
      if (_.first(_QueryCustomerAccountReadQuery?.data?.CustomerAccountRead) && !_.isEmpty(_.first(_QueryCustomerAccountReadQuery?.data?.CustomerAccountRead).Smpp)) {
        // Update form data.
        _formDataRef.current = {
          'smppId': _.first(_QueryCustomerAccountReadQuery?.data?.CustomerAccountRead).Smpp.id,
          'displayName': _.first(_QueryCustomerAccountReadQuery?.data?.CustomerAccountRead).Smpp.displayName,
          'ip': _.first(_QueryCustomerAccountReadQuery?.data?.CustomerAccountRead).Smpp.ip,
          'username': _.first(_QueryCustomerAccountReadQuery?.data?.CustomerAccountRead).Smpp.username,
          'password': _.first(_QueryCustomerAccountReadQuery?.data?.CustomerAccountRead).Smpp.password,
          'sessionTimeoutInterval': _.first(_QueryCustomerAccountReadQuery?.data?.CustomerAccountRead).Smpp.sessionTimeoutInterval,
          'sessionAllowed': _.first(_QueryCustomerAccountReadQuery?.data?.CustomerAccountRead).Smpp.sessionAllowed,
          'tps': _.first(_QueryCustomerAccountReadQuery?.data?.CustomerAccountRead).Smpp.tps,
          'enquireLinkInterval': _.first(_QueryCustomerAccountReadQuery?.data?.CustomerAccountRead).Smpp.enquireLinkInterval,
          'debuggingLevel': _.first(_QueryCustomerAccountReadQuery?.data?.CustomerAccountRead).Smpp.debuggingLevel
        }

        // Update state.
        return setForceReRender(String.random(8))
      }

      // Report failure.
      return void 0
    } ;_Async().catch(i => i)
  }, [passOn, isOpen])

  // Return component.
  return (
    <form onSubmit={_SubmitForm} key={forceReRender}>
      <Flex>
        <Flex gap='20px' flexDir='column' w='100%'>
          <Flex gap='20px'>
            <FormControl>
              <MemoizedInput
                isRequired={true}
                name='displayName'
                label='Display name'
                placeholder='e.g. "Rocking Smpp"'
                onChange={({ target }) => {
                  // Over spreading.
                  const { name, value } = target

                  // Update form data.
                  _formDataRef.current = {
                    ..._formDataRef?.current,
                    [name]: value
                  }
                }}
                isInvalid={error?.includes('displayName')}
                error={error}
                data={_formDataRef?.current?.displayName}
              />
            </FormControl>
            <FormControl>
              <MemoizedInput
                name='ip'
                label='ip'
                placeholder='e.g. "0.0.0.0"'
                onChange={({ target }) => {
                  // Over spreading.
                  const { name, value } = target

                  // Update form data.
                  _formDataRef.current = {
                    ..._formDataRef?.current,
                    [name]: value
                  }
                }}
                isRequired={true}
                error={error}
                isInvalid={error?.includes('ip')}
                data={_formDataRef?.current?.ip}
              />
            </FormControl>
          </Flex>
          <Flex gap='20px'>
            <FormControl>
              <MemoizedInput
                name='username'
                label='SystemId'
                placeholder='e.g. "RockSmpp"'
                onChange={({ target }) => {
                  // Over spreading.
                  const { name, value } = target

                  // Update form data.
                  _formDataRef.current = {
                    ..._formDataRef?.current,
                    [name]: value
                  }
                }}
                isRequired={true}
                error={error}
                isInvalid={error?.includes('username')}
                data={_formDataRef?.current?.username}
              />
            </FormControl>
            <FormControl>
              <MemoizedInput
                name='password'
                label='Password'
                placeholder='e.g. "*******"'
                onChange={({ target }) => {
                  // Over spreading.
                  const { name, value } = target

                  // Update form data.
                  _formDataRef.current = {
                    ..._formDataRef?.current,
                    [name]: value
                  }
                }}
                isRequired={true}
                error={error}
                isInvalid={error?.includes('password')}
                data={_formDataRef?.current?.password}
              />
            </FormControl>
          </Flex>
          <Flex gap='20px'>
            <FormControl>
              <MemoizedInput
                isRequired={true}
                name='sessionTimeoutInterval'
                label='Response Time '
                placeholder='e.g. "300"'
                onChange={({ target }) => {
                  // Over spreading.
                  const { name, value } = target

                  // Update form data.
                  _formDataRef.current = {
                    ..._formDataRef?.current,
                    [name]: Number(value ?? 0)
                  }
                }}
                error={error}
                isInvalid={error?.includes('sessionTimeoutInterval')}
                data={_formDataRef?.current?.sessionTimeoutInterval}
              />
            </FormControl>
            <FormControl>
              <MemoizedInput
                name='sessionAllowed'
                label='Session Allowed'
                placeholder='e.g. "2"'
                onChange={({ target }) => {
                  // Over spreading.
                  const { name, value } = target

                  // Update form data.
                  _formDataRef.current = {
                    ..._formDataRef?.current,
                    [name]: Number(value ?? 0)
                  }
                }}
                error={error}
                isInvalid={error?.includes('sessionAllowed')}
                data={_formDataRef?.current?.sessionAllowed}
              />
            </FormControl>
          </Flex>
          <Flex gap='20px'>
            <FormControl>
              <MemoizedInput
                name='tps'
                label='Submit Speed Per Second'
                placeholder='e.g. "1000"'
                onChange={({ target }) => {
                  // Over spreading.
                  const { name, value } = target

                  // Update form data.
                  _formDataRef.current = {
                    ..._formDataRef?.current,
                    [name]: Number(value ?? 0)
                  }
                }}
                error={error}
                isInvalid={error?.includes('tps')}
                data={_formDataRef?.current?.tps}
              />
            </FormControl>
            <FormControl>
              <MemoizedInput
                isRequired={true}
                name='enquireLinkInterval'
                label='Enquire link '
                placeholder='e.g. "60"'
                onChange={({ target }) => {
                  // Over spreading.
                  const { name, value } = target

                  // Update form data.
                  _formDataRef.current = {
                    ..._formDataRef?.current,
                    [name]: Number(value ?? 0)
                  }
                }}
                error={error}
                isInvalid={error?.includes('enquireLinkInterval')}
                data={_formDataRef?.current?.enquireLinkInterval}
              />
            </FormControl>
          </Flex>
          <Flex gap='20px'>
            <FormControl>
              <MemoizedSearchSelect
                style={selectStyle}
                label='Assign Route'
                name='route'
                data={_formDataRef?.current?.route}
                placeholder='e.g. "Amazing Route"'
                onChange={({ target }) => {
                  // Over spreading.
                  const { name, value } = target

                  // Update form data.
                  _formDataRef.current = {
                    ..._formDataRef?.current,
                    [name]: value?.includes('(') && value?.includes(')') ? value?.split('(')[1]?.split(')')[0] : value
                  }

                  // Update route.
                  setRoute(_formDataRef?.current?.[name])
                }}
                options={_RouteReadQuery?.data?.RouteRead?.map?.(({ id, displayName }) => `${displayName} (${id})`)}
              />
            </FormControl>
          </Flex>
        </Flex>
      </Flex>
      <ModalFooter isLoading={MutationCustomerAccountCreateResponse.loading || MutationCustomerAccountUpdateResponse.loading || MutationCustomerAccountAttachRouteResponse.loading || MutationSmppCreateResponse.loading || MutationSmppUpdateResponse.loading} onSubmit={_SubmitForm} />
    </form>
  )
}


/*
 * PROPTYPES
 */
Index.propTypes = {
  'passOn': PropTypes.object,
  'onClose': PropTypes.func,
  'isCreateOnly': PropTypes.bool,
  'isOpen': PropTypes.bool
}
Index.defaultProps = {}


/*
 * REDUX
 */
const _MapStateToProps = __state => ({ 'passOn': __state.PassOn })


/*
 * EXPORT
 */
export default connect(_MapStateToProps)(Index)
