/*
 * IMPORTS
 */
import { gql } from '@apollo/client' // Npm: Apollo client for handling graphql request.


/*
 * GRAPHS
 */
const Index = gql`
  query MccReadQuery($mcc: String, $take: PositiveInt, $skip: Int) {
    MccRead(mcc: $mcc, take: $take, skip: $skip) {
        mcc,
        countryName,
        Mnc {
            mnc,
            network
        }
        status,
        message
    }
  }
`


/*
 * EXPORTS
 */
export default Index
