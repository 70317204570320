/*
 * IMPORTS
 */
import { gql } from '@apollo/client' // Npm: Apollo client for handling graphql request.


/*
 * EXPORTS
 */
export default gql`
query AccountRead($take: PositiveInt, $skip: Int) @live {
    AccountRead(take: $take, skip: $skip) {
      id
      displayName,
      isActive,
      accountType,
      status,
      updatedAt,
      createdAt,
      email,
      isBlocked,
      activeAs,
      _totalCount
    }
  }`
