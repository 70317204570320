/*
 * IMPORTS
 */
import { gql } from '@apollo/client' // Npm: Apollo client for handling graphql request.


/*
 * EXPORTS
 */
export default gql`
query VendorAccountReadQuery($vendorId: ID!, $take: PositiveInt, $skip: Int) {
  VendorAccountRead(vendorId: $vendorId, take: $take, skip: $skip) {
    id
    createdAt
    updatedAt
    displayName
    status
    message
    _totalCount
    Rate {
      displayName
      currency
      RatePlan {
        rate
      }
    }
    RoutePlan {
      id,
      Route {
        id
        displayName
      }
    }
    Vendor {
      displayName
    }
    Billing {
      email
      creditLimit
      currency
    }
    Smpp {
      id
      ip,
      port,
      tps,
      sessionAllowed,
      sourceAddress,
      isActive,
      ContentTranslation {
        id
      }
    }
  }
}
`
