/*
 * IMPORTS
 */
import 'react-date-range/dist/styles.css' // Npm: React date range.
import 'react-date-range/dist/theme/default.css' // Npm: React date range.
import React from 'react' // Npm: react.js library.
import { useQuery } from '@apollo/client' // Npm: Apollo client.
import { HiUsers } from 'react-icons/hi2' // Npm: React icons.
import { Flex, Spinner, Text } from '@chakra-ui/react' // Npm: Chakra UI components.


/*
 * COMPONENTS
 */
import DateRangePicker from 'components/dateRangePicker'


/*
 * GRAPHS
 */
import CustomerCountQuery from './__query__/index.customer.count.query'


/*
 * OBJECTS
 */
const Index = () => {
  // Object assignment.
  const _DateRangeProvider = () => {
    // Const assignment.
    const _yesterday = new Date()

    // Set date range to 30 days
    _yesterday.setDate(_yesterday.getDate() - 1)

    // Set start date to yesterday.
    const _startDate = new Date(_yesterday)

    // Set start date to 30 days ago.
    _startDate.setDate(_startDate.getDate() - 29)

    // Return date range.
    return ({
      'endDate': _yesterday.toISOString().split('T')[0],
      'startDate': _startDate.toISOString().split('T')[0]
    })
  }

  // Hook assignment.
  const [count, setCount] = React.useState(0)
  const [growth, setGrowth] = React.useState('0%')
  const [dateRange, setDateRange] = React.useState({
    'selection': {
      'startDate': new Date(),
      'endDate': null,
      'key': 'selection'
    }
  })
  const _QueryCustomerCount = useQuery(CustomerCountQuery, {
    'variables': {
      'startDate': dateRange?.selection.endDate ? dateRange.selection.startDate.toISOString().split('T')[0] : _DateRangeProvider().startDate,
      'endDate': dateRange?.selection.endDate ? dateRange.selection.endDate.toISOString().split('T')[0] : _DateRangeProvider().endDate
    }
  })

  // Event handler.
  React.useEffect(() => {
    // Async handler.
    const _Async = async () => {
      // Refetch data on date range change.
      const { data, error } = await _QueryCustomerCount.refetch({
        'startDate': dateRange?.selection.endDate ? dateRange.selection.startDate.toISOString().split('T')[0] : _DateRangeProvider().startDate,
        'endDate': dateRange?.selection.endDate ? dateRange.selection.endDate.toISOString().split('T')[0] : _DateRangeProvider().endDate
      })

      // If getting data caught exception then report failure.
      if (error instanceof Error) throw error

      // Update data on successful read.
      if ('COUNT_SUCCESSFUL' === data?.CustomerCount?.status) {
        // Update state.
        setGrowth(data?.CustomerCount?._totalCount)
        setCount(data?.CustomerCount?.count)
      }
    } ;_Async().catch(e => e)
  }, [dateRange])

  // Return component.
  return (
    <Flex
      my='auto'
      w='100%'
      borderRadius='20px'
      bg='white'
      h='140px'
      p='15px'
      gap='10px'
      flexDir='column'
      color='#1B2559'
      fontSize={['clamp(13px, 1.5vw, 16px)']}
      justify='space-between'
      boxShadow='14px 17px 40px 4px rgba(112, 144, 176, 0.17)'>
      <DateRangePicker
        setSelectDate={setDateRange}
        isLoading={_QueryCustomerCount?.loading} />
      {_QueryCustomerCount?.loading ? (
        <Spinner mx='auto' my='auto' color='#c1a9f4' />
      ) : (
        <>
          <Flex w='100%' justify='space-between'>
            <Flex
              my='auto'
              display='flex'
              flexDir='column'>
              <Text
                lineHeight='100%'
                color='gray.500'
                fontWeight='600'
                fontSize='13px'
                mb='8px'>
                New Customers
              </Text>
              <Text
                fontSize={['clamp(15px, 2vw, 25px)']}
                fontWeight='900'
                color='#1B2559'>
                {`${(count)}%`}
              </Text>
              <Flex fontWeight='500' align='center'>
                <Text
                  color='green.500'
                  fontSize='12px'
                  fontWeight='700'
                  me='5px'>
                  {growth ?? 0}
                </Text>
                <Text
                  color='secondaryGray.600'
                  fontSize='11px'
                  fontWeight='600'>
                    till today
                </Text>
              </Flex>
            </Flex>
            <Flex
              w='60px'
              h='60px'
              bg='#F1F5FF'
              borderRadius='10px'
              align='center'
              justify='center'>
              <HiUsers color='#4308FF' size={25} />
            </Flex>
          </Flex>
        </>
      )}
    </Flex>
  )
}


/*
 * EXPORT
 */
export default Index


