/*
 * IMPORTS
 */
import { createBreakpoints } from '@chakra-ui/theme-tools' // Npm: Chakra UI theme tools.
import { extendTheme } from '@chakra-ui/react' // Npm: Chakra UI component library.


/*
 * SIBLINGS
 */
import { CardComponent } from './components/Card'
import { buttonStyles } from './components/Button'
import { badgeStyles } from './components/Badge'
import { inputStyles } from './components/Input'
import { progressStyles } from './components/Progress'
import { sliderStyles } from './components/Slider'
import { textareaStyles } from './components/TextArea'
import { switchStyles } from './components/Switch'
import { linkStyles } from './components/Link'


/*
 * STYLES
 */
import { globalStyles } from './index.style'


/*
 * GLOBALS
 */
const _breakpoints = createBreakpoints({
  'sm': '320px',
  '2sm': '380px',
  'md': '768px',
  'lg': '960px',
  'xl': '1200px',
  '2xl': '1600px',
  '3xl': '1920px'
})


/*
 * EXPORTS
 */
export default extendTheme(
  { 'breakpoints': _breakpoints },
  globalStyles,
  badgeStyles,
  buttonStyles,
  linkStyles,
  progressStyles,
  sliderStyles,
  inputStyles,
  textareaStyles,
  switchStyles,
  CardComponent
)
