/*
 * IMPORTS
 */
import { gql } from '@apollo/client' // Npm: Apollo client for handling graphql request.


/*
 * EXPORTS
 */
export default gql`
query MncReadQuery($mncId: ID) {
  MncRead(mncId: $mncId) {
    id,
    network,
    mnc,
    Mcc {
      id
    }
  }
}
`
