/*
 * IMPORTS
 */
import { gql } from '@apollo/client' // Npm: Apollo client for handling graphql request.


/*
 * EXPORTS
 */
export default gql`
mutation SmppAttachContentTranslationMutation(
  $smppId: ID!,
  $contentTranslationId: ID!
) {
  SmppAttachContentTranslation(
    smppId: $smppId,
    contentTranslationId: $contentTranslationId
  ) {
    id
    message
    status
  }
}
`
