/*
 * IMPORTS
 */
import { gql } from '@apollo/client' // Npm: Apollo client for handling graphql request.


/*
 * EXPORTS
 */
export default gql`
mutation AccountLogoutMutation($accountId: ID!){
  AccountLogout(accountId: $accountId) {
    message,
    status
  }
}`
