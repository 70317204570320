/*
 * IMPORTS
 */
import React from 'react' // Npm import: React.js library
import PropTypes from 'prop-types' // Npm import: Prop types validator.
import ClassName from 'classnames' // Npm import: classnames utility.
import { Flex } from '@chakra-ui/react' // Npm import: Chakra UI components.


/*
 * UTILS
 */
import coreStyles from '../util/index.style'
import { findNextRangeIndex, generateStyles } from '../util'


/*
 * SIBLINGS
 */
import DateRange from './DateRange/index'
import DefinedRange from './DefinedRange/index'


/*
 * OBJECTS
 */
const Index = props => {
  // Hook assignment.
  const [focusedRange, setFocusedRange] = React.useState([findNextRangeIndex(props.ranges), 0])
  const _styles = generateStyles([coreStyles, props.classNames])
  const _dateRangeRef = React.useRef(null)

  // Return component.
  return (
    <Flex
      className={ClassName(_styles.dateRangePickerWrapper, props.className)}>
      <DateRange
        setTurnOnCompare={props.setTurnOnCompare}
        turnOnCompare={props.turnOnCompare}
        onRangeFocusChange={newFocusedRange => setFocusedRange(newFocusedRange)}
        focusedRange={focusedRange}
        {...props}
        ref={_dateRangeRef}
        className={void 0}
      />
    </Flex>
  )
}


/*
 * PROPTYPES
 */
Index.defaultProps = {}
Index.propTypes = {
  ...DateRange.propTypes,
  ...DefinedRange.propTypes,
  'className': PropTypes.string
}


/*
 * EXPORTS
 */
export default Index
