/*
 * EXPORTS
 */
export default {
  'dateRangeWrapper': 'rdrDateRangeWrapper',
  'calendarWrapper': 'rdrCalendarWrapper',
  'dateDisplay': 'rdrDateDisplay',
  'dateDisplayItem': 'rdrDateDisplayItem',
  'dateDisplayItemActive': 'rdrDateDisplayItemActive',
  'monthAndYearWrapper': 'rdrMonthAndYearWrapper',
  'monthAndYearPickers': 'rdrMonthAndYearPickers',
  'nextPrevButton': 'rdrNextPrevButton',
  'month': 'rdrMonth',
  'weekDays': 'rdrWeekDays',
  'weekDay': 'rdrWeekDay',
  'days': 'rdrDays',
  'day': 'rdrDay',
  'dayNumber': 'rdrDayNumber',
  'dayPassive': 'rdrDayPassive',
  'dayToday': 'rdrDayToday',
  'dayStartOfWeek': 'rdrDayStartOfWeek',
  'dayEndOfWeek': 'rdrDayEndOfWeek',
  'daySelected': 'rdrDaySelected',
  'dayDisabled': 'rdrDayDisabled',
  'dayStartOfMonth': 'rdrDayStartOfMonth',
  'dayEndOfMonth': 'rdrDayEndOfMonth',
  'dayWeekend': 'rdrDayWeekend',
  'dayStartPreview': 'rdrDayStartPreview',
  'dayInPreview': 'rdrDayInPreview',
  'dayEndPreview': 'rdrDayEndPreview',
  'dayHovered': 'rdrDayHovered',
  'dayActive': 'rdrDayActive',
  'inRange': 'rdrInRange',
  'endEdge': 'rdrEndEdge',
  'startEdge': 'rdrStartEdge',
  'prevButton': 'rdrPprevButton',
  'nextButton': 'rdrNextButton',
  'selected': 'rdrSelected',
  'months': 'rdrMonths',
  'monthPicker': 'rdrMonthPicker',
  'yearPicker': 'rdrYearPicker',
  'dateDisplayWrapper': 'rdrDateDisplayWrapper',
  'definedRangesWrapper': 'rdrDefinedRangesWrapper',
  'staticRanges': 'rdrStaticRanges',
  'staticRange': 'rdrStaticRange',
  'inputRanges': 'rdrInputRanges',
  'inputRange': 'rdrInputRange',
  'inputRangeInput': 'rdrInputRangeInput',
  'dateRangePickerWrapper': 'rdrDateRangePickerWrapper',
  'staticRangeLabel': 'rdrStaticRangeLabel',
  'staticRangeSelected': 'rdrStaticRangeSelected',
  'monthName': 'rdrMonthName',
  'infiniteMonths': 'rdrInfiniteMonths',
  'monthsVertical': 'rdrMonthsVertical',
  'monthsHorizontal': 'rdrMonthsHorizontal'
}
