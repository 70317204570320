/*
 * IMPORTS
 */
import { gql } from '@apollo/client' // Npm: Apollo client for handling graphql request.


/*
 * EXPORTS
 */
export default gql`
mutation SmppUpdateMutation(
    $smppId: ID!,
    $displayName: String!,
    $ip: IPv4!,
    $username: String!,
    $password: String!,
    $sessionTimeoutInterval: Float!,
    $sessionAllowed: Int,
    $tps: Int,
    $enquireLinkInterval: Float!
) {
  SmppUpdate(
    smppId: $smppId,
    displayName: $displayName,
    ip: $ip,
    username: $username,
    password: $password,
    sessionTimeoutInterval: $sessionTimeoutInterval,
    sessionAllowed: $sessionAllowed,
    tps: $tps,
    enquireLinkInterval: $enquireLinkInterval
  ) {
    id,
    message
    status
  }
}
`
