/*
 * IMPORTS
 */
import React from 'react' // Npm: react.js library.
import PropTypes from 'prop-types' // Npm: react.js library.
import JoiBrowser from 'joi-browser' // Npm: Joi for frontend validation.
import _ from 'underscore' // Npm: Underscore.js library.
import { useLazyQuery, useMutation } from '@apollo/client' // Npm: Apollo client for graphql.
import { connect } from 'react-redux' // Npm: React Redux for state management.
import { toast } from 'react-toastify' // Npm: React toastify for notifications.
import { IoIosSave } from 'react-icons/io' // Npm: React icons.
import {
  Button,
  Flex,
  FormControl,
  Spinner
} from '@chakra-ui/react' // Npm: Chakra UI components.


/*
 * PACKAGES
 */
import { MemoizedInput } from 'components/MemoizedInput'


/*
 * GRAPHS
 */
import MccCreateMutation from './__mutation__/index.mcc.create.mutation'
import MccUpdateMutation from './__mutation__/index.mcc.update.mutation'
import MccReadQuery from './__query__/index.mcc.read.query'


/*
 * OBJECTS
 */
const Index = ({ isOpen, isCreateOnly, onClose, passOn }) => {
  // Hook assignment.
  const [error, setError] = React.useState('')
  const [forceReRender, setForceReRender] = React.useState('')
  const [MutationMccCreate, MutationMccCreateResponse] = useMutation(MccCreateMutation)
  const [MutationMccUpdate, MutationMccUpdateResponse] = useMutation(MccUpdateMutation)
  const [QueryMccRead] = useLazyQuery(MccReadQuery)
  const _formDataRef = React.useRef({})

  // Object assignment.
  const _SubmitForm = async e => {
    // Prevent default behavior.
    e.preventDefault()

    // Const assignment.
    const _JoiSchema = JoiBrowser.object({
      'countryName': JoiBrowser.string().required(),
      'dialingCode': JoiBrowser.number().required(),
      'mcc': JoiBrowser.string().required()
    }).options({ 'allowUnknown': true })

    // Remove all keys from _formDataRef.current which are undefined.
    _formDataRef.current = _.pick(_formDataRef.current, _.identity)

    // Validate form data.
    const _JoiSchemaValidate = _JoiSchema.validate(_formDataRef.current)

    // If error exists then report failure.
    if (_JoiSchemaValidate.error) return setError(_JoiSchemaValidate.error?.message)

    // Reset error.
    setError('')

    // Create or Update given mcc.
    const _MutationMcc = await [isCreateOnly ? MutationMccCreate : MutationMccUpdate]?.[0]({
      'variables': {
        'mccId': isCreateOnly ? passOn?.mcc : passOn?.mccId,
        ..._formDataRef.current
      }
    })

    // If creating or updating mcc caught an exception then report failure.
    if (_MutationMcc instanceof Error) return _MutationMcc

    // Style Guide.
    toast(_MutationMcc?.data?.MccCreate?.message || _MutationMcc?.data?.MccUpdate?.message)

    // On Successful update or create close the modal.
    if ('CREATE_SUCCESSFUL' === _MutationMcc?.data?.MccCreate?.status || 'UPDATE_SUCCESSFUL' === _MutationMcc?.data?.MccUpdate?.status) return onClose()

    // Report void 0.
    return void 0
  }

  // Event handler.
  React.useEffect(() => {
    // _Async handler.
    const _Async = async () => {
      // Const assignment.
      const _QueryMccRead = await QueryMccRead({ 'variables': { 'mccId': isCreateOnly ? 'UN_KNOWN' : passOn?.mccId } })

      // If query caught an exception then report failure.
      if (_QueryMccRead instanceof Error) return _QueryMccRead

      // Const assignment.
      const _mccRead = _.first(_QueryMccRead?.data?.MccRead)

      /*
       * If details fetch complete then
       * update its value.
       */
      if (!_.isEmpty(_mccRead)) {
        // Update form data.
        _formDataRef.current = {
          'countryName': _mccRead?.countryName,
          'dialingCode': _mccRead?.dialingCode,
          'mcc': _mccRead?.mcc
        }

        // Update state.
        return setForceReRender(String.random(8))
      }

      // Report failure.
      return void 0
    }; _Async().catch(i => i)
  }, [passOn, isOpen])

  // Return component.
  return (
    <form onSubmit={_SubmitForm} key={forceReRender}>
      <Flex gap='20px' flexDir='column' w='100%'>
        <Flex flexDir='row' gap='20px'>
          <FormControl isRequired>
            <MemoizedInput
              name='mcc'
              label='Mcc'
              placeholder='e.g. "Mcc"'
              onChange={({ target }) => {
                // Over spreading.
                const { name, value } = target

                // Update form data.
                _formDataRef.current = {
                  ..._formDataRef?.current,
                  [name]: value
                }
              }}
              error={error}
              isInvalid={error?.includes('mcc')}
              isRequired={true}
              data={_formDataRef?.current?.mcc}
            />
          </FormControl>
        </Flex>
        <Flex flexDir='row' gap='20px'>
          <FormControl isRequired>
            <MemoizedInput
              name='countryName'
              label='Country Name'
              placeholder='e.g. "Country Name"'
              onChange={({ target }) => {
                // Over spreading.
                const { name, value } = target

                // Update form data.
                _formDataRef.current = {
                  ..._formDataRef?.current,
                  [name]: value
                }
              }}
              error={error}
              isInvalid={error?.includes('countryName')}
              isRequired={true}
              data={_formDataRef?.current?.countryName}
            />
          </FormControl>
          <FormControl isRequired>
            <MemoizedInput
              name='dialingCode'
              label='Dialing Code'
              placeholder='e.g. "Dialing Code"'
              onChange={({ target }) => {
                // Over spreading.
                const { name, value } = target

                // Update form data.
                _formDataRef.current = {
                  ..._formDataRef?.current,
                  [name]: value
                }
              }}
              error={error}
              isInvalid={error?.includes('dialingCode')}
              isRequired={true}
              data={_formDataRef?.current?.dialingCode}
            />
          </FormControl>
        </Flex>
      </Flex>
      <Flex
        p='10px 0'
        color='rgba(43, 54, 116, 1)'
        fontWeight='700'
        fontSize='20px'
        lineHeight='24px'
        fontFamily='DM Sans'
        justifyContent='flex-end'
        mt='1.5rem'>
        <Button
          variant='basic'
          type='submit'
          minW='150px'>
          {isCreateOnly ? 'Create Mcc' : 'Update Mcc'} {MutationMccCreateResponse.loading || MutationMccUpdateResponse.loading ? <Spinner color='#c1a9f4' size='sm' ml='10px' /> : <IoIosSave size={16} style={{ 'marginLeft': '10px' }} />}
        </Button>
      </Flex>
    </form>
  )
}


/*
 * PROPTYPES
 */
Index.propTypes = {
  'passOn': PropTypes.object,
  'isOpen': PropTypes.bool,
  'isCreateOnly': PropTypes.bool,
  'onClose': PropTypes.func
}


/*
 * REDUX
 */
const _MapStateToProps = __state => ({ 'passOn': __state.PassOn })


/*
 * EXPORT
 */
export default connect(_MapStateToProps)(Index)
