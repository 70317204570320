/*
 * PACKAGES
 */
export const _initialState = {}


/*
 * OBJECTS
 */
const _PassOnReducer = (__prevState, __action) => {
  /*
   * Switch case for handling actions on
   * account store.
   */
  switch (__action.type) {
  case 'PASS_ON_UPDATE':
    // Return combined state.
    return {
      ...__prevState,
      ...__action.PassOn
    }
  case 'PASS_ON_CLEAR':
    // Return combined state.
    return {
      ..._initialState
    }
  default:
    // Return old state.
    return __prevState || _initialState
  }
}


/*
 * EXPORTS
 */
export default _PassOnReducer
