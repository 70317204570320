/*
 * IMPORTS
 */
import ClassName from 'classnames' // Npm import: classnames utility.
import {
  addDays,
  differenceInCalendarDays,
  differenceInCalendarMonths,
  endOfMonth,
  endOfWeek,
  startOfMonth,
  startOfWeek
} from 'date-fns' // Npm import: date-fns functions.


/*
 * EXPORTS
 */
export function findNextRangeIndex(ranges, currentRangeIndex = -1) {
  // Const assignment.
  const nextIndex = ranges.findIndex((__range, i) => i > currentRangeIndex && false !== __range.autoFocus && !__range.disabled)

  // If next index is not -1, return next index.
  if (-1 !== nextIndex) return nextIndex

  // Return value.
  return ranges.findIndex(__range => false !== __range.autoFocus && !__range.disabled)
}
export function generateStyles(sources) {
  // If sources is not an array, return an empty object.
  if (!sources.length) return {}

  // Return value.
  return sources.filter(source => Boolean(source)).reduce((styles, styleSource) => {
    // Object keys iteration.
    Object.keys(styleSource).forEach(r => { styles[r] = ClassName(styles[r], styleSource[r]) })

    // Return value.
    return styles
  }, {})
}
export function getMonthDisplayRange(date, dateOptions, fixedHeight) {
  // Local variable.
  let endDateOfCalendar

  // Const assignment.
  const startDateOfMonth = startOfMonth(date, dateOptions)
  const endDateOfMonth = endOfMonth(date, dateOptions)
  const startDateOfCalendar = startOfWeek(startDateOfMonth, dateOptions)

  // Variable assignment.
  endDateOfCalendar = endOfWeek(endDateOfMonth, dateOptions)

  // If fixed height, add weeks
  // eslint-disable-next-line no-magic-numbers
  if (fixedHeight && 34 >= differenceInCalendarDays(endDateOfCalendar, startDateOfCalendar)) endDateOfCalendar = addDays(endDateOfCalendar, 7)

  return {
    start: startDateOfCalendar,
    end: endDateOfCalendar,
    startDateOfMonth,
    endDateOfMonth
  }
}
export function calcFocusDate(currentFocusedDate, props) {
  // Find primary date according the props
  let targetInterval

  // Destructuring assignment.
  const { shownDate, date, months, ranges, focusedRange, displayMode } = props

  // If date range.
  if ('dateRange' === displayMode) {
    // Const assignment.
    const range = ranges[focusedRange[0]] || {}

    // Variable assignment.
    targetInterval = {
      'start': range.startDate,
      'end': range.endDate
    }
  } else {
    // Variable assignment.
    targetInterval = {
      'start': date,
      'end': date
    }
  }

  // If target interval start, end, and before end.
  targetInterval.start = startOfMonth(targetInterval.start || new Date())
  targetInterval.end = endOfMonth(targetInterval.end || targetInterval.start)

  // If target interval start, end, and before end.
  const targetDate = targetInterval.start || targetInterval.end || shownDate || new Date()

  // Initial focus
  if (!currentFocusedDate) return shownDate || targetDate

  /*
   * Just return targetDate for native scrolled calendars
   * If (props.scroll.enabled) return targetDate;
   */
  if (differenceInCalendarMonths(targetInterval.start, targetInterval.end) > months) return currentFocusedDate

  // If target interval start, end, and before end.
  return targetDate
}
