/*
 * IMPORTS
 */
import { gql } from '@apollo/client' // Npm: Apollo client for handling graphql request.


/*
 * EXPORTS
 */
export default gql`
query VendorAccountReadQuery($vendorAccountId: ID!) {
  VendorAccountRead(vendorAccountId: $vendorAccountId) {
    id,
    displayName,
    Smpp {
        displayName,
        ip,
        username,
        password,
        port,
        isActive,
        sessionTimeoutInterval,
        sessionAllowed,
        tps,
        enquireLinkInterval,
        sourceAddressTon,
        sourceAddressNpi,
        destinationAddressTon,
        destinationAddressNpi
        message,
        status
    }
  }
}
`
