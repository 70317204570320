/*
 * IMPORTS
 */
import { gql } from '@apollo/client' // Npm: Apollo client.


/*
 * EXPORTS
 */
export default gql`
mutation CustomerDelete($customerId: ID!) {
    CustomerDelete(customerId: $customerId) {
        message
        status
    }
} 
`

