/*
 * EXPORTS
 */
export const rowStyle = {
  'paddingTop': '10px',
  'paddingBottom': '10px',
  'fontWeight': '500',
  'fontSize': 'Clamp(13px, 1.5vw, 15px)'
}
export const headerStyle = {
  'fontFamily': 'DM Sans',
  'fontWeight': '600',
  'fontSize': '17px',
  'height': '50px',
  'color': '#FFF',
  'backgroundColor': '#422AFB'
}
export const cellStyle = {
  'fontFamily': 'Dm Sans',
  'color': 'rgba(43, 54, 116, 1)',
  'fontWeight': '700',
  'fontSize': '18px',
  'lineHeight': '24px',
  'letterSpacing': '-0.36px'
}
export const buttonStyle = {
  'backgroundColor': '#422AFB',
  'color': '#FFF',
  'fontFamily': 'Dm Sans',
  'fontWeight': '500',
  'fontSize': '16px',
  'lineHeight': '32px',
  'letterSpacing': '-0.22px',
  'borderRadius': '10px'
}
