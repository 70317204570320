/*
 * IMPORTS
 */
import React from 'react' // Npm: react.js library.
import PropTypes from 'prop-types' // Npm: react.js library.
import JoiBrowser from 'joi-browser' // Npm: Joi for frontend validation.
import _ from 'underscore' // Npm: Underscore.js library.
import { connect } from 'react-redux' // Npm: React Redux for state management.
import { useMutation, useQuery } from '@apollo/client' // Npm: Apollo client.
import { toast } from 'react-hot-toast' // Npm: React hot toast.
import {
  Flex,
  FormControl
} from '@chakra-ui/react' // Npm: Chakra UI components.


/*
 * PACKAGES
 */
import ModalFooter from 'components/ModalFooter'
import { MemoizedInput, MemoizedSelect } from 'components/MemoizedInput'


/*
 * GRAPHS
 */
import SmppAttachContentTranslationMutation from './__mutation__/index.smpp.attachContentTranslation.mutation'
import ContentTranslationCreateMutation from './__mutation__/index.contentTranslation.create.mutation'
import ContentTranslationUpdateMutation from './__mutation__/index.contentTranslation.update.mutation'
import ContentTranslationReadQuery from './__query__/index.contentTranslation.read.query'


/*
 * STYLES
 */
import './index.style.css'


/*
 * OBJECTS
 */
const Index = ({
  isOpen,
  onClose,
  passOn
}) => {
  // Hook assignment.
  const [error, setError] = React.useState('')
  const [regex, setRegex] = React.useState('')
  const [translationKeyword, setTranslationKeyword] = React.useState('')
  const [strategy, setStrategy] = React.useState('')
  const [MutationSmppAttachContentTranslation, MutationSmppAttachContentTranslationResponse] = useMutation(SmppAttachContentTranslationMutation)
  const [MutationContentTranslationCreate, MutationContentTranslationCreateResponse] = useMutation(ContentTranslationCreateMutation)
  const [MutationContentTranslationUpdate, MutationContentTranslationUpdateResponse] = useMutation(ContentTranslationUpdateMutation)
  const _QueryContentTranslationRead = useQuery(ContentTranslationReadQuery, { 'variables': { 'smppId': passOn?.smppId } })

  // Const assignment.
  const _cdId = _QueryContentTranslationRead?.data?.ContentTranslationRead?.id

  // Object assignment.
  const _SubmitForm = async e => {
    // Prevent default behavior.
    e?.preventDefault()

    // Const assignment.
    const _JoiSchema = JoiBrowser.object({
      'regex': JoiBrowser.string().required(),
      'translationKeyword': JoiBrowser.string().required(),
      'strategy': JoiBrowser.string().required()
    }).options({ 'allowUnknown': true })

    // Validate if regex is correct.
    if (!String.isValidRegex(regex)) return new Error('Enter valid regex.')

    // Validate form data.
    const _JoiSchemaValidate = _JoiSchema.validate({ regex, translationKeyword, strategy })

    // If error exists then report failure.
    if (_JoiSchemaValidate.error) return setError(_JoiSchemaValidate.error?.message)

    // Reset error.
    setError('')

    // Create VendorAccount for given Vendor.
    const _MutationContentTranslation = await [_cdId ? MutationContentTranslationUpdate : MutationContentTranslationCreate]?.[0]({
      'variables': {
        [_cdId ? 'contentTranslationId' : 'smppId']: _cdId ? _cdId : passOn?.smppId,
        'regex': regex,
        'translationKeyword': translationKeyword,
        'strategy': strategy
      }
    })

    // If creating or updating CT caught an exception then report failure.
    if (_MutationContentTranslation instanceof Error) return _MutationContentTranslation

    // Style Guide.
    toast(_MutationContentTranslation?.data?.ContentTranslationCreate?.message ?? _MutationContentTranslation?.data?.ContentTranslationUpdate?.message)

    // Attach given vendor with smpp.
    const _MutationSmppAttachContentTranslation = await MutationSmppAttachContentTranslation({ 'variables': { 'contentTranslationId': _MutationContentTranslation?.data[_cdId ? 'ContentTranslationUpdate' : 'ContentTranslationCreate']?.id, 'smppId': passOn?.smppId } })

    // If mutation caught an exception then report failure.
    if (_MutationSmppAttachContentTranslation instanceof Error) return _MutationSmppAttachContentTranslation

    // Only close if attach is successful.
    if ('UPDATE_SUCCESSFUL' === _MutationSmppAttachContentTranslation?.data?.SmppAttachContentTranslation?.status) return onClose?.()

    // Return void 0.
    return void 0
  }

  // Event handler.
  React.useEffect(() => {
    // Update all states if data is available.
    if (!_.isEmpty(_QueryContentTranslationRead?.data?.ContentTranslationRead) && ['READ_SUCCESSFUL', 'UPDATE_SUCCESSFUL'].includes(_QueryContentTranslationRead?.data?.ContentTranslationRead?.status)) {
      // Update all states.
      setRegex(_QueryContentTranslationRead?.data?.ContentTranslationRead?.regex)
      setTranslationKeyword(_QueryContentTranslationRead?.data?.ContentTranslationRead?.translationKeyword)
      setStrategy(_QueryContentTranslationRead?.data?.ContentTranslationRead?.strategy)
    }
  }, [isOpen, _QueryContentTranslationRead?.loading])

  // Return component.
  return (
    <form onSubmit={_SubmitForm}>
      <Flex>
        <Flex gap='20px' flexDir='column' w='100%'>
          <Flex gap='20px'>
            <FormControl>
              <MemoizedInput
                isRequired={true}
                name='regex'
                label='Original Keyword (Regex)'
                placeholder='e.g. "Rocking Smpp"'
                onChange={({ target }) => setRegex(target?.value)}
                isInvalid={error?.includes('regex')}
                error={error}
                data={regex}
              />
            </FormControl>
          </Flex>
          <Flex gap='20px'>
            <FormControl>
              <MemoizedInput
                isRequired={true}
                name='translationKeyword'
                label='Translation Keyword'
                placeholder='e.g. "Rocking Smpp"'
                onChange={({ target }) => setTranslationKeyword(target?.value)}
                isInvalid={error?.includes('translationKeyword')}
                error={error}
                data={translationKeyword}
              />
            </FormControl>
          </Flex>
          <Flex gap='20px'>
            <FormControl>
              <MemoizedSelect
                isRequired={true}
                name='strategy'
                label='Select Match Type'
                placeholder='e.g. "REPLACE"'
                onChange={({ target }) => setStrategy(target?.value)}
                isInvalid={error?.includes('strategy')}
                error={error}
                options={Object.React.App.enums.CONTENT_TRANSLATION_STRATEGY.enums?.map(i => i.key)}
                data={strategy}
              />
            </FormControl>
          </Flex>
        </Flex>
      </Flex>
      <ModalFooter isLoading={MutationContentTranslationCreateResponse.loading || MutationSmppAttachContentTranslationResponse.loading || MutationContentTranslationUpdateResponse.loading} onSubmit={_SubmitForm} />
    </form>
  )
}


/*
 * PROPTYPES
 */
Index.propTypes = {
  'isOpen': PropTypes.bool,
  'onClose': PropTypes.func,
  'passOn': PropTypes.object
}
Index.defaultProps = {}


/*
 * REDUX
 */
const _MapStateToProps = __state => ({ 'passOn': __state.PassOn })


/*
 * EXPORT
 */
export default connect(_MapStateToProps)(Index)
