/*
 * IMPORTS
 */
import { gql } from '@apollo/client' // Npm: Apollo client for handling graphql request.


/*
 * EXPORTS
 */
export default gql`
mutation BlockDestinationAddressDeleteMutation($blockDestinationAddressId: ID!) {
  BlockDestinationAddressDelete(blockDestinationAddressId: $blockDestinationAddressId) {
    id
    message
    status
  }
}
`
