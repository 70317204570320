/*
 * IMPORTS
 */
import { gql } from '@apollo/client' // Npm: Apollo client for handling graphql request.


/*
 * GRAPH
 */
const Index = gql`
query CustomerAccountReadQuery($customerId: ID, $take: PositiveInt, $skip: Int) @live {
  CustomerAccountRead(customerId: $customerId, take: $take, skip: $skip) {
    id,
    message,
    status,
    Smpp {
        id,
        createdAt,
        updatedAt,
        isActive,
        displayName,
        ip,
        port,
        username,
        password,
        sessionTimeoutInterval,
        sessionAllowed,
        tps,
        message,
        status,
    },
        Account {
            email,
           displayName
        }
        _totalCount
  }
} 
`

/*
 * EXPORTS
 */
export default Index
