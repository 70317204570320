/*
 * IMPORTS
 */
import React from 'react' // Npm: react.js library.
import Moment from 'moment' // Npm: Moment library.
import PropTypes from 'prop-types' // Npm: react.js library.
import _ from 'underscore' // Npm: underscore library.
import { connect } from 'react-redux' // Npm: React Redux for state management.
import { useQuery } from '@apollo/client' // Npm: Apollo client.
import { IoMdAdd } from 'react-icons/io' // Npm: React icons.
import {
  Button,
  Flex,
  Table,
  TableContainer,
  Tbody,
  Td,
  Thead,
  Tooltip,
  Tr,
  useDisclosure
} from '@chakra-ui/react' // Npm: Chakra UI components.


/*
 * PACKAGES
 */
import Pagination from 'components/Pagination'
import Modal from 'components/Modal'
import TableSpinner from 'components/TableSpinner'
import MncDropDownOption from 'components/MncDropDownOption'
import MncUpsert from 'components/MncUpsert'
import MncDelete from 'components/MncDelete'


/*
 * GRAPHS
 */
import MncReadQuery from './__query__/index.mnc.read.query'


/*
 * STYLES
 */
import {
  buttonStyle,
  cellStyle,
  headerStyle,
  rowStyle
} from './index.style'


/*
 * OBJECTS
 */
const Index = ({ passOn }) => {
  // Hook assignment.
  const [skipPage, setSkipPage] = React.useState(0)
  const _skipDifference = 8
  const { 'isOpen': isMncCreateOpen, 'onOpen': onMncCreateOpen, 'onClose': onMncCreateClose } = useDisclosure()
  const { 'isOpen': isMncUpdateOpen, 'onOpen': onMncUpdateOpen, 'onClose': onMncUpdateClose } = useDisclosure()
  const { 'isOpen': isMncDeleteOpen, 'onOpen': onMncDeleteOpen, 'onClose': onMncDeleteClose } = useDisclosure()
  const _QueryMncRead = useQuery(MncReadQuery, { 'variables': { 'mccId': passOn?.mccId, 'skip': skipPage * _skipDifference, 'take': _skipDifference } })

  // Return component.
  return (
    <Flex
      fontSize={['Clamp(13px, 1.5vw, 15px)']}
      direction={{ 'base': 'column' }}
      display='flex'
      flex={1}
      overflowX='scroll'>
      <Flex
        display='flex'
        flex={1}
        flexDirection='column'
        gap='1rem'
        bg='white'
        minH='calc(100vh - 160px)'
        maxHeight='100%'
        borderRadius='20px'
        p='20px'>
        <Flex pb='0px' justify='space-between'>
          <Tooltip label='Create New Operator' fontSize='sm'>
            <Button
              w='170px'
              leftIcon={<IoMdAdd />}
              onClick={onMncCreateOpen}
              style={buttonStyle}>
              Create Mnc
            </Button>
          </Tooltip>
        </Flex>
        <TableContainer
          flex={1}
          display='flex'
          overflowY='scroll'
          borderRadius='15px'
          outline='1px solid #C5CFE8'>
          {_QueryMncRead.loading ? (
            <Flex w='100%' h='100%' display='flex' alignItems='center' justifyContent='center'>
              <TableSpinner isLoading={true} />
            </Flex>
          ) : 0 === _QueryMncRead.data?.MncRead?.length || !_.every(_.pluck(_QueryMncRead.data?.MncRead, 'status'), i => 'READ_SUCCESSFUL' === i) ? (
            <Flex w='100%' h='100%' display='flex' alignItems='center' justifyContent='center'>
              <TableSpinner isLoading={false} isEmpty={true} />
            </Flex>
          ) : (
            <Table variant='simple' display='block' size='md'>
              <Thead>
                <Tr style={headerStyle}>
                  <Td style={rowStyle}>S.No</Td>
                  <Td style={rowStyle}>CreatedAt</Td>
                  <Td style={rowStyle}>UpdatedAt</Td>
                  <Td style={rowStyle}>Operator</Td>
                  <Td style={rowStyle}>Mcc</Td>
                  <Td style={rowStyle}>Mnc</Td>
                  <Td style={rowStyle}>Country</Td>
                  <Td style={rowStyle}>Action</Td>
                </Tr>
              </Thead>
              <Tbody style={cellStyle}>
                {_QueryMncRead.data?.MncRead?.map(({ id, createdAt, updatedAt, mnc, network, Mcc }, __index) => (
                  <Tr key={String.random(9)}>
                    <Td
                      style={rowStyle}
                      borderRight='1px solid rgba(216, 227, 252, 1)'>
                      {`${(__index + (_skipDifference * (skipPage))) + 1}.`}
                    </Td>
                    <Td
                      style={rowStyle}
                      borderRight='1px solid rgba(216, 227, 252, 1)'>
                      {Moment(createdAt).format('YYYY-MM-DD hh:mm:ss A')}
                    </Td>
                    <Td
                      style={rowStyle}
                      borderRight='1px solid rgba(216, 227, 252, 1)'>
                      {Moment(updatedAt).format('YYYY-MM-DD hh:mm:ss A')}
                    </Td>
                    <Td
                      style={rowStyle}
                      borderRight='1px solid rgba(216, 227, 252, 1)'>
                      {network ?? '-'}
                    </Td>
                    <Td
                      width='50%'
                      style={rowStyle}
                      borderRight='1px solid rgba(216, 227, 252, 1)'>
                      {Mcc?.mcc ?? '-'}
                    </Td>
                    <Td
                      width='50%'
                      style={rowStyle}
                      borderRight='1px solid rgba(216, 227, 252, 1)'>
                      {mnc ?? '-'}
                    </Td>
                    <Td
                      width='50%'
                      style={rowStyle}
                      borderRight='1px solid rgba(216, 227, 252, 1)'>
                      {Mcc?.countryName ?? '-'}
                    </Td>
                    <Td
                      style={rowStyle}>
                      {' '}
                      {
                        <MncDropDownOption
                          mncId={id}
                          onMncUpdate={onMncUpdateOpen}
                          onMncDelete={onMncDeleteOpen}
                        />
                      }
                    </Td>
                  </Tr>
                ))}
              </Tbody>
            </Table>
          )
          }
        </TableContainer>
      </Flex>
      <Pagination itemsPerPage={_skipDifference} totalCount={_.pluck(_QueryMncRead?.data?.MncRead, '_totalCount')?.[0]} onPageChange={setSkipPage} />
      <Modal
        size='md'
        title='Mnc Create'
        isOpen={isMncCreateOpen}
        onClose={onMncCreateClose}>
        <MncUpsert isCreateOnly={true} />
      </Modal>
      <Modal
        size='md'
        title='Mnc Update'
        isOpen={isMncUpdateOpen}
        onClose={onMncUpdateClose}>
        <MncUpsert />
      </Modal>
      <Modal
        size='md'
        title='Mnc Delete'
        isOpen={isMncDeleteOpen}
        onClose={onMncDeleteClose}>
        <MncDelete />
      </Modal>
    </Flex>
  )
}



/*
 * PROPTYPES
 */
Index.propTypes = {
  'passOn': PropTypes.object,
  'PassOnUpdate': PropTypes.func
}


/*
 * REDUX
 */
const _MapStateToProps = __state => ({ 'passOn': __state.PassOn })
const _MapDispatchToProps = __dispatch => ({ 'PassOnUpdate': __payload => __dispatch({ 'type': 'PASS_ON_UPDATE', 'PassOn': __payload }) })


/*
 * EXPORT
 */
export default connect(_MapStateToProps, _MapDispatchToProps)(Index)

