/*
 * IMPORTS
 */
import { gql } from '@apollo/client' // Npm: Apollo client for handling graphql request.


/*
 * EXPORTS
 */
export default gql`
mutation CustomerAttachRouteMutation ($customerId: ID!, $routeId: ID!) {
  CustomerAttachRoute( customerId: $customerId routeId: $routeId ) {
    message
    status
  }
}
`
