/*
 * IMPORTS
 */
import { gql } from '@apollo/client' // Npm: Apollo client for handling graphql request.


/*
 * GRAPHS
 */
const Index = gql`
query RouteReadQuery($routeId: ID!) {
  RouteRead(routeId: $routeId) {
    id,
    displayName,
    type,
    RoutePlan {
      id,
      isPaused,
      Mcc {
        id,
        mcc,
        Mnc {
          id,
          mnc
        }
      }
      Mnc {
        id,
        network,
        mnc
      }
      Sms {
        id,
        isUnicodeMessage,
        deliveredThrough,
        status
      }
      VendorAccount {
        id,
        Smpp {
          ip,
          port,
        }
      }
    }
  }
}
`


/*
 * EXPORTS
 */
export default Index
