/*
 * IMPORTS
 */
import React from 'react' // Npm: react.js library.
import PropTypes from 'prop-types' // Npm: prop types.
import { HiNoSymbol } from 'react-icons/hi2' // Npm: React icons.
import {
  Flex,
  Spinner,
  Text
} from '@chakra-ui/react' // Npm: Chakra UI components.


/*
 * STYLES
 */
import './index.style.css'


/*
 * OBJECTS
 */
const Index = ({ isLoading, isEmpty }) => isLoading ? (
  <Flex className='nothingFound'>
    <Spinner size='md' color='rgba(141, 151, 182, 1)' />
    <Text>Please wait..</Text>
  </Flex>
) : isEmpty ? (
  <Flex className='nothingFound'>
    <HiNoSymbol size={27} color='rgba(141, 151, 182, 1)' />
    <Text>Nothing Found</Text>
  </Flex>
) : <></>


/*
 * PROPTYPES
 */
Index.propTypes = {
  'isLoading': PropTypes.bool,
  'isEmpty': PropTypes.bool
}


/*
 * EXPORT
 */
export default Index
