/*
 * IMPORTS
 */
import { gql } from '@apollo/client' // Npm: Apollo client for handling graphql request.


/*
 * GRAPHS
 */
const Index = gql`
    query AccountReadQuery($accountId: ID) {
        AccountRead(accountId: $accountId) {
            displayName,
            bio,
            email,
            thumbnailStoredAt,
            profileCoverThumbnailStoredAt,
            isBlocked,
            message,
            status
        }
    }

`


/*
 * EXPORTS
 */
export default Index
