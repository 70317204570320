/*
 * IMPORTS
 */
import React from 'react' // Npm: React.js library.
import PropTypes from 'prop-types' // Npm: Prop Types for checking the props type.
import _ from 'underscore' // Npm: Underscore.js for utility functions.
import { BsCheckAll } from 'react-icons/bs' // Npm: React Icons for icons.
import { useQuery } from '@apollo/client' // Npm: Apollo client.
import { HiCheckCircle, HiPlusCircle, HiXCircle } from 'react-icons/hi2' // Npm: React Icons for icons.
import {
  Checkbox,
  Flex,
  FormControl,
  FormLabel,
  IconButton,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Text
} from '@chakra-ui/react' // Npm: Chakra UI A simple, modular and accessible component library for React.


/*
 * COMPONENTS
 */
import { MemoizedSelect } from 'components/MemoizedInput'


/*
 * GRAPHS
 */
import MccReadQuery from './__query__/index.mcc.read.query'


/*
 * STYLES
 */
import './index.style.css'


/*
 * OBJECTS
 */
const Index = ({ mccValue, mncValue, inValidMnc, disabled, inValidMcc, isOnlySingleCheckAllowed, onChange }) => {
  // Hook assignment.
  const [mcc, setMcc] = React.useState('')
  const [mnc, setMnc] = React.useState([])
  const _QueryMccRead = useQuery(MccReadQuery, { 'variables': { 'take': 5000, 'skip': 0 } })

  // Event handler.
  React.useEffect(() => {
    /*
     * Update selected country if mcc value
     * Is passed in params.
     */
    mccValue && setMcc(mccValue ?? '')
    mncValue && setMnc(mncValue ?? [])
  }, [])

  // Component assignment.
  const OperatorMnc = () => React.useMemo(() => {
    // Const assignment.
    const _selectedTarget = (mcc)?.split('(')[1]?.split(')')[0]
    const _selectedMcc = _QueryMccRead?.data?.MccRead?.filter?.(j => j.mcc.includes(_selectedTarget))
    const _mcc = _selectedMcc?.[0]

    // Return the JSX.
    return (
      <Menu isDisabled={0 === _mcc?.Mnc?.length || disabled} w='100%'>
        <MenuButton
          type='button'
          disabled={0 === _mcc?.Mnc?.length}
          h='45px'
          color={disabled ? '#aaafc8' : void 0}
          px='15px'
          onClick={e => e.stopPropagation()}
          m='0'
          w='100%'
          transition='all 0.2s'
          textAlign='left'
          borderRadius='10px'
          bg={inValidMnc ? 'rgb(255,255,255,0.8)' : 'rgba(163, 174, 208, 0.34)'}
          rightIcon={<BsCheckAll />}
          _hover={{ 'bg': 'rgba(163, 174, 208, 0.34)' }}
          borderColor={inValidMnc ? 'red' : void 0}
          borderWidth={inValidMnc ? '2px' : void 0}
          _expanded={{ 'borderColor': '#7552ff' }}
          _focus={{ 'boxShadow': '#7552ff' }}>
          {(_.isString(mnc) && !_.isEmpty(mnc) ? [mnc] : mnc)?.length ?? 0} Selected
        </MenuButton>
        <MenuList h='250px' overflow='scroll' minW='300px'>
          {_mcc?.Mnc?.map?.(item => (
            <MenuItem closeOnSelect={false} key={String.random(8)}>
              <Checkbox
                value={item.network}
                disabled={0 === _selectedMcc.length}
                name='mnc'
                onChange={() => {
                  // Return updated mcc and mnc.
                  isOnlySingleCheckAllowed && onChange({
                    'mnc': [`(${item.mnc}) ${item?.network}`],
                    'mcc': _mcc?.mcc ?? []
                  })

                  // Update state of mnc.
                  setMnc(j => {
                  /*
                   * If only one selection is allowed
                   * Then clear the array.
                   */
                    if (isOnlySingleCheckAllowed) return [`(${item.mnc}) ${item?.network}`]
                    else if (j.includes(`(${item.mnc}) ${item?.network}`)) return _.without(j, `(${item.mnc}) ${item?.network}`)

                    // Return updated mnc.
                    return [...j, `(${item.mnc}) ${item?.network}`]
                  })
                }}
                isChecked={mnc.includes(`(${item.mnc}) ${item?.network}`)}>
                {`(${item.mnc})`} {item?.network}
              </Checkbox>
            </MenuItem>
          ))}
        </MenuList>
        <Flex position='absolute' right={0} top='32.5px' zIndex={1000}>
          <IconButton
            aria-label='Select All'
            isDisabled={isOnlySingleCheckAllowed ?? disabled}
            _hover={{ 'bg': 'none' }}
            _active={{ 'bg': 'none' }}
            onClick={() => {
              // Const assignment.
              const _mnc = _.map(0 < _mcc?.Mnc?.length ? _mcc.Mnc : [], j => `(${j.mnc}) ${j?.network}`)

              // Update mnc.
              setMnc(_mnc)

              // Return updated mcc and mnc.
              onChange({
                'mnc': _mnc,
                'mcc': _mcc?.mcc ?? []
              })
            }}
            right='-10px'
            bg='none'
            p='0'>
            {mnc?.length === _mcc?.mnc?.length ? (<HiCheckCircle fontSize='20px' color='#c4c4c4' />) : (<HiPlusCircle fontSize='20px' color='#3CA55C' />)}
          </IconButton>
          <IconButton
            aria-label='Clear All'
            _hover={{ 'bg': 'none' }}
            _active={{ 'bg': 'none' }}
            isDisabled={isOnlySingleCheckAllowed ?? disabled}
            onClick={() => {
              // Const assignment.
              setMnc([])

              // Return updated mcc and mnc.
              onChange({ 'mnc': [], 'mcc': '' })
            }}
            bg='none'
            p='0'>
            {0 === mnc?.length ? (<HiXCircle fontSize='20px' color='#c4c4c4' />) : (<HiXCircle fontSize='20px' color='#FF416C' />)}
          </IconButton>
        </Flex>
      </Menu>
    )
  }, [])

  // Return the JSX.
  return (
    <Flex className='mccAndMncSelector' flexDir='column' gap='15px'>
      <FormControl isRequired>
        <FormLabel fontWeight='600' fontSize={['Clamp(13px, 1.5vw, 15px)']}>
          Country Mcc
        </FormLabel>
        <MemoizedSelect
          isDisabled={disabled}
          name='mcc'
          data={mcc}
          fontSize={['Clamp(13px, 1.5vw, 15px)']}
          placeholder='Select Country'
          options={_.compact(_QueryMccRead?.data?.MccRead?.map?.(j => `${j.countryName} (${j.mcc})`))}
          isInvalid={inValidMcc}
          disabled={disabled}
          onChange={__event => {
            // Const assignment.
            const _selectedTarget = __event.target.value?.split('(')[1]?.split(')')[0]
            const _selectedMcc = _QueryMccRead?.data?.MccRead?.filter?.(j => j.mcc.includes(_selectedTarget))

            // Update states.
            setMcc(__event.target.value)
            setMnc([])

            // Return updated mcc and mnc.
            onChange({
              'mnc': [],
              'mcc': _.first(_selectedMcc)?.mcc
            })
          }}
        />
      </FormControl>
      <FormControl flexDir='column'>
        <FormLabel
          fontWeight='600'
          display='flex'
          alignItems='center'
          style={{ pointerEvents: 'none' }}
          fontSize={['Clamp(13px, 1.5vw, 15px)']}>
          Operator Mnc{' '}<Text color='red'>*</Text>
        </FormLabel>
        <OperatorMnc />
      </FormControl>
    </Flex>
  )
}


/*
 * PROPTYPES
 */
Index.propTypes = {
  'mccValue': PropTypes.string,
  'mncValue': PropTypes.array,
  'inValidMnc': PropTypes.bool,
  'disabled': PropTypes.bool,
  'inValidMcc': PropTypes.bool,
  'isOnlySingleCheckAllowed': PropTypes.bool,
  'onChange': PropTypes.func
}


/*
 * EXPORT
 */
export default Index
