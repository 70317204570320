/*
 * IMPORTS
 */
import React from 'react' // Npm: react.js library.
import PropTypes from 'prop-types' // Npm: react.js library.
import JoiBrowser from 'joi-browser' // Npm: Joi for frontend validation.
import _ from 'underscore' // Npm: Underscore.js library.
import { toast } from 'react-hot-toast' // Npm: React hot toast.
import { connect } from 'react-redux' // Npm: React Redux for state management.
import { useLazyQuery, useMutation, useQuery } from '@apollo/client' // Npm: Apollo client.
import {
  Flex,
  FormControl
} from '@chakra-ui/react' // Npm: Chakra UI components.


/*
 * PACKAGES
 */
import ModalFooter from 'components/ModalFooter'
import MccMncSelector from 'components/MccMncSelector'
import { MemoizedInput, MemoizedSelect } from 'components/MemoizedInput'


/*
 * GRAPHS
 */
import RoutePlanReadQuery from './__query__/index.routePlan.read.query'
import RoutePlanUpdateMutation from './__mutation__/index.routePlan.update.mutation'
import RoutePlanCreateMutation from './__mutation__/index.routePlan.create.mutation'
import VendorAccountAttachRoutePlanMutation from './__mutation__/index.vendorAccount.attachRoutePlan.mutation'
import VendorAccountReadQuery from './__query__/index.vendorAccount.read.query'


/*
 * STYLES
 */
import './index.style.css'
import { selectStyle } from './index.style'


/*
 * OBJECTS
 */
const Index = ({
  isOpen,
  isCreateOnly,
  onClose,
  passOn
}) => {
  // Hook assignment.
  const [vendorAccount, setVendorAccount] = React.useState(void 0)
  const [MutationVendorAccountAttachRoutePlan, MutationVendorAccountAttachRoutePlanResponse] = useMutation(VendorAccountAttachRoutePlanMutation)
  const [error, setError] = React.useState('')
  const [forceReRender, setForceReRender] = React.useState('')
  const [QueryRoutePlanRead] = useLazyQuery(RoutePlanReadQuery, { 'variables': { 'routePlanId': passOn?.routePlanId } })
  const [MutationRoutePlanCreate, MutationRoutePlanCreateResponse] = useMutation(RoutePlanCreateMutation)
  const [MutationRoutePlanUpdate, MutationRoutePlanUpdateResponse] = useMutation(RoutePlanUpdateMutation)
  const _QueryVendorAccountRead = useQuery(VendorAccountReadQuery, { 'variables': { 'take': 1000, 'skip': 0 } })
  const _formDataRef = React.useRef({})

  // Object assignment.
  const _AttachVendorAccountToRoutePlan = async __id => {
    // Only Execute if route has data.
    if (!_.isEmpty(vendorAccount) && vendorAccount.includes('(') && vendorAccount.includes(')')) {
      // Const assignment.
      const vendorAccountId = vendorAccount.split(' ')[1].replace('(', '').replace(')', '')

      // Execute mutation.
      const _MutationVendorAccountAttachRoutePlan = await MutationVendorAccountAttachRoutePlan({
        'variables': {
          'routePlanId': __id,
          'vendorAccountId': vendorAccountId
        }
      })

      // If mutation caught an exception then report failure.
      if (_MutationVendorAccountAttachRoutePlan instanceof Error) {
        // Report failure.
        return _MutationVendorAccountAttachRoutePlan
      }

      // Style Guide.
      toast(_.every(_.pluck(_MutationVendorAccountAttachRoutePlan?.data?.VendorAccountAttachRoutePlan, 'status'), i => 'UPDATE_SUCCESSFUL' === i) ? 'Successfully attached the vendor with routePlan' : 'Something went wrong. Please try again after some time.')

      // On successful bind close modal.
      if (_.every(_.pluck(_MutationVendorAccountAttachRoutePlan?.data?.VendorAccountAttachRoutePlan, 'status'), i => 'UPDATE_SUCCESSFUL' === i)) onClose()
    }

    // Report void 0.
    return void 0
  }
  const _SubmitForm = async e => {
    // Local variable.
    let _routePlanIds

    // Prevent default behavior.
    e.preventDefault()

    // Const assignment.
    const _JoiSchema = JoiBrowser.object({
      'mcc': JoiBrowser.string().required(),
      'mnc': JoiBrowser.array().items(JoiBrowser.string().required()).required(),
      'percentage': JoiBrowser.number().greater(0).less(101).optional(),
      'smsType': JoiBrowser.string().required(),
      'vendorAccount': JoiBrowser.string().required()
    }).options({ 'allowUnknown': true })

    // Make sure that mnc is array.
    if (!isCreateOnly && !_.isArray(_formDataRef.current?.mnc)) _formDataRef.current = { ..._formDataRef.current, 'mnc': [(_formDataRef.current?.mnc)?.split('(')[1]?.split(')')[0]] }

    // Remove all keys from _formDataRef.current which are undefined.
    _formDataRef.current = _.pick(_formDataRef.current, _.identity)

    // Validate form data.
    const _JoiSchemaValidate = _JoiSchema.validate(_formDataRef.current)

    // If error exists then report failure.
    if (_JoiSchemaValidate.error) return setError(_JoiSchemaValidate.error?.message)

    /*
     * Update _formDataRef with routePlanId
     * if it doesn't exist and also make sure that mode
     * is not create only.
     */
    if (!isCreateOnly && _.isEmpty(_formDataRef.current?.routePlanId)) _formDataRef.current = { ..._formDataRef.current, 'routePlanId': passOn?.routePlanId }
    if (isCreateOnly && _.isEmpty(_formDataRef.current?.routeId)) _formDataRef.current = { ..._formDataRef.current, 'routeId': passOn?.routeId }

    /*
     * Update _formDataRef with input and routePlan
     * if it doesn't exist. Also make sure that mode is
     * not create only.
     */
    if (_.isEmpty(_formDataRef.current?.input)) _formDataRef.current = ({ 'input': [_formDataRef.current] })

    // Execute update mutation.
    const _MutationRoutePlanUpdate = await [isCreateOnly ? MutationRoutePlanCreate : MutationRoutePlanUpdate]?.[0]({ 'variables': {
      'routePlanId': isCreateOnly ? '' : passOn?.routePlanId,
      'input': _.pick(_formDataRef.current, 'input').input.map(j => ({ 'mcc': j?.mcc,
        'mnc': j?.mnc,
        'percentage': j?.percentage,
        'smsType': j?.smsType,
        ...(isCreateOnly ? { 'routeId': passOn?.routeId } : {}) }))
    } })

    // If mutation caught an exception then report failure.
    if (_MutationRoutePlanUpdate instanceof Error) return _MutationRoutePlanUpdate

    // If Update contains data then update routePlanIds.
    if (!_.isEmpty(_MutationRoutePlanUpdate?.data?.RoutePlanUpdate)) _routePlanIds = _.compact(_.pluck(_MutationRoutePlanUpdate?.data?.RoutePlanUpdate, 'id'))
    if (!_.isEmpty(_MutationRoutePlanUpdate?.data?.RoutePlanCreate)) _routePlanIds = _.compact(_.pluck(_MutationRoutePlanUpdate?.data?.RoutePlanCreate, 'id'))

    // Attach vendor account and route to customer.
    !_.isEmpty(_routePlanIds) && _.isArray(_routePlanIds) && await _AttachVendorAccountToRoutePlan(_routePlanIds)

    // Check if create was successful.
    if (!_.isEmpty(_MutationRoutePlanUpdate?.data?.RoutePlanCreate) && _.every(_.pluck(_MutationRoutePlanUpdate?.data?.RoutePlanCreate, 'status') ?? [], j => 'CREATE_SUCCESSFUL' === j)) {
      // Style guide.
      toast('Successfully created the route plan.')

      // On successful create close modal.
      return onClose?.(_MutationRoutePlanUpdate?.data?.RoutePlanCreate)
    }
    if (!_.isEmpty(_MutationRoutePlanUpdate?.data?.RoutePlanUpdate) && _.every(_.pluck(_MutationRoutePlanUpdate?.data?.RoutePlanUpdate, 'status') ?? [], j => 'UPDATE_SUCCESSFUL' === j)) {
      // Style guide.
      toast('Successfully created the route plan.')

      // Report failure.
      return onClose?.(_MutationRoutePlanUpdate?.data?.RoutePlanUpdate)
    }

    // Style Guide.
    toast(_.isEmpty(_MutationRoutePlanUpdate?.data?.RoutePlanCreate) ? _.pluck(_MutationRoutePlanUpdate?.data?.RoutePlanUpdate, 'message')?.join(' ') : _.pluck(_MutationRoutePlanUpdate?.data?.RoutePlanCreate, 'message')?.join(' '))


    // Report void.
    return void 0
  }

  // Event handler.
  React.useEffect(() => {
    // _Async handler.
    const _Async = async () => {
      // Const assignment.
      const _QueryRoutePlanReadQuery = await QueryRoutePlanRead({ 'variables': { 'routePlanId': isCreateOnly ? 'UN_KNOWN' : passOn?.routePlanId } })

      console.log(_QueryRoutePlanReadQuery)
      // If query caught an exception then report failure.
      if (_QueryRoutePlanReadQuery instanceof Error) return _QueryRoutePlanReadQuery

      /*
       * If routePlan details fetch complete then
       * update its value.
       */
      if (_QueryRoutePlanReadQuery?.data?.RoutePlanRead) {
        // Update form data.
        _formDataRef.current = {
          'routePlanId': passOn?.routePlanId,
          'createdAt': _.first(_QueryRoutePlanReadQuery?.data?.RoutePlanRead)?.createdAt,
          'updatedAt': _.first(_QueryRoutePlanReadQuery?.data?.RoutePlanRead)?.updatedAt,
          'mcc': _.first(_QueryRoutePlanReadQuery?.data?.RoutePlanRead)?.Mcc?.mcc,
          'mnc': _.first(_QueryRoutePlanReadQuery?.data?.RoutePlanRead)?.Mnc?.mnc ? `(${_.first(_QueryRoutePlanReadQuery?.data?.RoutePlanRead)?.Mnc?.mnc}) ${_.first(_QueryRoutePlanReadQuery?.data?.RoutePlanRead)?.Mnc?.network}` : void 0,
          'countryName': _.first(_QueryRoutePlanReadQuery?.data?.RoutePlanRead)?.Mcc?.countryName,
          'percentage': _.first(_QueryRoutePlanReadQuery?.data?.RoutePlanRead)?.percentage,
          'isPaused': _.first(_QueryRoutePlanReadQuery?.data?.RoutePlanRead)?.isPaused,
          'smsType': _.first(_QueryRoutePlanReadQuery?.data?.RoutePlanRead)?.smsType,
          'vendorAccount': vendorAccount ?? 0 < _.first(_QueryRoutePlanReadQuery?.data?.RoutePlanRead)?.VendorAccount?.length ? `${_.first(_.first(_QueryRoutePlanReadQuery?.data?.RoutePlanRead)?.VendorAccount)?.displayName} (${_.first(_.first(_QueryRoutePlanReadQuery?.data?.RoutePlanRead)?.VendorAccount)?.id})` : void 0
        }

        setEditable(true)

        // Update state.
        return setForceReRender(String.random(8))
      }

      // Report failure.
      return void 0
    } ;_Async().catch(i => i)
  }, [passOn, isOpen, _QueryVendorAccountRead])

  console.log('x-s-===>>', _formDataRef)

  // Return component.
  return (
    <form onSubmit={_SubmitForm} key={forceReRender}>
      <Flex>
        <Flex gap='20px' flexDir='column' w='100%'>
          <Flex width='100%' gap='20px'>
            <FormControl isRequired>
              <MccMncSelector
                mccValue={_formDataRef?.current?.countryName && _formDataRef?.current?.mcc ? `${_formDataRef?.current?.countryName} (${_formDataRef?.current?.mcc})` : _formDataRef?.current?.mcc}
                mncValue={_formDataRef?.current?.mnc}
                inValidMnc={error?.includes('mnc')}
                inValidMcc={error?.includes('mcc')}
                isOnlySingleCheckAllowed={!isCreateOnly}
                onChange={i => {
                  // Only update mnc if it is available.
                  if (i.mnc) i.mnc = i.mnc.map(e => e.split('(')[1].split(')')[0])

                  // Update form data.
                  _formDataRef.current = ({ ..._formDataRef.current, 'mnc': i.mnc, 'mcc': i.mcc })
                }}
              />
            </FormControl>
          </Flex>
          <Flex width='100%' gap='20px'>
            <FormControl isRequired>
              <MemoizedInput
                name='percentage'
                label='Load Percentage %'
                placeholder='Enter Percentage'
                onChange={({ target }) => {
                  // Over spreading.
                  const { name, value } = target

                  // Update form data.
                  _formDataRef.current = {
                    ..._formDataRef?.current,
                    [name]: parseFloat(value)
                  }
                }}
                error={error}
                isInvalid={error?.includes('percentage')}
                data={_formDataRef?.current?.percentage}
              />
            </FormControl>
            <FormControl isRequired>
              <MemoizedSelect
                name='smsType'
                label='SMS Type'
                placeholder='Enter SMS Type'
                onChange={({ target }) => {
                  // Over spreading.
                  const { name, value } = target

                  // Update form data.
                  _formDataRef.current = {
                    ..._formDataRef?.current,
                    [name]: value
                  }
                }}
                error={error}
                isRequired={true}
                isInvalid={error?.includes('smsType')}
                data={_formDataRef?.current?.smsType}
                options={Object.React.App.enums.SMS_TYPE.enums?.map(i => i.key)}
              />
            </FormControl>
          </Flex>
          <Flex width='100%'>
            <FormControl>
              <MemoizedSelect
                style={selectStyle}
                name='vendorAccount'
                label='Vendor Account'
                placeholder='e.g. "Awesome Vendor Account"'
                data={_formDataRef?.current?.vendorAccount}
                isRequired={true}
                error={error}
                isInvalid={error?.includes('vendorAccount')}
                onChange={({ target }) => {
                  // Over spreading.
                  const { name, value } = target

                  // Update form data.
                  _formDataRef.current = {
                    ..._formDataRef?.current,
                    [name]: value
                  }

                  // Update vendorAccount.
                  setVendorAccount(value)
                }}
                options={_QueryVendorAccountRead?.data?.VendorAccountRead?.map(i => `${i.displayName} (${i.id})`)}
              />
            </FormControl>
          </Flex>
        </Flex>
      </Flex>
      <ModalFooter isLoading={MutationVendorAccountAttachRoutePlanResponse.loading || MutationRoutePlanUpdateResponse.loading || MutationRoutePlanCreateResponse.loading} onSubmit={_SubmitForm} />
    </form>
  )
}


/*
 * PROPTYPES
 */
Index.propTypes = {
  'isCreateOnly': PropTypes.bool,
  'isOpen': PropTypes.bool,
  'onClose': PropTypes.func,
  'passOn': PropTypes.object,
  'submitRef': PropTypes.object,
  'disabled': PropTypes.bool,
  'isUpsertMixerContext': PropTypes.bool,
  'setSubmitIsLoading': PropTypes.func
}


/*
 * REDUX
 */
const _MapStateToProps = __state => ({ 'passOn': __state.PassOn })


/*
 * EXPORT
 */
export default connect(_MapStateToProps)(Index)
