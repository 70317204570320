/*
 * IMPORTS
 */
import React from 'react' // Npm: react.js library.
import PropTypes from 'prop-types' // Npm: react.js library.
import JoiBrowser from 'joi-browser' // Npm: Joi for frontend validation.
import _ from 'underscore' // Npm: Underscore.js library.
import { toast } from 'react-hot-toast' // Npm: React hot toast.
import { connect } from 'react-redux' // Npm: React Redux for state management.
import { useLazyQuery, useMutation, useQuery } from '@apollo/client' // Npm: Apollo client.
import {
  Flex,
  FormControl
} from '@chakra-ui/react' // Npm: Chakra UI components.



/*
 * PACKAGES
 */
import ModalFooter from 'components/ModalFooter'
import { MemoizedInput, MemoizedSelect } from 'components/MemoizedInput'


/*
 * GRAPHS
 */
import CustomerReadQuery from './__query__/index.customer.read.query'
import BlockDestinationAddressReadQuery from './__query__/index.blockDestinationAddress.read.query'
import BlockDestinationAddressUpsertMutation from './__mutation__/index.blockDestinationAddress.upsert.mutation'


/*
 * OBJECTS
 */
const Index = ({
  isOpen,
  onClose,
  passOn
}) => {
  // Hook assignment.
  const [selectedCustomer, setSelectedCustomer] = React.useState('')
  const [MutationBlockDestinationAddressUpsert, MutationBlockDestinationAddressUpsertResponse] = useMutation(BlockDestinationAddressUpsertMutation)
  const [QueryBlockDestinationAddressRead] = useLazyQuery(BlockDestinationAddressReadQuery)
  const [error, setError] = React.useState('')
  const [forceReRender, setForceReRender] = React.useState('')
  const _QueryCustomerRead = useQuery(CustomerReadQuery, { 'variables': { 'take': 1000, 'skip': 0 } })
  const _formDataRef = React.useRef({})

  // Object assignment.
  const _SubmitForm = async e => {
    // Prevent default behavior.
    e?.preventDefault()

    // Const assignment.
    const _JoiSchema = JoiBrowser.object({
      'phone': JoiBrowser.string().required(),
      'customerId': JoiBrowser.string().required(),
      'reason': JoiBrowser.string().required()
    }).options({ 'allowUnknown': true })

    // Remove all keys from _formDataRef.current which are undefined.
    _formDataRef.current = _.pick(_formDataRef.current, _.identity)

    // Validate form data.
    const _JoiSchemaValidate = _JoiSchema.validate(_formDataRef.current)

    // If error exists then report failure.
    if (_JoiSchemaValidate.error) return setError(_JoiSchemaValidate.error?.message)

    // Reset error.
    setError('')

    // Create Block for given Customer on given mobile number.
    const _MutationBlockDestinationAddressUpsert = await MutationBlockDestinationAddressUpsert({
      'variables': {
        'blockDestinationAddressId': passOn?.blockDestinationAddressId,
        ..._formDataRef?.current
      }
    })

    // If creating or updating block account caught an exception then report failure.
    if (_MutationBlockDestinationAddressUpsert instanceof Error) return _MutationBlockDestinationAddressUpsert

    // Style Guide.
    toast(_MutationBlockDestinationAddressUpsert?.data?.BlockDestinationAddressUpsert?.message)

    console.log('x-------eee', _MutationBlockDestinationAddressUpsert?.data?.BlockDestinationAddressUpsert?.status)

    // On Successful response from the mutation.
    if ('UPSERT_SUCCESSFUL' === _MutationBlockDestinationAddressUpsert?.data?.BlockDestinationAddressUpsert?.status || 'PHONE_ALREADY_BLOCKED' === _MutationBlockDestinationAddressUpsert?.data?.BlockDestinationAddressUpsert?.status) onClose?.()

    // Return void 0.
    return void 0
  }

  // Event handler.
  React.useEffect(() => {
    // _Async handler.
    const _Async = async () => {
      // Const assignment.
      const _QueryBlockDestinationAddressReadQuery = await QueryBlockDestinationAddressRead({ 'variables': { 'blockDestinationAddressId': passOn?.blockDestinationAddressId } })

      // If query caught an exception then report failure.
      if (_QueryBlockDestinationAddressReadQuery instanceof Error) return _QueryBlockDestinationAddressReadQuery

      // Const assignment.
      const _data = _.first(_QueryBlockDestinationAddressReadQuery?.data?.BlockDestinationAddressRead ?? [])

      /*
       * If smpp details fetch complete then
       * update its value.
       */
      if (!_.isEmpty(_data)) {
        // Update form data.
        _formDataRef.current = {
          'customerId': _data?.Customer?.id ?? void 0,
          'phone': _data.phone,
          'reason': _data.reason
        }

        // Update state.
        setSelectedCustomer(`${_data?.Customer?.displayName}(${_data?.Customer?.id})`)

        // Update state.
        return setForceReRender(String.random(8))
      }

      // Report failure.
      return void 0
    }; _Async().catch(i => i)
  }, [passOn, isOpen])

  // Return component.
  return (
    <form className='blockDestinationAddressUpsert' onSubmit={_SubmitForm} key={forceReRender}>
      <Flex>
        <Flex gap='20px' flexDir='column' w='100%'>
          <Flex gap='20px'>
            <FormControl>
              <MemoizedInput
                isRequired={true}
                name='phone'
                label='Phone Number'
                placeholder='e.g. "918826668515"'
                onChange={({ target }) => {
                  // Over spreading.
                  const { name, value } = target

                  // Update form data.
                  _formDataRef.current = {
                    ..._formDataRef?.current,
                    [name]: value
                  }
                }}
                isInvalid={error?.includes('phone')}
                error={error}
                data={_formDataRef?.current?.phone}
              />
            </FormControl>
            <FormControl>
              <MemoizedSelect
                name='customerId'
                label='Customer Id'
                placeholder='e.g. "Super Star"'
                onChange={({ target }) => {
                  // Over spreading.
                  const { name, value } = target

                  // Update form data.
                  _formDataRef.current = {
                    ..._formDataRef?.current,
                    [name]: value.split('(')[1].replace(')', '')
                  }

                  // Update state.
                  return setSelectedCustomer(value)
                }}
                isRequired={true}
                error={error}
                options={_QueryCustomerRead?.data?.CustomerRead?.map(i => `${i?.displayName}(${i.id})`)}
                isInvalid={error?.includes('customerId')}
                data={selectedCustomer}
              />
            </FormControl>
          </Flex>
          <Flex gap='20px'>
            <FormControl>
              <MemoizedInput
                isRequired={true}
                name='reason'
                label='Reason'
                placeholder='e.g. "Spamming or Fraud"'
                onChange={({ target }) => {
                  // Over spreading.
                  const { name, value } = target

                  // Update form data.
                  _formDataRef.current = {
                    ..._formDataRef?.current,
                    [name]: value
                  }
                }}
                isInvalid={error?.includes('reason')}
                error={error}
                data={_formDataRef?.current?.reason}
              />
            </FormControl>
          </Flex>
        </Flex>
      </Flex>
      <ModalFooter isLoading={MutationBlockDestinationAddressUpsertResponse.loading} onSubmit={_SubmitForm} />
    </form>
  )
}


/*
 * PROPTYPES
 */
Index.propTypes = {
  'passOn': PropTypes.object,
  'onClose': PropTypes.func,
  'isCreateOnly': PropTypes.bool,
  'isOpen': PropTypes.bool
}
Index.defaultProps = {}


/*
 * REDUX
 */
const _MapStateToProps = __state => ({ 'passOn': __state.PassOn })


/*
 * EXPORT
 */
export default connect(_MapStateToProps)(Index)
