/*
 * IMPORTS
 */
import React from 'react' // Npm: react.js library.
import Moment from 'moment' // Npm: Moment library.
import PropTypes from 'prop-types' // Npm: react.js library.
import _ from 'underscore' // Npm: underscore library.
import { connect } from 'react-redux' // Npm: React Redux for state management.
import { useQuery } from '@apollo/client' // Npm: Apollo client.
import { IoMdAdd } from 'react-icons/io' // Npm: React icons.
import {
  Button,
  Flex,
  Table,
  TableContainer,
  Tbody,
  Td,
  Thead,
  Tooltip,
  Tr,
  useDisclosure
} from '@chakra-ui/react' // Npm: Chakra UI components.


/*
 * PACKAGES
 */
import Pagination from 'components/Pagination'
import Modal from 'components/Modal'
import TableSpinner from 'components/TableSpinner'
import OperatorPrefixDropDownOption from 'components/OperatorPrefixDropDownOption'
import OperatorPrefixUpsert from 'components/OperatorPrefixUpsert'
import OperatorPrefixDelete from 'components/OperatorPrefixDelete'


/*
 * GRAPHS
 */
import OperatorPrefixReadQuery from './__query__/index.operatorPrefix.read.query'


/*
 * STYLES
 */
import {
  buttonStyle,
  cellStyle,
  headerStyle,
  rowStyle
} from './index.style'


/*
 * OBJECTS
 */
const Index = ({ passOn }) => {
  // Hook assignment.
  const [skipPage, setSkipPage] = React.useState(0)
  const _skipDifference = 8
  const { 'isOpen': isOperatorPrefixCreateOpen, 'onOpen': onOperatorPrefixCreateOpen, 'onClose': onOperatorPrefixCreateClose } = useDisclosure()
  const { 'isOpen': isOperatorPrefixUpdateOpen, 'onOpen': onOperatorPrefixUpdateOpen, 'onClose': onOperatorPrefixUpdateClose } = useDisclosure()
  const { 'isOpen': isOperatorPrefixDeleteOpen, 'onOpen': onOperatorPrefixDeleteOpen, 'onClose': onOperatorPrefixDeleteClose } = useDisclosure()
  const _QueryOperatorPrefixRead = useQuery(OperatorPrefixReadQuery, { 'variables': { 'mncId': passOn?.mncId, 'skip': skipPage * _skipDifference, 'take': _skipDifference } })

  // Return component.
  return (
    <Flex
      fontSize={['Clamp(13px, 1.5vw, 15px)']}
      direction={{ 'base': 'column' }}
      display='flex'
      flex={1}
      overflowX='scroll'>
      <Flex
        display='flex'
        flex={1}
        flexDirection='column'
        gap='1rem'
        bg='white'
        minH='calc(100vh - 160px)'
        maxHeight='100%'
        borderRadius='20px'
        p='20px'>
        <Flex pb='0px' justify='space-between'>
          <Tooltip label='Create New Operator' fontSize='sm'>
            <Button
              leftIcon={<IoMdAdd />}
              onClick={onOperatorPrefixCreateOpen}
              style={buttonStyle}>
              Create Operator Prefix
            </Button>
          </Tooltip>
        </Flex>
        <TableContainer
          flex={1}
          display='flex'
          overflowY='scroll'
          borderRadius='15px'
          outline='1px solid #C5CFE8'>
          {_QueryOperatorPrefixRead.loading ? (
            <Flex w='100%' h='100%' display='flex' alignItems='center' justifyContent='center'>
              <TableSpinner isLoading={true} />
            </Flex>
          ) : 0 === _QueryOperatorPrefixRead.data?.OperatorPrefixRead?.length || !_.every(_.pluck(_QueryOperatorPrefixRead.data?.OperatorPrefixRead, 'status'), i => 'READ_SUCCESSFUL' === i) ? (
            <Flex w='100%' h='100%' display='flex' alignItems='center' justifyContent='center'>
              <TableSpinner isLoading={false} isEmpty={true} />
            </Flex>
          ) : (
            <Table variant='simple' display='block' size='md'>
              <Thead>
                <Tr style={headerStyle}>
                  <Td style={rowStyle}>S.No</Td>
                  <Td style={rowStyle}>CreatedAt</Td>
                  <Td style={rowStyle}>UpdatedAt</Td>
                  <Td style={rowStyle}>Operator Name</Td>
                  <Td style={rowStyle}>Operator Prefix</Td>
                  <Td style={rowStyle}>Mnc</Td>
                  <Td style={rowStyle}>Action</Td>
                </Tr>
              </Thead>
              <Tbody style={cellStyle}>
                {_QueryOperatorPrefixRead.data?.OperatorPrefixRead?.map(({ id, createdAt, updatedAt, prefix, Mnc }, __index) => (
                  <Tr key={String.random(9)}>
                    <Td
                      style={rowStyle}
                      borderRight='1px solid rgba(216, 227, 252, 1)'>
                      {`${(__index + (_skipDifference * (skipPage))) + 1}.`}
                    </Td>
                    <Td
                      style={rowStyle}
                      borderRight='1px solid rgba(216, 227, 252, 1)'>
                      {Moment(createdAt).format('YYYY-MM-DD hh:mm:ss A')}
                    </Td>
                    <Td
                      style={rowStyle}
                      borderRight='1px solid rgba(216, 227, 252, 1)'>
                      {Moment(updatedAt).format('YYYY-MM-DD hh:mm:ss A')}
                    </Td>
                    <Td
                      width='50%'
                      style={rowStyle}
                      borderRight='1px solid rgba(216, 227, 252, 1)'>
                      {Mnc?.network ?? '-'}
                    </Td>
                    <Td
                      style={rowStyle}
                      borderRight='1px solid rgba(216, 227, 252, 1)'>
                      {prefix ?? '-'}
                    </Td>
                    <Td
                      width='50%'
                      style={rowStyle}
                      borderRight='1px solid rgba(216, 227, 252, 1)'>
                      {Mnc?.mnc ?? '-'}
                    </Td>
                    <Td
                      style={rowStyle}>
                      {' '}
                      {
                        <OperatorPrefixDropDownOption
                          operatorPrefixId={id}
                          onOperatorPrefixUpdate={onOperatorPrefixUpdateOpen}
                          onOperatorPrefixDelete={onOperatorPrefixDeleteOpen}
                        />
                      }
                    </Td>
                  </Tr>
                ))}
              </Tbody>
            </Table>
          )
          }
        </TableContainer>
      </Flex>
      <Pagination itemsPerPage={_skipDifference} totalCount={_.pluck(_QueryOperatorPrefixRead?.data?.OperatorPrefixRead, '_totalCount')?.[0]} onPageChange={setSkipPage} />
      <Modal
        size='md'
        title='Operator Prefix Create'
        isOpen={isOperatorPrefixCreateOpen}
        onClose={onOperatorPrefixCreateClose}>
        <OperatorPrefixUpsert isCreateOnly={true} />
      </Modal>
      <Modal
        size='md'
        title='Operator Prefix Update'
        isOpen={isOperatorPrefixUpdateOpen}
        onClose={onOperatorPrefixUpdateClose}>
        <OperatorPrefixUpsert />
      </Modal>
      <Modal
        size='md'
        title='Operator Prefix Delete'
        isOpen={isOperatorPrefixDeleteOpen}
        onClose={onOperatorPrefixDeleteClose}>
        <OperatorPrefixDelete />
      </Modal>
    </Flex>
  )
}



/*
 * PROPTYPES
 */
Index.propTypes = {
  'passOn': PropTypes.object,
  'PassOnUpdate': PropTypes.func
}


/*
 * REDUX
 */
const _MapStateToProps = __state => ({ 'passOn': __state.PassOn })
const _MapDispatchToProps = __dispatch => ({ 'PassOnUpdate': __payload => __dispatch({ 'type': 'PASS_ON_UPDATE', 'PassOn': __payload }) })


/*
 * EXPORT
 */
export default connect(_MapStateToProps, _MapDispatchToProps)(Index)

