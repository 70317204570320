/*
 * IMPORTS
 */
import 'nprogress/nprogress.css' // Npm: Progress bar.
import React from 'react' // Npm: React.js library.
import PropTypes from 'prop-types' // Npm: React.js prop types for type checking.
import NProgress from 'nprogress' // Npm: Progress bar.
import _ from 'underscore' // Npm: Utility module.
import { Route, Switch, useHistory, useLocation } from 'react-router-dom' // Npm: React router dom library.
import { connect } from 'react-redux' // Npm: react native redux library.
import { Toaster } from 'react-hot-toast' // Npm: React hot toast.
import { ApolloProvider, useMutation, useQuery, useSubscription } from '@apollo/client' // Npm: Apollo client for handling graphql.
import { Box, Portal, SimpleGrid, useDisclosure, useTheme } from '@chakra-ui/react' // Npm: A simple, modular and accessible component library for React.js.
import { HiArrowUturnUp, HiBuildingStorefront, HiChartBar, HiChatBubbleBottomCenterText, HiChatBubbleLeftEllipsis, HiCurrencyDollar, HiDocumentArrowDown, HiDocumentArrowUp, HiDocumentChartBar, HiHome, HiNoSymbol, HiOutlineArrowPathRoundedSquare, HiOutlineArrowUturnDown, HiSquaresPlus, HiUser, HiUserCircle, HiUserPlus } from 'react-icons/hi2' // Npm: React icons.


/*
 * PACKAGES
 */
import SideBarNavigation from 'components/SideBar'
import NavigationBar from 'components/navbar'
import DlrPercentage from 'components/DlrPercentage'
import TotalSmsDelivered from 'components/TotalSmsDelivered'
import NewCustomer from 'components/NewCustomer'
import TotalSmsSent from 'components/TotalSmsSent'


/*
 * APOLLO
 */
import { ApolloClientProvider } from './index.apollo.client'


/*
 * STYLES
 */
import 'mapbox-gl/dist/mapbox-gl.css'


/*
 * SCREENS
 */
import Page404 from './pages/404'
import Login from './pages/Login'
import PageDashboard from './pages/Dashboard'
import ToolsVendor from './pages/Tools/Vendor'
import ToolsVendorAccount from './pages/Tools/Vendor/Account'
import ToolsBlockNumber from './pages/Tools/BlockNumber'
import ToolsRoute from './pages/Tools/Route'
import ToolsRoutePlan from './pages/Tools/Route/Plan'
import ToolsNetworkMcc from './pages/Tools/MccMnc/Mcc'
import ToolsNetworkMccMnc from './pages/Tools/MccMnc/Mcc/Mnc'
import ToolsNetworkMccMncOperatorPrefix from './pages/Tools/MccMnc/Mcc/Mnc/OperatorPrefix'
import Customer from './pages/Customer'
import CustomerAccount from './pages/Customer/Account'
import AdminAccount from './pages/Account'
import ReportsMessage from './pages/Reports/Message'
import ReportsVendor from './pages/Reports/Vendor'
import ReportsCustomer from './pages/Reports/Customer'
import ReportsBilled from './pages/Reports/Billed'


/*
 * GRAPHS
 */
import AccountReadSubscription from './__subscription__/index.account.read.subscription'
import AccountLogoutMutation from './__mutation__/index.account.logout.mutation'
import AccountReadQuery from './__query__/index.account.read.query'


/*
 * OBJECTS
 */
const Index = ({ account, AccountUpdate, AccountLogout, ClearEverything, ...props }) => {
  // Const assignment.
  const _routes = [
    {
      'name': 'Dashboard',
      'path': '/',
      'icon': <HiHome size={21} />,
      'collapse': false,
      'component': PageDashboard
    },
    {
      'name': 'Login',
      'path': '/login',
      'icon': <></>,
      'collapse': false,
      'hide': true,
      'noLayout': true,
      'component': Login
    },
    {
      'name': 'Users',
      'path': '/customer/mapping',
      'icon': <HiUserCircle size={21} />,
      'collapse': false,
      'component': Customer,
      'items': [
        {
          'name': 'Connect Mapping',
          'path': '/customer/mapping/connect',
          'icon': <HiChatBubbleLeftEllipsis size={21} />,
          'collapse': false,
          'hide': true,
          'component': CustomerAccount
        }
      ]
    },
    {
      'name': 'IAM Roles',
      'path': '/admin/account',
      'icon': <HiUserPlus size={21} />,
      'collapse': false,
      'component': AdminAccount
    },
    {
      'name': 'Reports',
      'path': '/reports',
      'icon': <HiDocumentChartBar size={21} />,
      'collapse': true,
      'items': [
        {
          'name': 'Message Reports',
          'path': '/reports/message',
          'icon': <HiChatBubbleBottomCenterText size={21} />,
          'collapse': false,
          'component': ReportsMessage
        },
        {
          'name': 'User Reports',
          'path': '/reports/user',
          'icon': <HiDocumentArrowDown size={21} />,
          'collapse': false,
          'component': ReportsCustomer
        },
        {
          'name': 'Connect Reports',
          'path': '/reports/connect',
          'icon': <HiDocumentArrowUp size={21} />,
          'collapse': false,
          'component': ReportsVendor
        },
        {
          'name': 'Billed Reports',
          'path': '/reports/billed',
          'icon': <HiCurrencyDollar size={21} />,
          'collapse': false,
          'component': ReportsBilled
        }
      ]
    },
    {
      'name': 'Tools',
      'path': '/tools',
      'icon': <HiSquaresPlus size={21} />,
      'collapse': true,
      'items': [
        {
          'name': 'Block Number',
          'path': '/tools/block/number',
          'icon': <HiNoSymbol size={21} />,
          'collapse': false,
          'component': ToolsBlockNumber
        },
        {
          'name': 'Vendor Mapping',
          'path': '/tools/vendor/mapping',
          'icon': <HiBuildingStorefront size={21} />,
          'collapse': false,
          'component': ToolsVendor,
          'items': [
            {
              'name': 'Connect Mapping',
              'path': '/tools/vendor/mapping/connect',
              'icon': <HiArrowUturnUp size={21} />,
              'collapse': false,
              'hide': true,
              'component': ToolsVendorAccount
            }
          ]
        },
        {
          'name': 'Route Mapping',
          'path': '/tools/route/mapping',
          'icon': <HiOutlineArrowPathRoundedSquare size={21} />,
          'collapse': false,
          'component': ToolsRoute,
          'items': [
            {
              'name': 'Route Mapping',
              'path': '/tools/route/mapping/connect',
              'icon': <HiOutlineArrowUturnDown size={21} />,
              'collapse': false,
              'hide': true,
              'component': ToolsRoutePlan
            }
          ]
        },
        {
          'name': 'Network Mapping',
          'path': '/tools/network',
          'icon': <HiChartBar size={21} />,
          'collapse': false,
          'component': ToolsNetworkMcc,
          'items': [
            {
              'name': 'Mcc Mapping',
              'path': '/tools/network/mcc',
              'icon': <HiChartBar size={21} />,
              'collapse': false,
              'hide': true,
              'component': ToolsNetworkMcc,
              'items': [
                {
                  'name': 'Mnc Mapping',
                  'path': '/tools/network/mcc/mnc',
                  'icon': <HiChartBar size={21} />,
                  'collapse': false,
                  'hide': true,
                  'component': ToolsNetworkMccMnc,
                  'items': [
                    {
                      'name': 'Operator Prefix',
                      'path': '/tools/network/mcc/mnc/prefix',
                      'icon': <HiChartBar size={21} />,
                      'collapse': false,
                      'hide': true,
                      'component': ToolsNetworkMccMncOperatorPrefix
                    }
                  ]
                }
              ]
            }
          ]
        }
      ]
    }
  ]

  // Object assignment.
  const _FlattenItems = __items => __items.flatMap(item => Object.prototype.hasOwnProperty.call(item, 'items') ? [item.items, ..._FlattenItems(item.items)] : [])

  // Apollo client instance.
  const _ApolloClientInstance_ = React.useRef(ApolloClientProvider(account, { ClearEverything }))

  // Hook assignment.
  const [MutationAccountLogout] = useMutation(AccountLogoutMutation, { 'context': { 'headers': { 'l-authorization': account.token } }, 'client': _ApolloClientInstance_.current })
  const _QueryAccountRead = useQuery(AccountReadQuery, {
    'context': {
      'headers': {
        'l-authorization': account.token
      }
    },
    'client': _ApolloClientInstance_.current
  })
  const _SubscriptionAccountRead = useSubscription(AccountReadSubscription, {
    'context': {
      'headers': {
        'l-authorization': account.token
      }
    },
    'client': _ApolloClientInstance_.current
  })
  const _locationRef = useLocation()
  const _historyRef = useHistory()
  const _themeRef = useTheme()
  const _routeRef = React.useRef([..._routes, ..._.flatten(_FlattenItems(_routes))])
  const { onOpen } = useDisclosure()

  // Object assignment.
  const _Logout = () => MutationAccountLogout().then(() => AccountLogout()).catch(error => { throw error })
  const _GetActiveRoute = routes => {
    // Local variable.
    let i, n

    // Object assignment.
    const _ActiveRoute = o => _locationRef.pathname.split('/')[2] === o?.split('/')?.[2]
    const _NonCollapsibleActiveRoute = o => _locationRef.pathname === o

    // Loop through routes.
    for (i = 0 ;i < routes?.length ;i++) {
      // If route is collapsible.
      if (routes[i]?.collapse) {
        // Loop through items.
        for (n = 0 ;n < routes[i]?.items.length ;n++) if (_ActiveRoute(routes[i]?.items[n]?.path)) return `${routes[i]?.items[n]?.name}`
      } else if (!routes[i]?.collapse) {
        // If route is not collapsible.
        if (_NonCollapsibleActiveRoute(routes[i]?.path)) return routes[i]?.name
      }
    }

    // Return active route.
    return _ActiveRoute
  }
  const _GetActiveNavBar = routes => {
    // Local variable.
    let i

    // Const assignment.
    const _activeNavBar = false

    // Loop through routes.
    for (i = 0 ;i < routes.length ;i++) {
      // If route is collapsible.
      if (routes[i].collapse) {
        // Const assignment.
        const _collapseActiveNavbar = _GetActiveNavBar(routes[i].items)

        // If route is collapsible.
        if (_collapseActiveNavbar !== _activeNavBar) return _collapseActiveNavbar
      } else if (routes[i].category) {
        // Const assignment.
        const _collapseActiveNavbar = _GetActiveNavBar(routes[i].items)

        // If route is category.
        if (_collapseActiveNavbar !== _activeNavBar) return _collapseActiveNavbar
      } else if (-1 !== window.location.href.indexOf(routes[i].layout + routes[i].path)) {
        // If route is not collapsible.
        return routes[i].secondary
      }
    }

    // Return active navbar.
    return _activeNavBar
  }
  const _GetActiveNavBarText = routes => {
    // Local variable.
    let i

    // Const assignment.
    const _activeNavBar = false

    // Loop through routes.
    for (i = 0 ;i < routes.length ;i++) {
      // If route is collapsible.
      if (routes[i].collapse) {
        // Const assignment.
        const _collapseActiveNavbar = _GetActiveNavBarText(routes[i].items)

        // If route is collapsible.
        if (_collapseActiveNavbar !== _activeNavBar) return _collapseActiveNavbar
      } else if (routes[i].category) {
        // Const assignment.
        const _categoryActiveNavBar = _GetActiveNavBarText(routes[i].items)

        // If route is category.
        if (_categoryActiveNavBar !== _activeNavBar) return _categoryActiveNavBar
      } else if (-1 !== window.location.href.indexOf(routes[i].layout + routes[i].path)) {
        // If route is not collapsible.
        return routes[i].messageNavbar
      }
    }

    // Return active navbar.
    return _activeNavBar
  }

  // Event listener.
  React.useEffect(() => {
    // Const assignment.
    const _accountReadQuery = _QueryAccountRead.data?.AccountRead

    /*
     * Only update account details if they
     * are available.
     */
    if ('READ_SUCCESSFUL' === _.first([_accountReadQuery ?? {}]).status && !_.isEmpty(_accountReadQuery) && !_accountReadQuery.ban) {
      // Update account.
      AccountUpdate({
        'email': _.first([_accountReadQuery ?? {}]).email,
        'displayName': _.first([_accountReadQuery ?? {}]).displayName,
        'bio': _.first([_accountReadQuery ?? {}]).bio,
        'thumbnailStoredAt': _.first([_accountReadQuery ?? {}]).thumbnailStoredAt?.path,
        'profileCoverThumbnailStoredAt': _.first([_accountReadQuery ?? {}]).profileCoverThumbnailStoredAt?.path
      })
    } else if ((!_.isEmpty(_accountReadQuery) && _accountReadQuery.ban)) {
      /*
       * Logout immediately and remove
       * all credentials.
       */
      _Logout().catch(e => {
        // Report failure.
        throw e
      })
    }
  }, [])
  React.useEffect(() => {
    // Const assignment.
    const _subscriptionAccountRead = _SubscriptionAccountRead.data?.AccountRead

    /*
     * If data is available.
     * then update it to the context.
     */
    if (!_.isEmpty(_subscriptionAccountRead) && !_subscriptionAccountRead.ban) {
      // Const assignment.
      const _dataToUpdate = {}

      /*
       * Update individual data to the context.
       * as they are available.
       */
      if (!_.isEmpty(_subscriptionAccountRead.email)) Object.assign(_dataToUpdate, { 'email': _subscriptionAccountRead.email })
      if (!_.isEmpty(_subscriptionAccountRead.displayName)) Object.assign(_dataToUpdate, { 'displayName': _subscriptionAccountRead.displayName })
      if (!_.isEmpty(_subscriptionAccountRead.bio)) Object.assign(_dataToUpdate, { 'bio': _subscriptionAccountRead.bio })
      if (!_.isEmpty(_subscriptionAccountRead.thumbnailStoredAt)) Object.assign(_dataToUpdate, { 'thumbnailStoredAt': _subscriptionAccountRead.thumbnailStoredAt.path })
      if (!_.isEmpty(_subscriptionAccountRead.profileCoverThumbnailStoredAt)) Object.assign(_dataToUpdate, { 'profileCoverThumbnailStoredAt': _subscriptionAccountRead.profileCoverThumbnailStoredAt.path })

      // Update store with new information.
      AccountUpdate(_dataToUpdate)
    } else if (!_.isEmpty(_subscriptionAccountRead) && _subscriptionAccountRead.ban) {
      // Logout given user.
      AccountLogout()
    }
  }, [_SubscriptionAccountRead])
  React.useEffect(() => {
    // Update apollo client instance.
    _ApolloClientInstance_.current = ApolloClientProvider(account, { ClearEverything })

    // If user is not logged in then redirect to login page.
    if ('/login' !== _locationRef.pathname && account && !account.isUserLoggedIn) _historyRef.push('/login')
  }, [account])
  React.useEffect(() => {
    // Start NProgress when the document is ready
    NProgress.configure({ 'showSpinner': false })
    NProgress.start()

    // Clear timeout after sometime.
    const j = setTimeout(() => {
      // Stop NProgress after sometime
      NProgress.done()

      // Clear timeout.
      clearTimeout(j)
    }, 1000)
  })

  // Return component.
  return (
    <ApolloProvider client={_ApolloClientInstance_.current}>
      <Switch>
        {_routeRef.current.map(i => (
          <Route
            exact
            path={i.path}
            render={__props => {
              // Const assignment.
              const Component = i.component

              // Return component.
              return i.noLayout && Component ? <Component {...__props} /> : (
                <>
                  <SideBarNavigation routes={_routes} display='none' {...props} />
                  <Box
                    float='right'
                    minHeight='100vh'
                    height='100%'
                    overflow='auto'
                    bg='#F5F8FD'
                    pt='100px'
                    position='relative'
                    maxHeight='100%'
                    w={{ 'base': '100%', 'xl': '80%' }}
                    maxWidth={{ 'base': '100%', 'xl': '80%' }}
                    transition='all 0.33s cubic-bezier(0.685, 0.0473, 0.346, 1)'
                    transitionDuration='.2s, .2s, .35s'
                    transitionProperty='top, bottom, width'
                    transitionTimingFunction='linear, linear, ease'>
                    <Portal>
                      <Box bg='red'>
                        <NavigationBar
                          onOpen={onOpen}
                          logoText={''}
                          timelineChildren={(
                            <SimpleGrid
                              w='100%'
                              transform='translateY(75px)'
                              position='absolute'
                              columns={{ 'sm': 1, 'md': 2, 'lg': 2, 'xl': 4, '2xl': 4 }}
                              gap='20px'>
                              <TotalSmsSent />
                              <NewCustomer />
                              <TotalSmsDelivered />
                              <DlrPercentage />
                            </SimpleGrid>
                          )}
                          brandText={_GetActiveRoute(_routeRef.current)}
                          secondary={_GetActiveNavBar(_routeRef.current)}
                          message={_GetActiveNavBarText(_routeRef.current)}
                          fixed={true}
                          {...props}
                        />
                      </Box>
                    </Portal>
                    <Box
                      p={{ 'base': '20px', 'md': '30px' }}
                      pe='20px'
                      bg='#F5F8FD'
                      boxSizing='border-box'
                      boxShadow='14px 17px 40px 4px rgba(112, 144, 176, 0.1)'
                      display='flex'
                      pt='50px'>
                      <Component {...__props} />
                    </Box>
                    <Toaster
                      position='bottom-left'
                      reverseOrder={false}
                      containerClassName='toaster'
                      toastOptions={{
                        'duration': 5000,
                        'style': {
                          'background': _themeRef.colors.brand[500],
                          'color': _themeRef.colors.white
                        }
                      }}
                    />
                  </Box>
                </>
              )
            }}
            key={String.random(10)} />))}
        <Route path='*' component={Page404} />
      </Switch>
    </ApolloProvider>
  )
}


/*
 * PROPTYPES
 */
Index.propTypes = {
  'account': PropTypes.object.isRequired,
  'AccountUpdate': PropTypes.func.isRequired,
  'AccountLogout': PropTypes.func.isRequired,
  'ClearEverything': PropTypes.func.isRequired
}
Index.defaultProps = {}


/*
 * REDUX
 */
const _MapStateToProps = __state => ({ 'account': __state.Account, 'platform': __state.Platform })
const _MapDispatchToProps = __dispatch => ({
  'AccountUpdate': u => __dispatch({ 'type': 'ACCOUNT_UPDATE', 'Account': u }),
  'AccountLogout': () => __dispatch({ 'type': 'ACCOUNT_CLEAR' }),
  'ClearEverything': () => {
    // Clear all data.
    __dispatch({ 'type': 'ACCOUNT_CLEAR' })
    __dispatch({ 'type': 'PASS_ON_CLEAR' })
  }
})


/*
 * EXPORTS
 */
export default connect(_MapStateToProps, _MapDispatchToProps)(Index)
