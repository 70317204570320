/*
 * IMPORTS
 */
import { gql } from '@apollo/client' // Npm: Apollo client for handling graphql request.


/*
 * EXPORTS
 */
export default gql`
mutation VendorAccountAttachRoutePlanMutation($vendorAccountId: ID!, $routePlanId: [ID!]!) {
  VendorAccountAttachRoutePlan(routePlanId: $routePlanId, vendorAccountId: $vendorAccountId) {
    message
    status
  }
}
`
