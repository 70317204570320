/*
 * IMPORTS
 */
import { gql } from '@apollo/client' // Npm: Apollo client for handling graphql request.


/*
 * EXPORTS
 */
export default gql`
  mutation RoutePlanPlayPauseMutation($routePlanId: ID!, $isPaused: Boolean!) {
    RoutePlanPlayPause(routePlanId: $routePlanId, isPaused: $isPaused) {
      id,
      message,
      status
    }
  }
`
