/*
 * IMPORTS
 */
import React from 'react' // Npm: react.js library.
import { Flex, Grid } from '@chakra-ui/react' // Npm: Chakra UI component library.


/*
 * PACKAGES
 */
import UtilizationChart from 'components/UtilizationChart'
import SystemLoadChart from 'components/SystemLoadChart'


/*
 * STYLES
 */
import './index.css'


/*
 * OBJECTS
 */
const Index = () => (
  <Flex
    className='masterView'
    direction={{ 'base': 'column', 'xl': 'row' }}
    pt='130px'>
    <Flex direction='column' width='stretch'>
      <Grid
        mb='20px'
        gridTemplateColumns={{ 'base': 'auto' }}
        display={{ 'base': 'block', 'lg': 'grid' }}>
        <Flex
          flexDir='column'
          gap='20px'
          w='100%'
          gridArea={{ 'base': 'auto' }}>
          <Flex gap={4}>
            <Flex flex={0.3} flexDir='column' gap='22px'>
              <UtilizationChart title='Memory Utilization' color='#422AFB' whatToShowOnChart='memory' />
              <UtilizationChart title='Space Utilization' color='#422AFB' whatToShowOnChart='drive' />
            </Flex>
            <Flex flex={0.7}>
              <SystemLoadChart title='Cpu Usage' />
            </Flex>
          </Flex>
        </Flex>
      </Grid>
    </Flex>
  </Flex>
)



/*
 * EXPORTS
 */
export default Index
