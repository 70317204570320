/*
 * IMPORTS
 */
import React from 'react' // Npm: react.js library.
import PropTypes from 'prop-types' // Npm: react.js library.
import {
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent, ModalHeader,
  ModalOverlay
} from '@chakra-ui/react' // Npm: Chakra UI components.


/*
 * STYLES
 */
import { headerStyle } from './index.style'


/*
 * OBJECTS
 */
const Index = ({ title, isOpen, onClose, children, isCentered, size, bodyProps }) => {
  // Hook assignment.
  const [forceReRender, setForceReRender] = React.useState('')

  // Event handler.
  React.useEffect(() => {
    // Set force re-render.
    setForceReRender(Math.random())
  }, [isOpen])

  // Return component.
  return (
    <Modal
      bg='blackAlpha.300'
      size={size}
      isOpen={isOpen}
      onClose={onClose}
      isCentered={isCentered}
      key={forceReRender}>
      {' '}
      <ModalOverlay />
      <ModalContent borderRadius='25px'>
        {title ? <ModalHeader style={headerStyle}>{title}</ModalHeader> : void 0}
        <ModalCloseButton
          size='lg'
          mt='15px'
          mr='10px'
          onClick={onClose}
          color='rgba(238, 93, 80, 1)'
          background='none'
        />
        <ModalBody borderBottomLeftRadius='25px' borderBottomRightRadius='25px' overflow='hidden' {...bodyProps}>
          {React.Children.map(children, child => {
            // Only clone the child if it's a valid React element
            if (React.isValidElement(children)) return React.cloneElement(children, { onClose })

            // Return child.
            return child
          })}
        </ModalBody>
      </ModalContent>
    </Modal>
  )
}


/*
 * PROPTYPES
 */
Index.propTypes = {
  'title': PropTypes.string,
  'isOpen': PropTypes.bool,
  'onClose': PropTypes.func,
  'children': PropTypes.any,
  'size': PropTypes.string,
  'isCentered': PropTypes.bool,
  'bodyProps': PropTypes.object
}
Index.defaultProps = {
  'size': 'xl',
  'isCentered': true
}


/*
 * EXPORT
 */
export default Index
