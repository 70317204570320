/*
 * IMPORTS
 */
import React from 'react' // Npm: react.js library.
import PropTypes from 'prop-types' // Npm: react.js library.
import { Box, useStyleConfig } from '@chakra-ui/react' // Npm: Chakra UI components.


/*
 * OBJECTS
 */
const Index = ({ variant, children, ...rest }) => {
  // Hook assignment.
  const styles = useStyleConfig('Card', { variant })

  // Return component.
  return (
    <Box __css={styles} {...rest}>
      {children}
    </Box>
  )
}


/*
 * PROPTYPES
 */
Index.propTypes = {
  'variant': PropTypes.string,
  'children': PropTypes.node
}



/*
 * EXPORTS
 */
export default Index
