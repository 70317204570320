/*
 * IMPORTS
 */
import { gql } from '@apollo/client' // Npm: Apollo client for handling graphql request.


/*
 * EXPORTS
 */
export default gql`
mutation VendorUpdateMutation(
  $vendorId: ID!,
  $displayName: String!,
  $website: URL
) {
  VendorUpdate(
    vendorId: $vendorId,
    displayName: $displayName,
    website: $website
  ) {
    message
    status
  }
}
`
