/*
 * IMPORTS
 */
import { mode } from '@chakra-ui/theme-tools' // Npm: Chakra UI theme tools.


/*
 * GLOBALS
 */
const Card = {
  'baseStyle': props => ({
    'p': '20px 20px 30px 20px',
    'display': 'flex',
    'flexDirection': 'column',
    'width': '100%',
    'position': 'relative',
    'boxShadow': mode(
      '14px 17px 40px 4px rgba(112, 144, 176, 0.08)',
      'unset'
    )(props),
    'borderRadius': '20px',
    'minWidth': '0px',
    'wordWrap': 'break-word',
    'bg': mode('#ffffff', 'navy.800')(props),
    'backgroundClip': 'border-box'
  }),
  'variants': {
    'panel': props => ({
      'bg': 'dark' === props.colorMode ? 'gray.700' : 'white',
      'width': '100%',
      'boxShadow': '45px 76px 113px 7px rgba(112, 144, 176, 0.08)',
      'borderRadius': '30px'
    })
  }
}


/*
 * EXPORTS
 */
export const CardComponent = {
  components: {
    Card
  }
}
