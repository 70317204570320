/*
 * IMPORTS
 */
import React from 'react' // Npm: react.js library.
import PropTypes from 'prop-types' // Npm: react.js library.
import { useHistory } from 'react-router-dom' // Npm: react-router-dom for routing.
import { connect } from 'react-redux' // Npm: React Redux for state management.
import { HiBuildingStorefront, HiCodeBracketSquare, HiPencil, HiTrash, HiWrenchScrewdriver } from 'react-icons/hi2' // Npm: React icons.
import {
  Button,
  Flex,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Text
} from '@chakra-ui/react' // Npm: Chakra UI components.


/*
 * STYLES
 */
import { menuStyle } from './index.style'


/*
 * OBJECTS
 */
const Index = ({
  routeId,
  type,
  onRouteUpdate,
  onRouteDelete,
  onRouteTerminal,
  PassOnUpdate
}) => {
  // Hook assignment.
  const _historyRef = useHistory()

  // Return Component.
  return (
    <Flex>
      <Menu placement='left'>
        <MenuButton
          as={Button}
          w='100%'
          borderRadius='8px'
          color='brand.500'
          _hover={{ 'bg': 'brand.500', 'color': 'white' }}
          _active={{ 'bg': 'brand.500', 'color': 'white' }}
          _focused={{ 'bg': 'brand.500', 'color': 'white' }}
          bg='transparent'>
          <HiWrenchScrewdriver size={18} />
        </MenuButton>
        <MenuList
          style={menuStyle}
          maxHeight='400px'
          overflow='auto'>
          <MenuItem
            gap='10px'
            display='flex'
            onClick={() => {
              // Push route id to url.
              PassOnUpdate({ routeId })

              // Execute handler.
              onRouteUpdate()
            }}
            minH='40px'>
            <HiPencil size={18} />
            <Text as='span' fontWeight='500'>Edit Route Mapping</Text>
          </MenuItem>
          <MenuItem
            gap='10px'
            display='flex'
            onClick={() => {
              // Push route id to url.
              PassOnUpdate({ routeId })

              // Execute handler.
              onRouteDelete()
            }}
            minH='40px'>
            <HiTrash />
            <Text as='span' fontWeight='500'>Delete Route Mapping</Text>
          </MenuItem>
          <MenuItem
            gap='10px'
            display='flex'
            onClick={() => {
              // Push route id to url.
              PassOnUpdate({ routeId, type })

              // Execute handler.
              _historyRef.push('/tools/route/mapping/connect')
            }}
            minH='40px'>
            <HiBuildingStorefront />
            <Text as='span' fontWeight='500'>Connect Mapping</Text>
          </MenuItem>
          <MenuItem
            gap='10px'
            display='flex'
            onClick={() => {
              // Push route id to url.
              PassOnUpdate({ routeId })

              // Execute handler.
              onRouteTerminal()
            }}
            minH='40px'>
            <HiCodeBracketSquare />
            <span>Connect Mapping Logs</span>
          </MenuItem>
        </MenuList>
      </Menu>
    </Flex>
  )
}


/*
 * PROPTYPES
 */
Index.propTypes = {
  'routeId': PropTypes.string,
  'type': PropTypes.string,
  'onRouteUpdate': PropTypes.func,
  'onRouteDelete': PropTypes.func,
  'onRouteTerminal': PropTypes.func,
  'PassOnUpdate': PropTypes.func
}


/*
 * REDUX
 */
const _MapDispatchToProps = __dispatch => ({
  'PassOnUpdate': u => __dispatch({ 'type': 'PASS_ON_UPDATE', 'PassOn': u })
})


/*
 * EXPORT
 */
export default connect(void 0, _MapDispatchToProps)(Index)
