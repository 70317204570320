/*
 * IMPORTS
 */
import React from 'react' // Npm: react.js library.
import PropTypes from 'prop-types' // Npm: Prop types.
import Papa from 'papaparse' // Npm: Papaparse for parsing data to CSV.
import * as FileSaver from 'file-saver' // Npm: FileSaver for saving file.
import { HiPrinter } from 'react-icons/hi2' // Npm: React icons.
import { Button, Text, Tooltip } from '@chakra-ui/react' // Npm: Chakra UI components.


/*
 * OBJECTS
 */
const Index = ({ cellsData = [], headersData = [] }) => {
  // Object assignment.
  const _DownloadCsv = () => {
    // Create a new array of objects with only the data we need.
    const csvData = cellsData.map(item => {
      // Create a new object.
      const _dataToReturn = {}

      // Loop through the headers and create key-value pairs.
      headersData.forEach(e => (_dataToReturn[e.key] = item[e.key]))

      // Return the new object.
      return _dataToReturn
    })

    const csvString = Papa.unparse(csvData) // Use papaparse to convert data to CSV string

    // Create a blob object of the data.
    const csvBlob = new Blob([csvString], { type: 'text/csv;charset=utf-8;' })

    // Save given file with random name.
    FileSaver.saveAs(csvBlob, `${String.random(6)}.csv`)
  }

  // Return component.
  return (
    <Tooltip label='Export data in table into csv.' fontSize='sm'>
      <Button borderRadius='8px' bg='brand.100' aria-label='Export data in table into csv.' onClick={_DownloadCsv} color='brand.800' _hover={{ 'bg': 'brand.500', 'color': 'white' }}>
        <Text as='h4' mr={2} fontWeight='500'>Download Sheet</Text>
        <HiPrinter size='18px' />
      </Button>
    </Tooltip>
  )
}


/*
 * PROPTYPES
 */
Index.propTypes = {
  'cellsData': PropTypes.array,
  'headersData': PropTypes.array
}


/*
 * EXPORT
 */
export default Index
