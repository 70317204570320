/*
 * IMPORTS
 */
import React from 'react' // Npm: react.js library.
import PropTypes from 'prop-types' // Npm: react.js library.
import { connect } from 'react-redux' // Npm: React Redux for state management.
import { useMutation } from '@apollo/client' // Npm: Apollo client.


/*
 * SIBLINGS
 */
import DeleteCard from 'components/DeleteCard'


/*
 * GRAPHS
 */
import RoutePlanDeleteMutation from './__mutation__/index.routePlan.delete.mutation'
import { toast } from 'react-hot-toast'


/*
 * OBJECTS
 */
const Index = ({ passOn, onClose }) => {
  // Hook assignment.
  const [MutationRoutePlanDelete, MutationRoutePlanDeleteResponse] = useMutation(RoutePlanDeleteMutation)

  // Return Component.
  return (
    <DeleteCard onClose={onClose} onConfirm={() => MutationRoutePlanDelete({ 'variables': { 'routePlanId': passOn?.routePlanId } }).then(i => {
      // Style Guide.
      toast(i?.data?.RoutePlanDelete?.message)

      // Report success.
      return onClose?.()
    }).catch(() =>
    // Style Guide.
      toast('Something went wrong please try after some time.'))} isLoading={MutationRoutePlanDeleteResponse.loading} />
  )
}


/*
 * PROPTYPES
 */
Index.propTypes = {
  'passOn': PropTypes.object,
  'onClose': PropTypes.func
}


/*
 * REDUX
 */
const _MapStateToProps = __state => ({ 'passOn': __state.PassOn })


/*
 * EXPORT
 */
export default connect(_MapStateToProps)(Index)
