/*
 * IMPORTS
 */
import React from 'react' // Npm: react.js library.
import PropTypes from 'prop-types' // Npm: react.js library.
import { connect } from 'react-redux' // Npm: React Redux for state management.
import { HiPencil, HiTrash } from 'react-icons/hi2' // Npm: React icons.
import {
  Button,
  Flex,
  Menu,
  MenuButton,
  MenuItem,
  MenuList
} from '@chakra-ui/react' // Npm: Chakra UI components.


/*
 * STYLES
 */
import { menuStyle } from './index.style'


/*
 * OBJECTS
 */
const Index = ({
  blockDestinationAddressId,
  onBlockDestinationAddressUpsert,
  onBlockDestinationAddressDelete,
  PassOnUpdate
}) => (
  <Flex>
    <Menu placement='left'>
      <MenuButton
        as={Button}
        w='100%'
        borderRadius='8px'
        color='gray.500'
        bg='transparent'>
        <HiPencil size={18} />
      </MenuButton>
      <MenuList
        style={menuStyle}
        maxHeight='400px'
        overflow='auto'>
        <MenuItem
          gap='10px'
          display='flex'
          color='gray.500'
          onClick={() => {
            // Push blockDestinationAddress id to url.
            PassOnUpdate({ blockDestinationAddressId })

            // Execute handler.
            onBlockDestinationAddressUpsert()
          }}
          minH='40px'>
          <HiPencil size={18} />
          <span>Block Destination Address Edit</span>
        </MenuItem>
        <MenuItem
          gap='10px'
          display='flex'
          color='gray.500'
          onClick={() => {
            // Push blockDestinationAddress id to url.
            PassOnUpdate({ blockDestinationAddressId })

            // Execute handler.
            onBlockDestinationAddressDelete()
          }}
          minH='40px'>
          <HiTrash size={18} />
          <span>Block Destination Address Delete</span>
        </MenuItem>
      </MenuList>
    </Menu>
  </Flex>
)


/*
 * PROPTYPES
 */
Index.propTypes = {
  'blockDestinationAddressId': PropTypes.string,
  'onBlockDestinationAddressUpsert': PropTypes.func,
  'onBlockDestinationAddressDelete': PropTypes.func,
  'PassOnUpdate': PropTypes.func
}


/*
 * REDUX
 */
const _MapDispatchToProps = __dispatch => ({
  'PassOnUpdate': u => __dispatch({ 'type': 'PASS_ON_UPDATE', 'PassOn': u })
})


/*
 * EXPORT
 */
export default connect(void 0, _MapDispatchToProps)(Index)
