/*
 * IMPORTS
 */
import { gql } from '@apollo/client' // Npm: Apollo client for handling graphql request.


/*
 * EXPORTS
 */
export default gql`
mutation VendorAccountAttachSmppMutation($vendorAccountId: ID!, $smppId: ID!) {
  VendorAccountAttachSmpp(
    vendorAccountId: $vendorAccountId,
    smppId: $smppId
  ) {
    message
    status
  }
}
`
