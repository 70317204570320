/*
 * IMPORTS
 */
import React from 'react' // Npm: react.js library.
import Moment from 'moment' // Npm: Moment library.
import _ from 'underscore' // Npm: underscore library.
import { useQuery } from '@apollo/client' // Npm: Apollo client.
import { IoMdAdd } from 'react-icons/io' // Npm: React icons.
import {
  Button,
  Flex,
  Table,
  TableContainer,
  Tbody,
  Td,
  Thead,
  Tooltip,
  Tr,
  useDisclosure
} from '@chakra-ui/react' // Npm: Chakra UI components.


/*
 * PACKAGES
 */
import DownloadToExcel from 'components/DownloadToExcel'
import Pagination from 'components/Pagination'
import TableSpinner from 'components/TableSpinner'
import Modal from 'components/Modal'
import MccUpsert from 'components/MccUpsert'
import MccDropDownOption from 'components/MccDropDownOption'
import MccDelete from 'components/MccDelete'


/*
 * STYLES
 */
import {
  buttonStyle,
  cellStyle,
  headerStyle,
  rowStyle
} from './index.style'


/*
 * GRAPHS
 */
import MccReadQuery from './__query__/index.mcc.read.query'


/*
 * OBJECTS
 */
const Index = () => {
  // Hook assignment.
  const [skipPage, setSkipPage] = React.useState(0)
  const _skipDifference = 8
  const { 'isOpen': isMccCreateOpen, 'onOpen': onMccCreateOpen, 'onClose': onMccCreateClose } = useDisclosure()
  const { 'isOpen': isMccUpdateOpen, 'onOpen': onMccUpdateOpen, 'onClose': onMccUpdateClose } = useDisclosure()
  const { 'isOpen': isMccDeleteOpen, 'onOpen': onMccDeleteOpen, 'onClose': onMccDeleteClose } = useDisclosure()
  const _QueryMccRead = useQuery(MccReadQuery, { 'variables': { 'skip': skipPage * _skipDifference, 'take': _skipDifference } })

  console.log(_QueryMccRead)

  // Return component.
  return (
    <Flex
      fontSize={['Clamp(13px, 1.5vw, 15px)']}
      direction={{ 'base': 'column' }}
      display='flex'
      flex={1}
      overflowX='scroll'>
      <Flex
        display='flex'
        flex={1}
        flexDirection='column'
        gap='1rem'
        bg='white'
        minH='calc(100vh - 160px)'
        maxHeight='100%'
        borderRadius='20px'
        p='20px'>
        <Flex pb='0px' justify='space-between'>
          <Tooltip label='Create New Operator' fontSize='sm'>
            <Button
              w='170px'
              leftIcon={<IoMdAdd />}
              onClick={onMccCreateOpen}
              style={buttonStyle}>
              Create Mcc
            </Button>
          </Tooltip>
          <DownloadToExcel
            cellsData={0 === _QueryMccRead?.data?.MccRead?.length || !_.every(_.pluck(_QueryMccRead?.data?.MccRead, 'status'), i => 'READ_SUCCESSFUL' === i) ? [] : _QueryMccRead?.data?.MccRead.map((item, __index) => ({
              'S.No.': `${(__index + (_skipDifference * (skipPage))) + 1}.`,
              'CreatedAt': item.createdAt,
              'UpdatedAt': item?.updatedAt ?? '-',
              'Name': item?.displayName ?? '-',
              'Email': item?.email ?? '-',
              'Phone': item?.phone ?? '-',
              'CreatedBy': item?.Account?.displayName ?? '-'
            }))}
            headersData={[
              'S.No.',
              'CreatedAt',
              'UpdatedAt',
              'Name',
              'Email',
              'Phone',
              'CreatedBy'
            ].map(i => ({ 'key': i, 'label': i }))} />
        </Flex>
        <TableContainer
          flex={1}
          display='flex'
          overflowY='scroll'
          borderRadius='15px'
          outline='1px solid #C5CFE8'>
          {_QueryMccRead.loading ? (
            <Flex w='100%' h='100%' display='flex' alignItems='center' justifyContent='center'>
              <TableSpinner isLoading={true} />
            </Flex>
          ) : 0 === _QueryMccRead.data?.MccRead?.length || !_.every(_.pluck(_QueryMccRead.data?.MccRead, 'status'), i => 'READ_SUCCESSFUL' === i) ? (
            <Flex w='100%' h='100%' display='flex' alignItems='center' justifyContent='center'>
              <TableSpinner isLoading={false} isEmpty={true} />
            </Flex>
          ) : (
            <Table variant='simple' display='block' size='md'>
              <Thead>
                <Tr style={headerStyle}>
                  <Td style={rowStyle}>S.No</Td>
                  <Td style={rowStyle}>CreatedAt</Td>
                  <Td style={rowStyle}>UpdatedAt</Td>
                  <Td style={rowStyle}>Dialing Code</Td>
                  <Td style={rowStyle}>MCC</Td>
                  <Td style={rowStyle}>Country</Td>
                  <Td style={rowStyle}>Actions</Td>
                </Tr>
              </Thead>
              <Tbody style={cellStyle}>
                {_QueryMccRead.data?.MccRead?.map(({ id, createdAt, updatedAt, mcc, countryName, dialingCode }, __index) => (
                  <Tr key={String.random(9)}>
                    <Td
                      style={rowStyle}
                      borderRight='1px solid rgba(216, 227, 252, 1)'>
                      {`${(__index + (_skipDifference * (skipPage))) + 1}.`}
                    </Td>
                    <Td
                      style={rowStyle}
                      borderRight='1px solid rgba(216, 227, 252, 1)'>
                      {Moment(createdAt).format('YYYY-MM-DD hh:mm:ss A')}
                    </Td>
                    <Td
                      style={rowStyle}
                      borderRight='1px solid rgba(216, 227, 252, 1)'>
                      {Moment(updatedAt).format('YYYY-MM-DD hh:mm:ss A')}
                    </Td>
                    <Td
                      style={rowStyle}
                      borderRight='1px solid rgba(216, 227, 252, 1)'>
                      {dialingCode ?? '-'}
                    </Td>
                    <Td
                      width='50%'
                      style={rowStyle}
                      borderRight='1px solid rgba(216, 227, 252, 1)'>
                      {mcc ?? '-'}
                    </Td>
                    <Td
                      width='50%'
                      style={rowStyle}
                      borderRight='1px solid rgba(216, 227, 252, 1)'>
                      {countryName ?? '-'}
                    </Td>
                    <Td
                      style={rowStyle}>
                      {' '}
                      {
                        <MccDropDownOption
                          mccId={id}
                          onMccUpdate={onMccUpdateOpen}
                          onMccDelete={onMccDeleteOpen}
                        />
                      }
                    </Td>
                  </Tr>
                ))}
              </Tbody>
            </Table>
          )
          }
        </TableContainer>
      </Flex>
      <Pagination itemsPerPage={_skipDifference} totalCount={_.pluck(_QueryMccRead?.data?.MccRead, '_totalCount')?.[0]} onPageChange={setSkipPage} />
      <Modal
        size='md'
        title='Mcc Create'
        isOpen={isMccCreateOpen}
        onClose={onMccCreateClose}>
        <MccUpsert isCreateOnly={true} />
      </Modal>
      <Modal
        size='md'
        title='Mcc Update'
        isOpen={isMccUpdateOpen}
        onClose={onMccUpdateClose}>
        <MccUpsert />
      </Modal>
      <Modal
        size='md'
        title='Mcc Delete'
        isOpen={isMccDeleteOpen}
        onClose={onMccDeleteClose}>
        <MccDelete />
      </Modal>
    </Flex>
  )
}


/*
 * EXPORT
 */
export default Index
