/*
 * IMPORTS
 */
import React from 'react' // Npm: react.js library.
import PropTypes from 'prop-types' // Npm: react.js library.
import _ from 'underscore' // Npm: underscore.js library.
import { toast } from 'react-hot-toast' // Npm: React hot toast.
import { connect } from 'react-redux' // Npm: React Redux for state management.
import { useMutation, useQuery } from '@apollo/client' // Npm: Apollo client.
import { IoIosSave } from 'react-icons/io' // Npm: React icons.
import {
  Button,
  Flex,
  FormLabel,
  Spinner
} from '@chakra-ui/react' // Npm: Chakra UI components.


/*
 * PACKAGES
 */
import { MemoizedSelect } from 'components/MemoizedInput'


/*
 * GRAPHS
 */
import VendorAccountAttachRoutePlanMutation from './__mutation__/index.vendorAccount.attachRoutePlan.mutation'
import VendorAccountReadQuery from './__query__/index.vendorAccount.read.query'


/*
 * STYLES
 */
import './index.style.css'
import { labelStyle, selectStyle } from './index.style'


/*
 * OBJECTS
 */
const Index = ({ onClose, passOn }) => {
  // Hook assignment.
  const [vendorAccount, setVendorAccount] = React.useState(void 0)
  const [MutationVendorAccountAttachRoutePlan, MutationVendorAccountAttachRoutePlanResponse] = useMutation(VendorAccountAttachRoutePlanMutation)
  const _QueryVendorAccountRead = useQuery(VendorAccountReadQuery)

  // Object assignment.
  const _AttachVendorAccountAndRouteToCustomer = async () => {
    // Only Execute if route has data.
    if (!_.isEmpty(vendorAccount) && vendorAccount.includes('(') && vendorAccount.includes(')')) {
      // Const assignment.
      const vendorAccountId = vendorAccount.split(' ')[1].replace('(', '').replace(')', '')

      // Execute mutation.
      const _MutationVendorAccountAttachRoutePlan = await MutationVendorAccountAttachRoutePlan({
        'variables': {
          'routePlanId': passOn?.routePlanId,
          'vendorAccountId': vendorAccountId
        }
      })

      // If mutation caught an exception then report failure.
      if (_MutationVendorAccountAttachRoutePlan instanceof Error) {
        // Report failure.
        return _MutationVendorAccountAttachRoutePlan
      }

      // Style Guide.
      toast(_MutationVendorAccountAttachRoutePlan?.data?.VendorAccountAttachRoutePlan?.message)

      // On successful bind close modal.
      if (_.every(_.pluck(_MutationVendorAccountAttachRoutePlan?.data?.VendorAccountAttachRoutePlan, 'status'), i => 'UPDATE_SUCCESSFUL' === i)) onClose()
    }

    // Report void 0.
    return void 0
  }

  // Return component.
  return (
    <Flex flexDirection='column'>
      <Flex width='100%' className='attachRouteAndVendorAccount' direction='row' gap='30px'>
        <Flex width='100%' flex='1' direction='column'>
          <FormLabel style={labelStyle}>Vendor Account</FormLabel>
          <MemoizedSelect
            style={selectStyle}
            placeholder='e.g. "Awesome Vendor Account"'
            data={vendorAccount}
            onChange={e => setVendorAccount(e.target.value)}
            options={_QueryVendorAccountRead?.data?.VendorAccountRead?.map(i => `${i.displayName} (${i.id})`)}
          />
        </Flex>
      </Flex>
      <Flex justifyContent='space-between' mt='20px' mb='20px'>
        <Button
          width='100%'
          height='42px'
          borderRadius='10px'
          bg='rgba(117, 81, 255, 1)'
          color='rgba(255, 255, 255, 1)'
          _hover={{ 'bg': 'rgba(117, 81, 255, 1)' }}
          onClick={_AttachVendorAccountAndRouteToCustomer}>
          Save Setting {MutationVendorAccountAttachRoutePlanResponse.loading ? <Spinner color='#c1a9f4' size='sm' ml='10px'/> : <IoIosSave size={16} style={{ 'marginLeft': '10px' }}/>}
        </Button>
      </Flex>
    </Flex>
  )
}


/*
 * PROPTYPES
 */
Index.propTypes = {
  'onClose': PropTypes.func,
  'passOn': PropTypes.object
}


/*
 * REDUX
 */
const _MapStateToProps = __state => ({ 'passOn': __state.PassOn })


/*
 * EXPORT
 */
export default connect(_MapStateToProps)(Index)
