/*
 * IMPORTS
 */
import React, { Component } from 'react' // Npm: react.js library.
import PropTypes from 'prop-types' // Npm: react.js library.
import classnames from 'classnames' // Npm: classnames utility.
import {
  endOfDay,
  format,
  isAfter,
  isBefore,
  isSameDay,
  startOfDay
} from 'date-fns' // Npm: date-fns library.


/*
 * CLASS
 */
class DayCell extends Component {
  constructor(props, context) {
    // Constructor.
    super(props, context)

    // Update state.
    this.state = {
      'hover': false,
      'active': false
    }
  }

  /*
   * HandleKeyEvent
   * @param {object} event
   * @return {void}
   * @description Handle key event.
   * @see https://developer.mozilla.org/en-US/docs/Web/API/KeyboardEvent/keyCode
   * @see https://developer.mozilla.org/en-US/docs/Web/API/KeyboardEvent/type
   */
  handleKeyEvent = event => {
    // Handle key event.
    const { day, onMouseDown, onMouseUp } = this.props

    // If key code is 13 (enter) or 32 (space).
    // eslint-disable-next-line no-magic-numbers
    if ([13 /* Space */, 32].includes(event.keyCode)) {
      // If key event is keydown.
      if ('keydown' === event.type) onMouseDown(day)

      // Else key event is keyup.
      else onMouseUp(day)
    }
  }

  /*
   * HandleMouseEvent
   * @param {object} event
   * @return {void}
   * @description Handle mouse event.
   * @see https://developer.mozilla.org/en-US/docs/Web/API/Element/mouseenter_event
   */
  handleMouseEvent = event => {
    // Const assignment.
    const { day, disabled, onPreviewChange, onMouseEnter, onMouseDown, onMouseUp } = this.props
    const stateChanges = {}

    // If disabled.
    if (disabled) {
      // On preview change.
      onPreviewChange()

      // Return void 0.
      return void 0
    }

    // Switch event type.
    switch (event.type) {
    case 'mouseenter':
      // On mouse enter.
      onMouseEnter(day)

      // On preview change.
      onPreviewChange(day)

      // Update state.
      stateChanges.hover = true

      // Break.
      break
    case 'blur':
    case 'mouseleave':
      // Update state.
      stateChanges.hover = false

      // Break.
      break
    case 'mousedown':
      // Update state.
      stateChanges.active = true

      // On mouse down.
      onMouseDown(day)

      // Break.
      break
    case 'mouseup':
      // Stop propagation.
      event.stopPropagation()

      // Update state.
      stateChanges.active = false

      // On mouse up.
      onMouseUp(day)

      // Break.
      break
    case 'focus':
      // On preview change.
      onPreviewChange(day)

      // Break.
      break
    default:
      break
    }

    // If state changes.
    if (Object.keys(stateChanges).length) this.setState(stateChanges)

    // Return void 0.
    return void 0
  }

  /*
   * GetClassNames
   * @return {string}
   * @description Get class names.
   * @see https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Object/keys
   */
  getClassNames = () => {
    // Const assignment.
    const { isPassive, isToday, isWeekend, isStartOfWeek, isEndOfWeek, isStartOfMonth, isEndOfMonth, disabled, styles } = this.props

    // Return value.
    return classnames(styles.day, {
      [styles.dayPassive]: isPassive,
      [styles.dayDisabled]: disabled,
      [styles.dayToday]: isToday,
      [styles.dayWeekend]: isWeekend,
      [styles.dayStartOfWeek]: isStartOfWeek,
      [styles.dayEndOfWeek]: isEndOfWeek,
      [styles.dayStartOfMonth]: isStartOfMonth,
      [styles.dayEndOfMonth]: isEndOfMonth,
      [styles.dayHovered]: this.state.hover,
      [styles.dayActive]: this.state.active
    })
  }

  /*
   * RenderPreviewPlaceholder
   * @return {object}
   * @description Render preview placeholder.
   */
  renderPreviewPlaceholder = () => {
    // Const assignment.
    const { preview, day, styles } = this.props

    // If no preview, return null.
    if (!preview) return null

    // Const assignment.
    const startDate = preview.startDate ? endOfDay(preview.startDate) : null
    const endDate = preview.endDate ? startOfDay(preview.endDate) : null
    const isInRange = (!startDate || isAfter(day, startDate)) && (!endDate || isBefore(day, endDate))
    const isStartEdge = !isInRange && isSameDay(day, startDate)
    const isEndEdge = !isInRange && isSameDay(day, endDate)

    // Return value.
    return (
      <span
        className={classnames({
          [styles.dayStartPreview]: isStartEdge,
          [styles.dayInPreview]: isInRange,
          [styles.dayEndPreview]: isEndEdge
        })}
        style={{ color: preview.color }}
      />
    )
  }

  /*
   * RenderSelectionPlaceholders
   * @return {object}
   * @description Render selection placeholders.
   */
  renderSelectionPlaceholders = () => {
    // Const assignment.
    const { styles, ranges, day } = this.props

    // If date display mode.
    if ('date' === this.props.displayMode) {
      // Const assignment.
      const isSelected = isSameDay(this.props.day, this.props.date)

      // If selected, return value.
      return isSelected ? (
        <span className={styles.selected} style={{ color: this.props.color }} />
      ) : null
    }

    // Const assignment.
    const inRanges = ranges.reduce((result, range) => {
      // Local variable.
      let endDate, startDate

      // Variable assignment.
      startDate = range.startDate
      endDate = range.endDate

      // If start date, end date, and before end date.
      if (startDate && endDate && isBefore(endDate, startDate)) {
        // Swap start date and end date.
        [startDate, endDate] = [endDate, startDate]
      }

      // Start date and end date.
      startDate = startDate ? endOfDay(startDate) : null
      endDate = endDate ? startOfDay(endDate) : null

      // Const assignment.
      const isInRange = (!startDate || isAfter(day, startDate)) && (!endDate || isBefore(day, endDate))
      const isStartEdge = !isInRange && isSameDay(day, startDate)
      const isEndEdge = !isInRange && isSameDay(day, endDate)

      // If in range, start edge, or end edge.
      if (isInRange || isStartEdge || isEndEdge) {
        // Return value.
        return [
          ...result,
          {
            isStartEdge,
            isEndEdge,
            isInRange,
            ...range
          }
        ]
      }

      // Return result.
      return result
    }, [])

    // Return value.
    return inRanges.map((range, i) => (
      <span
        key={i}
        className={classnames({
          [styles.startEdge]: range.isStartEdge,
          [styles.endEdge]: range.isEndEdge,
          [styles.inRange]: range.isInRange
        })}
        style={{ color: range.color || this.props.color }}
      />
    ))
  }

  /*
   * Render
   * @return {object}
   * @description Render component.
   * @see https://reactjs.org/docs/react-component.html#render
   */
  render() {
    // Const assignment.
    const { dayContentRenderer } = this.props

    // Return value.
    return (
      <button
        type='button'
        onMouseEnter={this.handleMouseEvent}
        onMouseLeave={this.handleMouseEvent}
        onFocus={this.handleMouseEvent}
        onMouseDown={this.handleMouseEvent}
        onMouseUp={this.handleMouseEvent}
        onBlur={this.handleMouseEvent}
        onPauseCapture={this.handleMouseEvent}
        onKeyDown={this.handleKeyEvent}
        onKeyUp={this.handleKeyEvent}
        className={this.getClassNames(this.props.styles)}
        {...(this.props.disabled || this.props.isPassive ? { tabIndex: -1 } : {})}
        style={{ color: this.props.color }}>
        {this.renderSelectionPlaceholders()}
        {this.renderPreviewPlaceholder()}
        <span className={this.props.styles.dayNumber}>
          {dayContentRenderer?.(this.props.day) || (
            <span>{format(this.props.day, this.props.dayDisplayFormat)}</span>
          )}
        </span>
      </button>
    )
  }
}


/*
 * PROPTYPES
 */
DayCell.defaultProps = {}
DayCell.propTypes = {
  'day': PropTypes.object.isRequired,
  'dayDisplayFormat': PropTypes.string,
  'date': PropTypes.object,
  'ranges': PropTypes.arrayOf(PropTypes.shape({
    'startDate': PropTypes.object,
    'endDate': PropTypes.object,
    'color': PropTypes.string,
    'key': PropTypes.string,
    'autoFocus': PropTypes.bool,
    'disabled': PropTypes.bool,
    'showDateDisplay': PropTypes.bool
  })),
  'preview': PropTypes.shape({
    'startDate': PropTypes.object,
    'endDate': PropTypes.object,
    'color': PropTypes.string
  }),
  'onPreviewChange': PropTypes.func,
  'previewColor': PropTypes.string,
  'disabled': PropTypes.bool,
  'isPassive': PropTypes.bool,
  'isToday': PropTypes.bool,
  'isWeekend': PropTypes.bool,
  'isStartOfWeek': PropTypes.bool,
  'isEndOfWeek': PropTypes.bool,
  'isStartOfMonth': PropTypes.bool,
  'isEndOfMonth': PropTypes.bool,
  'color': PropTypes.string,
  'displayMode': PropTypes.oneOf(['dateRange', 'date']),
  'styles': PropTypes.object,
  'onMouseDown': PropTypes.func,
  'onMouseUp': PropTypes.func,
  'onMouseEnter': PropTypes.func,
  'dayContentRenderer': PropTypes.func
}


/*
 * EXPORTS
 */
export default DayCell
