/*
 * IMPORTS
 */
import React from 'react' // Npm: react.js library.
import { NavLink, useLocation } from 'react-router-dom' // Npm: react-router-dom for routing.
import { FaCircle } from 'react-icons/fa' // Npm: React icons.
import {
  Accordion,
  AccordionButton,
  AccordionIcon,
  AccordionItem,
  AccordionPanel,
  Box,
  Flex,
  HStack,
  Icon,
  List,
  ListItem,
  Text,
  useColorModeValue
} from '@chakra-ui/react' // Npm: Chakra UI components.


/*
 * STYLES
 */
import './index.css'


/*
 * OBJECTS
 */
const Index = ({ routes }) => {
  // Hook assignment.
  const _locationRef = useLocation()
  const _activeColorRef = useColorModeValue('rgb(55,19,236)', 'rgb(55,19,236)')
  const _inactiveColorRef = useColorModeValue('gray.400', 'gray.400')
  const _activeIconRef = useColorModeValue('rgb(55,19,236)', 'rgb(55,19,236)')

  // Object assignment.
  const _GetActivePath = j => ('/' !== j.path && _locationRef.pathname.includes(j.path)) || (('/' === j.path && _locationRef.pathname === j.path))
  const _CreateAccordionLinks = __routes => __routes.map((route, key) => (
    <NavLink to={route.path} key={key}>
      <ListItem
        ms='28px'
        display='flex'
        alignItems='center'
        mb='10px'
        key={key}>
        <Icon w='6px' h='6px' me='8px' as={FaCircle} color={_activeIconRef} />
        <Text
          color={_GetActivePath(route) ? _activeColorRef : _inactiveColorRef}
          fontWeight={_GetActivePath(route) ? 'bold' : 'normal'}
          fontSize='sm'>
          {route.name}
        </Text>
      </ListItem>
    </NavLink>
  ))
  const _CreateLink = __routes => __routes.map((route, key) => {
    // If route is hidden then return nothing
    if (route.hide) return void 0

    // If route is collapse then return component.
    if (route.collapse) {
      // Return component.
      return (
        <Accordion allowToggle key={key}>
          <AccordionItem border='none' key={key}>
            <AccordionButton
              display='flex'
              align='center'
              justify='center'
              _hover={{ 'bg': 'unset' }}
              _focus={{ 'boxShadow': 'none' }}
              borderRadius='8px'
              w={{ 'sm': '100%', 'xl': '100%', '2xl': '95%' }}
              px={route.icon ? null : '0px'}
              py='0px'
              bg={'transparent'}
              ms={0}>
              {
                route.icon ? (
                  <Flex mb='22px' align='center' justifyContent='space-between' w='100%'>
                    <HStack
                      spacing={_GetActivePath(route) ? '22px' : '26px'}>
                      <Flex
                        w='100%'
                        alignItems='center'
                        justifyContent='center'>
                        <Box
                          color={_GetActivePath(route) ? _activeIconRef : _inactiveColorRef}
                          me='12px'>
                          {route.icon}
                        </Box>
                        <Text
                          me='auto'
                          color={_GetActivePath(route) ? _activeColorRef : 'gray.400'}
                          fontWeight='500'
                          fontSize='16px'
                          textDecor={route.disable ? 'line-through' : 'none'}>
                          {route.name}
                        </Text>
                      </Flex>
                    </HStack>
                    <AccordionIcon
                      ms='auto'
                      color={'secondaryGray.600'}
                      transform={route.icon ? null : 'translateX(-70%)'}
                    />
                  </Flex>
                ) : (
                  <Flex pt='0px' pb='10px' alignItems='center' w='100%'>
                    <HStack
                        spacing={_GetActivePath(route) ? '22px' : '26px'}
                      ps='34px'>
                      <Text
                        me='auto'
                          color={_GetActivePath(route) ? _activeColorRef : _inactiveColorRef}
                        fontWeight='500'
                          fontSize='sm'>
                        {route.name}
                      </Text>
                    </HStack>
                    <AccordionIcon
                      ms='auto'
                      color={'black.300'}
                      transform={null}
                    />
                  </Flex>
                )}
            </AccordionButton>
            <AccordionPanel
              pe={route.icon ? null : '0px'}
              py='12px'
              ps={route.icon ? null : '8px'}>
              <List>
                {
                  route.icon ? _CreateLink(route.items) : _CreateAccordionLinks(route.items)
                }
              </List>
            </AccordionPanel>
          </AccordionItem>
        </Accordion>
      )
    }

    // Return component.
    return (
      <NavLink
        to={!route.disable && route.path} disabled={route.disable} key={key}>
        {route.icon ? (
          <Flex
            align='center'
            justifyContent='space-between'
            w='100%'
            ps='17px'
            mb='22px'>
            <HStack
              spacing={_GetActivePath(route) ? '22px' : '26px'}>
              <Flex w='100%' alignItems='center' justifyContent='center' display='flex'>
                <Box
                  color={_GetActivePath(route) ? _activeIconRef : _inactiveColorRef}
                  me='12px'>
                  {route.icon}
                </Box>
                <Text
                  me='auto'
                  color={_GetActivePath(route) ? _activeColorRef : 'gray.400'}
                  textDecor= {route.disable ? 'line-through' : 'none'}
                  fontWeight='500'
                  fontSize='16px'>
                  {route.name}
                </Text>
              </Flex>
            </HStack>
          </Flex>
        ) : (
          <ListItem ms={null}>
            <Flex ps='34px' alignItems='center' justifyContent='center' display='flex'>
              <Text
                  color={_GetActivePath(route) ? _activeColorRef : _inactiveColorRef}
                fontWeight='500'
                fontSize='16px'
                textDecor= {route.disable ? 'line-through' : 'none'}>
                {route.name}
              </Text>
            </Flex>
          </ListItem>
        )}
      </NavLink>
    )
  })

  // Return component.
  return _CreateLink(routes)
}


/*
 * EXPORTS
 */
export default Index
