/*
 * IMPORTS
 */
import React from 'react' // Npm: React LIBRARY.
import PropTypes from 'prop-types' // Npm: Prop types for React.
import { connect } from 'react-redux' // Npm: React Redux for state management.
import {
  Avatar,
  Box,
  Flex,
  Stack,
  Text,
  useColorModeValue
} from '@chakra-ui/react' // Npm: Chakra UI for design system.


/*
 * SIBLINGS
 */
import Brand from './Brand'
import Links from './Links'


/*
 * OBJECTS
 */
const Index = ({ routes, account }) => {
  // Hook assignment.
  const _textColorRef = useColorModeValue('navy.700', 'white')

  // Return component.
  return (
    <Flex
      direction='column'
      minH='100%'
      height='max-content'>
      <Flex mt='65px' mb='12px' justifyContent='center' alignItems='center' flexDirection='column'>
        <Avatar
          _hover={{ 'cursor': 'pointer' }}
          color={Object.React.CreateColorBasedOnAlphabet(account.displayName).bgColor}
          name={account.displayName}
          borderWidth='2px'
          borderStyle='solid'
          bg='none'
          borderColor={Object.React.CreateColorBasedOnAlphabet(account.displayName).bgColor}
          size='sm'
          w='40px'
          h='40px'
        />
        <Box>
          <Text color={_textColorRef} fontSize='lg' fontWeight='400' mt='10px' textAlign='center'>
            {account?.displayName}
          </Text>
          <Text color='secondaryGray.600' fontWeight='600' fontSize='15px' textAlign='center'>
            {account?.email}
          </Text>
        </Box>
      </Flex>
      <Brand />
      <Stack direction='column' mb='auto' mt='44px' p='12px'>
        <Links routes={routes} />
      </Stack>
    </Flex>
  )
}


/*
 * PROPTYPES
 */
Index.propTypes = {
  routes: PropTypes.array.isRequired,
  account: PropTypes.object.isRequired
}


/*
 * REDUX
 */
const _MapStateToProps = __state => ({ 'account': __state.Account })


/*
 * EXPORT
 */
export default connect(_MapStateToProps)(Index)
