/*
 * IMPORTS
 */
import { gql } from '@apollo/client' // Npm: Apollo client for handling graphql request.


/*
 * EXPORTS
 */
export default gql`
query ContentTranslationReadQuery($smppId: ID, $take: PositiveInt, $skip: Int) {
  ContentTranslationRead(smppId: $smppId, take: $take, skip: $skip) {
    id,
    regex,
    translationKeyword,
    strategy,
    message,
    status
  }
}
`
