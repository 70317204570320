/*
 * IMPORTS
 */
import React from 'react' // Npm: react.js library.
import PropTypes from 'prop-types' // Npm: react.js library.
import JoiBrowser from 'joi-browser' // Npm: Joi for frontend validation.
import _ from 'underscore' // Npm: Underscore.js library.
import { connect } from 'react-redux' // Npm: React Redux for state management.
import { toast } from 'react-hot-toast' // Npm: React hot toast.
import { useLazyQuery, useMutation } from '@apollo/client' // Npm: Apollo client.
import {
  Flex,
  FormControl
} from '@chakra-ui/react' // Npm: Chakra UI components.


/*
 * PACKAGES
 */
import ModalFooter from 'components/ModalFooter'
import { MemoizedInput, MemoizedSelect } from 'components/MemoizedInput'


/*
 * GRAPHS
 */
import CustomerReadQuery from './__query__/index.customer.read.query'
import CustomerUpdateMutation from './__mutation__/index.customer.update.mutation'
import CustomerCreateMutation from './__mutation__/index.customer.create.mutation'


/*
 * OBJECTS
 */
const Index = ({ isOpen, isCreateOnly, onClose, passOn }) => {
  // Hook assignment.
  const [error, setError] = React.useState('')
  const [forceReRender, setForceReRender] = React.useState('')
  const [QueryCustomerRead] = useLazyQuery(CustomerReadQuery, { 'variables': { 'customerId': passOn?.customerId } })
  const [MutationCustomerCreate, MutationCustomerCreateResponse] = useMutation(CustomerCreateMutation)
  const [MutationCustomerUpdate, MutationCustomerUpdateResponse] = useMutation(CustomerUpdateMutation)
  const [editable, setEditable] = React.useState(false) // State to track whether data has been fetched
  const _formDataRef = React.useRef({})

  // Object assignment.
  const _SubmitForm = async e => {
    // Prevent default behavior.
    e.preventDefault()

    // Const assignment.
    const _JoiSchema = JoiBrowser.object({
      'type': JoiBrowser.string().required(),
      'phone': JoiBrowser.string().required()
    }).options({ 'allowUnknown': true })

    // Remove all keys from _formDataRef.current which are undefined.
    _formDataRef.current = _.pick(_formDataRef.current, _.identity)

    // Validate form data.
    const _JoiSchemaValidate = _JoiSchema.validate(_formDataRef.current)

    // If error exists then report failure.
    if (_JoiSchemaValidate.error) return setError(_JoiSchemaValidate.error?.message)

    /*
     * Update _formDataRef with customerId
     * if it doesn't exist and also make sure that mode
     * is not create only.
     */
    if (!isCreateOnly && _.isEmpty(_formDataRef.current?.customerId)) _formDataRef.current = { ..._formDataRef.current, 'customerId': passOn?.customerId }

    // Execute update mutation.
    const _MutationCustomerUpdate = await [isCreateOnly ? MutationCustomerCreate : MutationCustomerUpdate]?.[0]({ 'variables': _.omit(_formDataRef?.current, isCreateOnly ? 'customerId' : void 0) })

    // If mutation caught an exception then report failure.
    if (_MutationCustomerUpdate instanceof Error) return _MutationCustomerUpdate

    // Style Guide.
    toast(_MutationCustomerUpdate?.data?.CustomerUpdate?.message || _MutationCustomerUpdate?.data?.CustomerCreate?.message)

    // Only execute onClose only if response is successful.
    if ('UPDATE_SUCCESSFUL' === _MutationCustomerUpdate?.data?.CustomerUpdate?.status || 'CREATE_SUCCESSFUL' === _MutationCustomerUpdate?.data?.CustomerCreate?.status) return onClose?.()

    // Execute callback if passed any.
    return void 0
  }

  // Event handler.
  React.useEffect(() => {
    // _Async handler.
    const _Async = async () => {
      // Const assignment.
      const _QueryCustomerReadQuery = await QueryCustomerRead({ 'variables': { 'customerId': isCreateOnly ? 'UN_KNOWN' : passOn?.customerId } })

      // If query caught an exception then report failure.
      if (_QueryCustomerReadQuery instanceof Error) return _QueryCustomerReadQuery

      /*
       * If customer details fetch complete then
       * update its value.
       */
      if (_QueryCustomerReadQuery?.data?.CustomerRead) {
        // Update form data.
        _formDataRef.current = {
          'customerId': passOn?.customerId,
          'type': _.first(_QueryCustomerReadQuery?.data?.CustomerRead)?.type,
          'phone': _.first(_QueryCustomerReadQuery && _QueryCustomerReadQuery.data ? _QueryCustomerReadQuery.data.CustomerRead : {})?.phone
        }

        setEditable(true)
        // Update state.

        return setForceReRender(String.random(8))
      }

      // Report failure.
      return void 0
    } ;_Async().catch(i => i)
  }, [passOn, isOpen])

  console.log(error)

  // Return component.
  return (
    <form onSubmit={_SubmitForm} key={forceReRender}>
      <Flex>
        <Flex gap='20px' flexDir='column' w='100%'>
          <Flex gap='20px'>
            <FormControl isRequired>
              <MemoizedSelect
                isRequired={true}
                name='type'
                label='Customer Type'
                placeholder='Select Customer Type'
                onChange={({ target }) => {
                  // Over spreading.
                  const { name, value } = target

                  // Update form data.
                  _formDataRef.current = {
                    ..._formDataRef?.current,
                    [name]: value
                  }
                }}
                error={error}
                disabled={!editable}
                isInvalid={error?.includes('type')}
                options={Object.React.App.enums.CUSTOMER_TYPE.enums?.map(i => i.key)}
                data={_formDataRef?.current?.type}
              />
            </FormControl>
          </Flex>
          <Flex gap='20px'>
            <FormControl isRequired>
              <MemoizedInput
                isRequired={true}
                name='phone'
                label='Phone'
                placeholder='Enter Phone Number'
                onChange={({ target }) => {
                  // Over spreading.
                  const { name, value } = target

                  // Update form data.
                  _formDataRef.current = {
                    ..._formDataRef?.current,
                    [name]: value
                  }
                }}
                error={error}
                disabled={!editable}
                isInvalid={error?.includes('phone')}
                data={_formDataRef?.current?.phone}
              />
            </FormControl>
          </Flex>
        </Flex>
      </Flex>
      <ModalFooter isLoading={MutationCustomerUpdateResponse.loading || MutationCustomerCreateResponse.loading} onSubmit={_SubmitForm} />
    </form>
  )
}


/*
 * PROPTYPES
 */
Index.propTypes = {
  'isCreateOnly': PropTypes.bool,
  'isOpen': PropTypes.bool,
  'onClose': PropTypes.func,
  'passOn': PropTypes.object
}


/*
 * REDUX
 */
const _MapStateToProps = __state => ({ 'passOn': __state.PassOn })


/*
 * EXPORT
 */
export default connect(_MapStateToProps)(Index)
