/*
 * IMPORTS
 */
import React from 'react' // Npm: react.js library.
import Moment from 'moment' // Npm: moment.js library.
import _ from 'underscore' // Npm: underscore.js library.
import { useQuery } from '@apollo/client' // Npm: Apollo client.
import { HiPlusCircle } from 'react-icons/hi2' // Npm: React icons.
import {
  Button,
  Flex,
  Table,
  TableContainer,
  Tbody,
  Td,
  Thead,
  Tooltip,
  Tr,
  useDisclosure
} from '@chakra-ui/react' // Npm: Chakra UI components.


/*
 * PACKAGES
 */
import DownloadToExcel from 'components/DownloadToExcel'
import TableSpinner from 'components/TableSpinner'
import Pagination from 'components/Pagination'
import Modal from 'components/Modal'
import BlockDestinationAddressUpsert from 'components/BlockDestinationAddressUpsert'
import BlockDestinationAddressDelete from 'components/BlockDestinationAddressDelete'
import BlockDestinationAddressDropDownOptions from 'components/BlockDestinationAddressDropDownOptions'


/*
 * GRAPHS
 */
import BlockDestinationAddressReadQuery from './__query__/index.blockDestinationAddress.read.query'


/*
 * STYLES
 */
import {
  buttonStyle,
  cellStyle,
  headerStyle,
  rowStyle
} from './index.style'


/*
 * OBJECTS
 */
const Index = () => {
  // Const assignment.
  const _skipDifference = 7

  // Hook assignment.
  const [skipPage, setSkipPage] = React.useState(0)
  const { 'onOpen': onBlockDestinationAddressUpsertOpen, 'isOpen': isBlockDestinationAddressUpsertOpen, 'onClose': onBlockDestinationAddressCreateClose } = useDisclosure()
  const { 'onOpen': onBlockDestinationAddressDeleteOpen, 'isOpen': isBlockDestinationAddressDeleteOpen, 'onClose': onBlockDestinationAddressDeleteClose } = useDisclosure()
  const _QueryBlockDestinationAddressRead = useQuery(BlockDestinationAddressReadQuery, { 'variables': { 'skip': skipPage * _skipDifference, 'take': _skipDifference }, 'fetchPolicy': 'cache-network' })

  // Return component.
  return (
    <Flex
      fontSize={['Clamp(13px, 1.5vw, 15px)']}
      direction={{ 'base': 'column' }}
      display='flex'
      flex={1}
      overflowX='scroll'>
      <Flex
        display='flex'
        flex={1}
        flexDirection='column'
        gap='1rem'
        bg='white'
        minH='calc(100vh - 160px)'
        maxHeight='100%'
        borderRadius='20px'
        p='20px'>
        <Flex pb='0px' justify='space-between'>
          <Tooltip label='Create new blockDestinationAddress.' fontSize='sm'>
            <Button
              onClick={onBlockDestinationAddressUpsertOpen}
              leftIcon={<HiPlusCircle />}
              style={buttonStyle}>
              Add Block Number
            </Button>
          </Tooltip>
          <DownloadToExcel
            cellsData={_QueryBlockDestinationAddressRead?.data?.BlockDestinationAddressRead?.map((item, __index) => ({
              'S.No.': `${(__index + (_skipDifference * (skipPage))) + 1}.`,
              'CreatedAt': Moment(item.createdAt).format('YYYY-MM-DD HH:mm:ss'),
              'UpdatedAt': Moment(item.updatedAt).format('YYYY-MM-DD HH:mm:ss'),
              'CompanyName': item?.Company?.displayName ?? '-',
              'CompanyReferenceNumber': item.Company?.referenceNumber,
              'Type': item?.type ?? '-',
              'RateEmail': item?.rateEmail?.join(', ') ?? '-',
              'Phone': item.phone ?? '-',
              'BillingCurrency': item.Billing?.currency ?? '-',
              'BillingBalance': item.Billing?.balance ?? '-',
              'BillingAlertAmount': item.Billing?.alertAmount ?? '-',
              'RateName': _.flatten(_.pluck(item.BlockDestinationAddressAccount, 'Rate')).map?.(i => i?.displayName)?.join(', ') ?? '-',
              'RouteName': _.flatten(_.pluck(item.BlockDestinationAddressAccount, 'Route')).map?.(i => i?.displayName)?.join(', ') ?? '-',
              'AccountManager': item.AccountManager ? item.AccountManager : 'Not Assigned'
            }))}
            headersData={[
              'S.No.',
              'CreatedAt',
              'UpdatedAt',
              'CompanyName',
              'CompanyReferenceNumber',
              'Type',
              'RateEmail',
              'Phone',
              'BillingCurrency',
              'BillingBalance',
              'BillingAlertAmount',
              'RateName',
              'RouteName',
              'AccountManager'
            ].map(i => ({ 'key': i, 'label': i }))} />
        </Flex>
        <TableContainer
          maxH='calc(100vh - 260px)'
          flex={1}
          display='flex'
          borderRadius='15px'
          outline='1px solid #C5CFE8'
          overflowY='auto'
        >
          {_QueryBlockDestinationAddressRead.loading || (0 === _QueryBlockDestinationAddressRead?.data?.BlockDestinationAddressRead?.length) ? (
            <Flex w='100%' h='100%' display='flex' alignItems='center' justifyContent='center'>
              <TableSpinner isLoading={true} />
            </Flex>
          ) : 0 === _QueryBlockDestinationAddressRead?.data?.BlockDestinationAddressRead?.length || (0 < _QueryBlockDestinationAddressRead?.data?.BlockDestinationAddressRead?.length && _.every(_.pluck(_QueryBlockDestinationAddressRead?.data?.BlockDestinationAddressRead, 'status'), j => 'READ_SUCCESSFUL' !== j)) ? (
            <Flex w='100%' h='100%' display='flex' alignItems='center' justifyContent='center'>
              <TableSpinner isLoading={false} isEmpty={true} />
            </Flex>
          ) : (
            <Table variant='simple' display='block' size='md'>
              <Thead>
                <Tr style={{ ...headerStyle, position: 'sticky', top: 0, zIndex: 1 }}>

                  <Td style={rowStyle}>S.No.</Td>
                  <Td style={rowStyle}>CreatedAt</Td>
                  <Td style={rowStyle}>UpdatedAt</Td>
                  <Td style={rowStyle}>Phone</Td>
                  <Td style={rowStyle}>Reason</Td>
                  <Td style={rowStyle}>Customer Name</Td>
                  <Td style={rowStyle}>Actions</Td>
                </Tr>
              </Thead>
              <Tbody style={cellStyle}>
                {_QueryBlockDestinationAddressRead?.data?.BlockDestinationAddressRead?.map((item, __index) => (
                  <Tr key={String.random(8)}>
                    <Td
                      style={rowStyle}
                      borderRight='1px solid rgba(216, 227, 252, 1)'>
                      {' '}
                      {`${(__index + (_skipDifference * (skipPage))) + 1}.`}
                    </Td>
                    <Td
                      style={rowStyle}
                      borderRight='1px solid rgba(216, 227, 252, 1)'>
                      {Moment(item.createdAt).format('YYYY-MM-DD hh:mm:ss A')}
                    </Td>
                    <Td
                      style={rowStyle}
                      borderRight='1px solid rgba(216, 227, 252, 1)'>
                      {Moment(item.updatedAt).format('YYYY-MM-DD hh:mm:ss A')}
                    </Td>
                    <Td
                      style={rowStyle}
                      borderRight='1px solid rgba(216, 227, 252, 1)'>
                      {item?.phone ?? '-'}
                    </Td>
                    <Td
                      style={rowStyle}
                      borderRight='1px solid rgba(216, 227, 252, 1)'>
                      {item?.reason ?? '-'}
                    </Td>
                    <Td
                      width='100%'
                      style={rowStyle}
                      borderRight='1px solid rgba(216, 227, 252, 1)'>
                      {item?.Customer?.displayName ?? '-'}
                    </Td>
                    <Td
                      style={rowStyle}>
                      {' '}
                      {
                        <BlockDestinationAddressDropDownOptions
                          blockDestinationAddressId={item.id}
                          onBlockDestinationAddressUpsert={onBlockDestinationAddressUpsertOpen}
                          onBlockDestinationAddressDelete={onBlockDestinationAddressDeleteOpen}
                        />
                      }
                    </Td>
                  </Tr>
                ))}
              </Tbody>
            </Table>
          )}
        </TableContainer>
      </Flex>
      <Pagination itemsPerPage={_skipDifference} totalCount={_.pluck(_QueryBlockDestinationAddressRead?.data?.BlockDestinationAddressRead, '_totalCount')?.[0]} onPageChange={setSkipPage} />
      <Modal
        size='md'
        title='Block Destination Address Upsert'
        isOpen={isBlockDestinationAddressUpsertOpen}
        onClose={onBlockDestinationAddressCreateClose}>
        <BlockDestinationAddressUpsert isCreateOnly={true} />
      </Modal>
      <Modal
        size='md'
        title='Block Destination Address Delete'
        isOpen={isBlockDestinationAddressDeleteOpen}
        onClose={onBlockDestinationAddressDeleteClose}>
        <BlockDestinationAddressDelete />
      </Modal>
    </Flex>
  )
}


/*
 * EXPORT
 */
export default Index

