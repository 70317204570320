/*
 * IMPORTS
 */
import { gql } from '@apollo/client' // Npm: Apollo client for handling graphql request.


/*
 * GRAPHS
 */
const Index = gql`
subscription SystemReadSubscription {
  SystemInformationRead {
    currentLoad,
    memory,
    fsSize
  }
}
`


/*
 * EXPORTS
 */
export default Index
