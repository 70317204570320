/*
 * IMPORTS
 */
import { gql } from '@apollo/client' // Npm: Apollo client for handling graphql request.


/*
 * EXPORTS
 */
export default gql`
mutation VendorAccountCreateMutation(
    $vendorId: ID!
    $displayName: String!
) {
  VendorAccountCreate(
    vendorId: $vendorId
    displayName: $displayName
  ) {
    id,
    message
    status
  }
}
`
