/*
 * IMPORTS
 */
import React from 'react' // Npm: react.js library.
import PropTypes from 'prop-types' // Npm: react.js library.
import JoiBrowser from 'joi-browser' // Npm: Joi for frontend validation.
import _ from 'underscore' // Npm: Underscore.js library.
import { toast } from 'react-hot-toast' // Npm: React hot toast.
import { connect } from 'react-redux' // Npm: React Redux for state management.
import { useLazyQuery, useMutation } from '@apollo/client' // Npm: Apollo client.
import {
  Flex,
  FormControl
} from '@chakra-ui/react' // Npm: Chakra UI components.


/*
 * PACKAGES
 */
import ModalFooter from 'components/ModalFooter'
import { MemoizedInput } from 'components/MemoizedInput'


/*
 * GRAPHS
 */
import AccountReadQuery from './__query__/index.account.read.query'
import AccountUpdateMutation from './__mutation__/index.account.update.mutation'
import AccountCreateMutation from './__mutation__/index.account.create.mutation'


/*
 * OBJECTS
 */
const Index = ({ isOpen, isCreateOnly, onClose, passOn }) => {
  // Hook assignment.
  const [error, setError] = React.useState('')
  const [forceReRender, setForceReRender] = React.useState('')
  const [QueryAccountRead] = useLazyQuery(AccountReadQuery, { 'variables': { 'accountId': passOn?.accountId } })
  const [MutationAccountCreate, MutationAccountCreateResponse] = useMutation(AccountCreateMutation)
  const [MutationAccountUpdate, MutationAccountUpdateResponse] = useMutation(AccountUpdateMutation)
  const _formDataRef = React.useRef({})
  const [editable, setEditable] = React.useState(false) // State to track whether data has been fetched

  // Object assignment.
  const _SubmitForm = async e => {
    // Prevent default behavior.
    e?.preventDefault()

    // Const assignment.
    const _JoiSchema = JoiBrowser.object({
      'accountId': isCreateOnly ? JoiBrowser.string().optional() : JoiBrowser.string().required(),
      'displayName': isCreateOnly ? JoiBrowser.string().required() : JoiBrowser.string().optional(),
      'password': isCreateOnly ? JoiBrowser.string().required() : JoiBrowser.string().optional(),
      'fullName': JoiBrowser.string().optional(),
      'bio': JoiBrowser.string().optional(),
      'email': JoiBrowser.string().required(),
      'website': JoiBrowser.string().optional(),
      'isBlocked': JoiBrowser.boolean().optional()
    }).options({ 'allowUnknown': true })

    // Remove all keys from _formDataRef.current which are undefined.
    _formDataRef.current = _.pick(_formDataRef.current, _.identity)

    // Validate form data.
    const _JoiSchemaValidate = _JoiSchema.validate(_formDataRef.current)

    // If error exists then report failure.
    if (_JoiSchemaValidate.error) return setError(_JoiSchemaValidate.error?.message)

    /*
     * Update _formDataRef with accountId
     * if it is not available on _formDataRef.
     * also make sure that mode is not create only.
     */
    if (!isCreateOnly && _.isEmpty(_formDataRef.current?.accountId)) _formDataRef.current = { ..._formDataRef?.current, 'accountId': passOn?.accountId }

    // Execute update mutation.
    const _MutationAccountUpdate = await [isCreateOnly ? MutationAccountCreate : MutationAccountUpdate]?.[0]({ 'variables': _.omit(_formDataRef?.current, isCreateOnly ? 'accountId' : void 0) })

    // If mutation caught an exception then report failure.
    if (_MutationAccountUpdate instanceof Error) return _MutationAccountUpdate

    // Style Guide.
    toast(_MutationAccountUpdate?.data?.AccountSetting?.message ?? _MutationAccountUpdate?.data?.AccountRegisterWithEmail?.message)

    /*
     * Update error if mutation execution contains
     * status.
     */
    if ('ACCOUNT_DISPLAY_NAME_ALREADY_EXISTS' === _MutationAccountUpdate?.data?.AccountRegisterWithEmail?.status) return setError(_MutationAccountUpdate?.data?.AccountRegisterWithEmail?.message)
    if ('ACCOUNT_WITH_EMAIL_FOUND' === _MutationAccountUpdate?.data?.AccountRegisterWithEmail?.status) return setError(_MutationAccountUpdate?.data?.AccountRegisterWithEmail?.message)
    if ('ACCOUNT_DISPLAY_NAME_ALREADY_EXISTS' === _MutationAccountUpdate?.data?.AccountSetting?.status) return setError(_MutationAccountUpdate?.data?.AccountSetting?.message)
    if ('UPDATE_SUCCESSFUL' === _MutationAccountUpdate?.data?.AccountSetting?.status) return onClose?.()
    if ('CREATE_SUCCESSFUL' === _MutationAccountUpdate?.data?.AccountRegisterWithEmail?.status) return onClose?.()

    // Report void.
    return void 0
  }

  // Event handler.
  React.useEffect(() => {
    // _Async handler.
    const _Async = async () => {
      // Const assignment.
      const _QueryAccountReadQuery = await QueryAccountRead({ 'variables': { 'accountId': isCreateOnly ? 'UN_KNOWN' : passOn?.accountId } })

      // If query caught an exception then report failure.
      if (_QueryAccountReadQuery instanceof Error) return _QueryAccountReadQuery

      /*
       * If account details fetch complete then
       * update its value.
       */
      if (_.first(_QueryAccountReadQuery?.data?.AccountRead)) {
        // Update form data.
        _formDataRef.current = {
          'accountId': passOn?.accountId,
          'displayName': _.first(_QueryAccountReadQuery?.data?.AccountRead)?.displayName,
          'fullName': _.first(_QueryAccountReadQuery?.data?.AccountRead)?.fullName,
          'bio': _.first(_QueryAccountReadQuery?.data?.AccountRead)?.bio,
          'email': _.first(_QueryAccountReadQuery?.data?.AccountRead)?.email,
          'website': _.first(_QueryAccountReadQuery?.data?.AccountRead)?.website,
          'isBlocked': _.first(_QueryAccountReadQuery?.data?.AccountRead)?.isBlocked
        }
        setEditable(true)

        // Update state.
        return setForceReRender(String.random(8))
      }

      // Report failure.
      return void 0
    } ;_Async().catch(i => i)
  }, [passOn, isOpen])

  // Return component.
  return (
    <Flex direction='column' key={forceReRender}>
      {
        isCreateOnly ? (
          <form onSubmit={_SubmitForm}>
            <Flex >
              <Flex gap='20px' flexDir='column' w='100%' >
                <Flex gap='20px' >
                  <FormControl isRequired={true}>
                    <MemoizedInput
                      name='displayName'
                      label='Display Name'
                      isRequired={true}
                      placeholder='Enter Display Name'
                      onChange={({ target }) => {
                        // Over spreading.
                        const { name, value } = target

                        // Update form data.
                        _formDataRef.current = {
                          ..._formDataRef?.current,
                          [name]: value
                        }
                      }}
                      isInvalid={error?.includes('displayName')}
                      error={error}
                      data={_formDataRef?.current?.displayName}
                      disabled={!editable}
                    />
                  </FormControl>
                  <FormControl isRequired={true}>
                    <MemoizedInput
                      name='email'
                      label='Email'
                      placeholder='Enter account email'
                      onChange={({ target }) => {
                        // Over spreading.
                        const { name, value } = target

                        // Update form data.
                        _formDataRef.current = {
                          ..._formDataRef?.current,
                          [name]: value
                        }
                      }}
                      isRequired={true}
                      error={error}
                      isInvalid={error?.includes('email')}
                      data={_formDataRef?.current?.email}
                      disabled={!editable}

                    />
                  </FormControl>
                </Flex>
                <Flex width='calc(50% - 10px)'>
                  <FormControl isRequired={true}>
                    <MemoizedInput
                      name='password'
                      label='Account Password'
                      placeholder='Enter account password'
                      onChange={({ target }) => {
                        // Over spreading.
                        const { name, value } = target

                        // Update form data.
                        _formDataRef.current = {
                          ..._formDataRef?.current,
                          [name]: value
                        }
                      }}
                      isRequired={true}
                      error={error}
                      isInvalid={error?.includes('password')}
                      data={_formDataRef?.current?.password}
                      disabled={!editable}

                    />
                  </FormControl>
                </Flex>
              </Flex>
            </Flex>
            <ModalFooter isLoading={MutationAccountCreateResponse.loading} onSubmit={_SubmitForm} />
          </form>
        ) : (
          <form onSubmit={_SubmitForm}>
            <Flex>
              <Flex gap='20px' flexDir='column' w='100%'>
                <Flex gap='20px'>
                  <FormControl>
                    <MemoizedInput
                      name='displayName'
                      label='Display Name'
                      placeholder='Enter Display Name'
                      onChange={({ target }) => {
                        // Over spreading.
                        const { name, value } = target

                        // Update form data.
                        _formDataRef.current = {
                          ..._formDataRef?.current,
                          [name]: value
                        }
                      }}
                      isInvalid={error?.includes('displayName')}
                      error={error}
                      data={_formDataRef?.current?.displayName}
                      disabled={!editable}

                    />
                  </FormControl>
                  <FormControl>
                    <MemoizedInput
                      name='fullName'
                      label='Full Name'
                      placeholder='Enter Full Name'
                      error={error}
                      onChange={({ target }) => {
                        // Over spreading.
                        const { name, value } = target

                        // Update form data.
                        _formDataRef.current = {
                          ..._formDataRef?.current,
                          [name]: value
                        }
                      }}
                      isInvalid={error?.includes('fullName')}
                      data={_formDataRef?.current?.fullName}
                      disabled={!editable}

                    />
                  </FormControl>
                </Flex>
                <Flex gap='20px'>
                  <FormControl>
                    <MemoizedInput
                      name='bio'
                      label='Account Bio'
                      placeholder='Enter account bio'
                      onChange={({ target }) => {
                        // Over spreading.
                        const { name, value } = target

                        // Update form data.
                        _formDataRef.current = {
                          ..._formDataRef?.current,
                          [name]: value
                        }
                      }}
                      error={error}
                      isInvalid={error?.includes('bio')}
                      data={_formDataRef?.current?.bio}
                      disabled={!editable}

                    />
                  </FormControl>
                  <FormControl>
                    <MemoizedInput
                      name='email'
                      label='Account Email'
                      placeholder='Enter account email'
                      onChange={({ target }) => {
                        // Over spreading.
                        const { name, value } = target

                        // Update form data.
                        _formDataRef.current = {
                          ..._formDataRef?.current,
                          [name]: value
                        }
                      }}
                      error={error}
                      isInvalid={error?.includes('email')}
                      data={_formDataRef?.current?.email}
                      disabled={!editable}

                    />
                  </FormControl>
                </Flex>
                <Flex width='calc(50% - 10px)'>
                  <FormControl>
                    <MemoizedInput
                      name='website'
                      label='Account website'
                      error={error}
                      placeholder='Enter account website'
                      onChange={({ target }) => {
                        // Over spreading.
                        const { name, value } = target

                        // Update form data.
                        _formDataRef.current = {
                          ..._formDataRef?.current,
                          [name]: value
                        }
                      }}
                      isInvalid={error?.includes('website')}
                      data={_formDataRef?.current?.website}
                      disabled={!editable}
                    />
                  </FormControl>
                </Flex>
              </Flex>
            </Flex>
            <ModalFooter isLoading={MutationAccountUpdateResponse.loading} onSubmit={_SubmitForm} />
          </form>
        )
      }
    </Flex>
  )
}


/*
 * PROPTYPES
 */
Index.propTypes = {
  'isCreateOnly': PropTypes.bool,
  'isOpen': PropTypes.bool,
  'onClose': PropTypes.func,
  'passOn': PropTypes.object
}


/*
 * REDUX
 */
const _MapStateToProps = __state => ({ 'passOn': __state.PassOn })


/*
 * EXPORT
 */
export default connect(_MapStateToProps)(Index)
