/*
 * IMPORTS
 */
import React from 'react' // Npm: react.js library.
import Moment from 'moment' // Npm: Moment.js library for date and time formatting.
import _ from 'underscore' // Npm: Underscore.js library for utility functions.
import { HiPlusCircle } from 'react-icons/hi2' // Npm: React icons.
import { useQuery } from '@apollo/client' // Npm: Apollo client for GraphQL queries and mutations.
import {
  Button,
  Flex,
  Table,
  TableContainer,
  Tbody,
  Td,
  Thead,
  Tooltip,
  Tr,
  useDisclosure
} from '@chakra-ui/react' // Npm: Chakra UI components.


/*
 * SIBLINGS
 */
import DownloadToExcel from 'components/DownloadToExcel'
import TableSpinner from 'components/TableSpinner'
import Pagination from 'components/Pagination'
import Modal from 'components/Modal'
import RouteUpsert from 'components/RouteUpsert'
import RouteDelete from 'components/RouteDelete'
import RouteDropDownOptions from 'components/RouteDropDownOptions'
import RouteTerminal from 'components/RouteTerminal'


/*
 * GRAPHS
 */
import RouteReadQuery from './__query__/index.route.read.query'


/*
 * STYLES
 */
import {
  buttonStyle,
  headerStyle,
  rowStyle
} from './index.style'


/*
 * OBJECTS
 */
const Index = () => {
  // Const assignment.
  const _skipDifference = 10

  // Hook assignment.
  const [skipPage, setSkipPage] = React.useState(0)
  const { 'isOpen': isRouteCreateFormOpen, onOpen: onRouteCreateFormOpen, onClose: onRouteCreateFormClose } = useDisclosure()
  const { 'isOpen': isRouteUpdateFormOpen, onOpen: onRouteUpdateFormOpen, onClose: onRouteUpdateFormClose } = useDisclosure()
  const { 'isOpen': isDeleteModalOpen, 'onOpen': onDeleteModalOpen, onClose: onDeleteModalClose } = useDisclosure()
  const { 'isOpen': isRouteTerminalOpen, 'onOpen': onRouteTerminalOpen, 'onClose': onRouteTerminalClose } = useDisclosure()
  const _QueryRouteRead = useQuery(RouteReadQuery, { 'variables': { 'skip': skipPage * _skipDifference, 'take': _skipDifference }, 'fetchPolicy': 'cache-network' })

  // Return component.
  return (
    <Flex
      fontSize={['Clamp(13px, 1.5vw, 15px)']}
      direction={{ 'base': 'column' }}
      display='flex'
      flex={1}
      overflowX='scroll'>
      <Flex
        display='flex'
        flex={1}
        flexDirection='column'
        gap='1rem'
        bg='white'
        minH='calc(100vh - 160px)'
        maxHeight='100%'
        borderRadius='20px'
        p='20px'>
        <Flex pb='0px' justify='space-between'>
          <Tooltip label='Create new vendor.' fontSize='sm'>
            <Button
              onClick={onRouteCreateFormOpen}
              leftIcon={<HiPlusCircle />}
              style={buttonStyle}>
              Create Route Mapping
            </Button>
          </Tooltip>
          <DownloadToExcel
            cellsData={0 === _QueryRouteRead?.data?.RouteRead?.length || !_.every(_.pluck(_QueryRouteRead?.data?.RouteRead, 'status'), i => 'READ_SUCCESSFUL' === i) ? [] : _QueryRouteRead?.data?.RouteRead?.map((item, __index) => ({
              'S.No.': `${(__index + (_skipDifference * (skipPage))) + 1}.`,
              'CreatedAt': item.createdAt,
              'UpdatedAt': item?.updatedAt ?? '-',
              'RouteName': item?.displayName ?? '-',
              'RouteType': item?.type ?? '-',
              'NetworkOperator': _.compact(_.pluck(_.compact(_.pluck(item?.RoutePlan, 'Mcc')), 'network'))?.join(', ') ?? '-',
              'SupportedMnc': _.compact(_.pluck(_.compact(_.pluck(item?.RoutePlan, 'Mcc')), 'mnc'))?.join(', ') ?? '-',
              'CreatedBy': item?.Account?.displayName ?? '-'
            }))}
            headersData={[
              'S.No.',
              'CreatedAt',
              'UpdatedAt',
              'RouteName',
              'RouteType',
              'NetworkOperator',
              'SupportedMnc',
              'CreatedBy'
            ].map(i => ({ 'key': i, 'label': i }))}
          />
        </Flex>
        <TableContainer
          maxH='calc(100vh - 260px)'
          flex={1}
          display='flex'
          borderRadius='15px'
          outline='1px solid #C5CFE8'
          overflowY='auto'
        >
          {
            _QueryRouteRead.loading && (_.isEmpty(_QueryRouteRead?.data?.RouteRead) || (!_.isEmpty(_QueryRouteRead?.data?.RouteRead) && (_.every(_QueryRouteRead?.data?.RouteRead, j => 'READ_SUCCESSFUL' !== j.status) || _.some(_QueryRouteRead?.data?.RouteRead, j => 'ROUTE_PLAN_NOT_FOUND' === j.status)))) ? (
              <Flex w='100%' h='100%' display='flex' alignItems='center' justifyContent='center'>
                <TableSpinner isLoading={true} />
              </Flex>
            ) : _.every(_QueryRouteRead?.data?.RouteRead, j => 'READ_SUCCESSFUL' !== j.status) || _.some(_QueryRouteRead?.data?.RouteRead, j => 'ROUTE_PLAN_NOT_FOUND' === j.status) ? (
              <Flex w='100%' h='100%' display='flex' alignItems='center' justifyContent='center' key={String.random(8)}>
                <TableSpinner isLoading={false} isEmpty={true} />
              </Flex>
            ) : (
              <Table variant='simple' display='block' size='md'>
                <Thead>
                  <Tr style={{ ...headerStyle, position: 'sticky', top: 0, zIndex: 1 }}>

                    <Td style={rowStyle}>S.No.</Td>
                    <Td style={rowStyle}>CreatedAt</Td>
                    <Td style={rowStyle}>UpdatedAt</Td>
                    <Td style={rowStyle}>Route Name</Td>
                    <Td style={rowStyle}>Network Operators</Td>
                    <Td style={rowStyle}>Supported Mnc</Td>
                    <Td style={rowStyle}>Created By</Td>
                    <Td style={rowStyle}>Action</Td>
                  </Tr>
                </Thead>
                <Tbody>
                  {_QueryRouteRead?.data?.RouteRead?.map?.((item, index) => (
                    <Tr height='10px' key={index} textAlign='center'>
                      <Td
                        style={rowStyle}
                        textAlign='center'
                        borderRight='1px solid #D8E3FC'>
                        {`${(index + (_skipDifference * (skipPage))) + 1}.`}
                      </Td>
                      <Td
                        style={rowStyle}
                        borderRight='1px solid #D8E3FC'>
                        {Moment(item.createdAt).format('YYYY-MM-DD hh:mm:ss A')}
                      </Td>
                      <Td
                        style={rowStyle}
                        borderRight='1px solid #D8E3FC'>
                        {Moment(item.updatedAt).format('YYYY-MM-DD hh:mm:ss A')}
                      </Td>
                      <Td
                        style={rowStyle}
                        borderRight={'1px solid #D8E3FC'}>
                        {item?.displayName ?? '-'}
                      </Td>
                      <Td
                        style={rowStyle}
                        borderRight={'1px solid #D8E3FC'}
                        maxWidth='210px'
                        overflowX='auto'>
                        {_.compact(_.flatten(_.pluck(_.compact(_.pluck(item?.RoutePlan, 'Mnc')), 'network')))?.join(', ') ?? '-'}
                      </Td>
                      <Td
                        style={rowStyle}
                        borderRight={'1px solid #D8E3FC'}
                        maxWidth='210px'
                        overflowX='auto'>
                        {_.compact(_.flatten(_.pluck(_.compact(_.pluck(item?.RoutePlan, 'Mnc')), 'mnc')))?.join(', ') ?? '-'}
                      </Td>
                      <Td
                        style={rowStyle}
                        borderRight={'1px solid #D8E3FC'}
                        width='100%'>
                        {item?.Account?.displayName ?? '-'}
                      </Td>
                      <Td
                        style={rowStyle}
                        borderRight='1px solid #D8E3FC'>
                        <RouteDropDownOptions
                          type={item.type}
                          routeId={item.id}
                          onRouteDelete={onDeleteModalOpen}
                          onRouteUpdate={onRouteUpdateFormOpen}
                          onRouteTerminal={onRouteTerminalOpen}
                        />
                      </Td>
                    </Tr>
                  ))}
                </Tbody>
              </Table>
            )}
        </TableContainer>
      </Flex>
      <Pagination itemsPerPage={_skipDifference} totalCount={_.pluck(_QueryRouteRead?.data?.RouteRead, '_totalCount')?.[0]} onPageChange={setSkipPage} />
      <Modal
        size='md'
        title='Route Create'
        isOpen={isRouteCreateFormOpen}
        onClose={onRouteCreateFormClose}>
        <RouteUpsert isCreateOnly={true} />
      </Modal>
      <Modal
        size='md'
        title='Route Update'
        isOpen={isRouteUpdateFormOpen}
        onClose={onRouteUpdateFormClose}>
        <RouteUpsert />
      </Modal>
      <Modal
        size='md'
        title='Route Delete'
        isOpen={isDeleteModalOpen}
        onClose={onDeleteModalClose}>
        <RouteDelete />
      </Modal>
      <Modal
        size='2xl'
        bodyProps={{ 'p': '5px' }}
        title='Connect Mapping Terminal'
        isOpen={isRouteTerminalOpen}
        onClose={onRouteTerminalClose}>
        <RouteTerminal />
      </Modal>
    </Flex>
  )
}


/*
 * EXPORT
 */
export default Index
