/*
 * IMPORTS
 */
import React from 'react' // Npm: react.js library.
import PropTypes from 'prop-types' // Npm: react.js library.
import { connect } from 'react-redux' // Npm: React Redux for state management.
import { useHistory } from 'react-router-dom' // Npm: React router dom.
import { HiBanknotes, HiBuildingStorefront, HiPencil, HiTrash, HiUsers, HiWrenchScrewdriver } from 'react-icons/hi2' // Npm: React icons.
import {
  Button,
  Flex,
  Menu,
  MenuButton,
  MenuItem,
  MenuList
} from '@chakra-ui/react' // Npm: Chakra UI components.


/*
 * STYLES
 */
import { menuStyle } from './index.style'


/*
 * OBJECTS
 */
const Index = ({
  customerId,
  type,
  onCustomerUpdate,
  onCustomerDelete,
  onCreditManagementUpsert,
  onAttachAccountManager,
  PassOnUpdate
}) => {
  // Hook assignment.
  const _historyRef = useHistory()

  // Return component.
  return (
    <Flex>
      <Menu placement='left'>
        <MenuButton
          as={Button}
          w='100%'
          borderRadius='8px'
          color='brand.500'
          _hover={{ 'bg': 'brand.500', 'color': 'white' }}
          _active={{ 'bg': 'brand.500', 'color': 'white' }}
          _focused={{ 'bg': 'brand.500', 'color': 'white' }}
          bg='transparent'>
          <HiWrenchScrewdriver size={18} />
        </MenuButton>
        <MenuList
          style={menuStyle}
          maxHeight='400px'
          overflow='auto'>
          <MenuItem
            gap='10px'
            display='flex'
            pl='20px'
            pr='20px'
            color='gray.500'
            onClick={() => {
              // Push customer id to url.
              PassOnUpdate({ customerId, type })

              // Execute handler.
              onAttachAccountManager()
            }}
            minH='40px'>
            <HiUsers size={18} />
            <span>Assign Account Manager</span>
          </MenuItem>
          <MenuItem
            gap='10px'
            display='flex'
            pl='20px'
            pr='20px'
            color='gray.500'
            onClick={() => {
              // Push customer id to url.
              PassOnUpdate({ customerId, type })

              // Execute handler.
              onCreditManagementUpsert()
            }}
            minH='40px'>
            <HiBanknotes size={18} />
            <span>Credit Management</span>
          </MenuItem>
          <MenuItem
            gap='10px'
            display='flex'
            pl='20px'
            pr='20px'
            color='gray.500'
            onClick={() => {
              // Push customer id to url.
              PassOnUpdate({ customerId, type })

              // Execute handler.
              onCustomerUpdate()
            }}
            minH='40px'>
            <HiPencil size={18} />
            <span>Customer Mapping Edit</span>
          </MenuItem>
          <MenuItem
            gap='10px'
            display='flex'
            pl='20px'
            pr='20px'
            color='gray.500'
            onClick={() => {
              // Push customer id to url.
              PassOnUpdate({ customerId, type })

              // Execute handler.
              onCustomerDelete()
            }}
            minH='40px'>
            <HiTrash />
            <span>Customer Mapping Delete</span>
          </MenuItem>
          <MenuItem
            isDisabled={'RESELLER' === type}
            onClick={() => {
              // Push customer id to url.
              PassOnUpdate({ customerId, type })

              // Execute handler.
              _historyRef.push('/customer/mapping/connect')
            }}
            gap='10px'
            display='flex'
            pl='20px'
            pr='20px'
            color='gray.500'
            minH='40px'>
            <HiBuildingStorefront size={18} />
            <span>Connect Mapping</span>
          </MenuItem>
        </MenuList>
      </Menu>
    </Flex>
  )
}


/*
 * PROPTYPES
 */
Index.propTypes = {
  'type': PropTypes.oneOf([...Object.React.App.enums.CUSTOMER_TYPE.enums, ...Object.React.App.enums.VENDOR_TYPE.enums]?.map(i => i.key)),
  'onCustomerUpdate': PropTypes.func,
  'onCustomerDelete': PropTypes.func,
  'onRateAndRoutePlanAttach': PropTypes.func,
  'customerId': PropTypes.string,
  'PassOnUpdate': PropTypes.func,
  'onAttachAccountManager': PropTypes.func
}


/*
 * REDUX
 */
const _MapDispatchToProps = __dispatch => ({
  'PassOnUpdate': u => __dispatch({ 'type': 'PASS_ON_UPDATE', 'PassOn': u })
})


/*
 * EXPORT
 */
export default connect(void 0, _MapDispatchToProps)(Index)
