/*
 * IMPORTS
 */
import React from 'react' // Npm: react.js library.
import Moment from 'moment' // Npm: Moment.js library.
import _ from 'underscore' // Npm: Underscore.js library.
import { HiPlusCircle } from 'react-icons/hi2' // Npm: React icons.
import { useMutation, useQuery } from '@apollo/client' // Npm: Apollo client.
import {
  Button,
  Checkbox,
  Flex,
  Select,
  Table,
  TableContainer,
  Tbody,
  Td,
  Thead,
  Tooltip,
  Tr,
  useDisclosure
} from '@chakra-ui/react' // Npm: Chakra UI components.


/*
 * PACKAGES
 */
import TableSpinner from 'components/TableSpinner'
import DownloadToExcel from 'components/DownloadToExcel'
import Pagination from 'components/Pagination'
import Modal from 'components/Modal'
import AccountLogout from 'components/AccountLogout'
import AccountUpsert from 'components/AccountUpsert'
import AccountDropDownOptions from 'components/AccountDropDownOptions'


/*
 * GRAPHS
 */
import AccountSettingMutation from './__mutation__/index.account.setting.mutation'
import AccountIsBlockedWithEmailOrAccountIdMutation from './__mutation__/index.account.isBlockedWithEmailOrAccountId.mutation'
import AccountReadQuery from './__query__/index.account.read.query'


/*
 * STYLES
 */
import {
  buttonStyle,
  cellStyle,
  headerStyle,
  rowStyle
} from './index.style'


/*
 * OBJECTS
 */
const Index = () => {
  // Const assignment.
  const _skipDifference = 10

  // Hook assignment.
  const [skipPage, setSkipPage] = React.useState(0)
  const { 'onOpen': onAccountLogoutOpen, 'isOpen': isAccountLogoutOpen, 'onClose': onAccountLogoutClose } = useDisclosure()
  const { 'onOpen': onAccountUpdateOpen, 'isOpen': isAccountUpdateOpen, 'onClose': onAccountUpdateClose } = useDisclosure()
  const { 'onOpen': onAccountCreateOpen, 'isOpen': isAccountCreateOpen, 'onClose': onAccountCreateClose } = useDisclosure()
  const [MutationAccountSetting] = useMutation(AccountSettingMutation)
  const [MutationAccountIsBlockedWithEmailOrAccountId] = useMutation(AccountIsBlockedWithEmailOrAccountIdMutation)
  const _QueryAccountRead = useQuery(AccountReadQuery, { 'variables': { 'skip': skipPage * _skipDifference, 'take': _skipDifference }, 'fetchPolicy': 'cache-and-network' })

  // Return Component.
  return (
    <Flex
      fontSize={['Clamp(13px, 1.5vw, 15px)']}
      direction={{ 'base': 'column' }}
      display='flex'
      flex={1}
      overflowX='scroll'>
      <Flex
        display='flex'
        flex={1}
        flexDirection='column'
        gap='1rem'
        bg='white'
        minH='calc(100vh - 160px)'
        maxHeight='100%'
        borderRadius='20px'
        p='20px'>
        <Flex pb='0px' justify='space-between'>
          <Tooltip label='Create new account.' fontSize='sm'>
            <Button
              w='170px'
              onClick={onAccountCreateOpen}
              leftIcon={<HiPlusCircle />}
              style={buttonStyle}>
            New Account
            </Button>
          </Tooltip>
          <DownloadToExcel
            cellsData={_QueryAccountRead?.data?.AccountRead?.map((item, __index) => ({
              'S.No.': `${(__index + (_skipDifference * (skipPage))) + 1}.`,
              'CreatedAt': item.createdAt ?? '-',
              'UpdatedAt': item.updatedAt ?? '-',
              'AccountName': item.displayName,
              'Email': item?.email ?? '-',
              'AccountStatus': item?.accountType ?? '-',
              'ActiveAs': item?.activeAs ?? '-',
              'Ban': item.isBlocked ?? '-'
            }))}
            headersData={[
              'S.No.',
              'CreatedAt',
              'UpdatedAt',
              'AccountName',
              'Email',
              'AccountStatus',
              'ActiveAs',
              'Ban'
            ].map(k => ({ 'key': k, 'label': k }))} />
        </Flex>
        <TableContainer
          maxH='calc(100vh - 260px)'
          flex={1}
          display='flex'
          borderRadius='15px'
          outline='1px solid #C5CFE8'
          overflowY='auto'>
          {_QueryAccountRead?.loading && (_.isEmpty(_QueryAccountRead?.data?.AccountRead) || 0 === _QueryAccountRead?.data?.AccountRead?.length) ? (
            <Flex w='100%' h='100%' display='flex' alignItems='center' justifyContent='center'>
              <TableSpinner isLoading={true} />
            </Flex>
          ) : 0 === _QueryAccountRead?.data?.AccountRead?.length ? (
            <Flex w='100%' h='100%' display='flex' alignItems='center' justifyContent='center'>
              <TableSpinner isLoading={false} isEmpty={true} />
            </Flex>
          ) : (
            <Table variant='simple' display='block' w='auto'>
              <Thead
                color='rgba(141, 151, 182, 1)'
                fontStyle='DM Sans'
                fontWeight='500'
                fontSize='18px'
                lineHeight='24px'>
                <Tr style={{ ...headerStyle, position: 'sticky', top: 0, zIndex: 1 }}>

                  <Td style={rowStyle}>S.No.</Td>
                  <Td style={rowStyle}>CreatedAt</Td>
                  <Td style={rowStyle}>UpdatedAt</Td>
                  <Td style={rowStyle}>Account Name</Td>
                  <Td style={rowStyle}>Email</Td>
                  <Td style={rowStyle}>Account Type</Td>
                  <Td style={rowStyle}>Account Status</Td>
                  <Td style={rowStyle}>isBlocked</Td>
                  <Td style={rowStyle}>Actions</Td>
                </Tr>
              </Thead>
              <Tbody
                style={cellStyle}>
                {_QueryAccountRead?.data?.AccountRead.map(({ id, displayName, email, accountType, activeAs, createdAt, updatedAt, isBlocked }, __index) => (
                  <Tr key={id}>
                    <Td
                      style={rowStyle}
                      borderRight='1px solid rgba(216, 227, 252, 1)'>
                      {' '}
                      {`${(__index + (_skipDifference * (skipPage))) + 1}.`}
                    </Td>
                    <Td
                      style={rowStyle}
                      borderRight='1px solid rgba(216, 227, 252, 1)'>
                      {Moment(createdAt).format('YYYY-MM-DD hh:mm:ss A')}
                    </Td>
                    <Td
                      style={rowStyle}
                      borderRight='1px solid rgba(216, 227, 252, 1)'>
                      {Moment(updatedAt).format('YYYY-MM-DD hh:mm:ss A')}
                    </Td>
                    <Td
                      style={rowStyle}
                      borderRight='1px solid rgba(216, 227, 252, 1)'>
                      {displayName}
                    </Td>
                    <Td
                      style={rowStyle}
                      borderRight='1px solid rgba(216, 227, 252, 1)'>
                      {email}
                    </Td>
                    <Td
                      style={rowStyle}
                      borderRight='1px solid rgba(216, 227, 252, 1)'>
                      <Select
                        value={accountType}
                        onChange={e => MutationAccountSetting({ 'variables': { 'accountId': id, 'accountType': e.target.value || 'ACCOUNT_DEFAULT' } })}
                        borderRadius='10px'
                        width='max-content'
                        height='36px'
                        fontSize='15px'
                        fontWeight='500'
                        border='1px solid rgba(216, 227, 252, 1)'>
                        <option value='ACCOUNT_ADMIN'>ACCOUNT_ADMIN</option>
                        <option value='ACCOUNT_DEFAULT'>ACCOUNT_DEFAULT</option>
                      </Select>
                    </Td>
                    <Td
                      style={rowStyle}
                      borderRight='1px solid rgba(216, 227, 252, 1)'>
                      {activeAs}
                    </Td>
                    <Td
                      style={rowStyle}
                      textAlign='center'
                      borderRight='1px solid rgba(216, 227, 252, 1)'>
                      <Checkbox isChecked={isBlocked} onChange={e => MutationAccountIsBlockedWithEmailOrAccountId({ 'variables': { 'accountId': id, 'isBlocked': e.nativeEvent?.target?.checked } }).then(() => _QueryAccountRead.refetch())}
                      />
                    </Td>
                    <Td
                      style={rowStyle}>
                      {' '}
                      {
                        <AccountDropDownOptions
                          accountId={id}
                          onAccountUpdate={onAccountUpdateOpen}
                          onAccountLogout={onAccountLogoutOpen}
                        />
                      }
                    </Td>
                  </Tr>
                ))}
              </Tbody>
            </Table>
          )}
        </TableContainer>
      </Flex>
      <Pagination itemsPerPage={_skipDifference} totalCount={_.pluck(_QueryAccountRead?.data?.AccountRead, '_totalCount')?.[0]} onPageChange={setSkipPage} />
      <Modal
        size='lg'
        title='Account Create'
        isOpen={isAccountCreateOpen}
        onClose={onAccountCreateClose}>
        <AccountUpsert isCreateOnly={true} />
      </Modal>
      <Modal
        size='lg'
        title='Account Update'
        isOpen={isAccountUpdateOpen}
        onClose={onAccountUpdateClose}>
        <AccountUpsert />
      </Modal>
      <Modal
        size='md'
        title='Account Logout'
        isOpen={isAccountLogoutOpen}
        onClose={onAccountLogoutClose}>
        <AccountLogout />
      </Modal>
    </Flex>
  )
}


/*
 * EXPORT
 */
export default Index
