/*
 * IMPORTS
 */
import { gql } from '@apollo/client' // Npm: Apollo client for handling graphql request.


/*
 * EXPORTS
 */
export default gql`
mutation MncCreateMutation(
  $mccId: ID!,
  $network: String!,
  $mnc: String!,
) {
  MncCreate(
    mccId: $mccId,
    network: $network,
    mnc: $mnc,
  ) {
    id
    message
    status
  }
}
`
