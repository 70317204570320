/*
 * IMPORTS
 */
import { gql } from '@apollo/client' // Npm: Apollo client for handling graphql request.


/*
 * EXPORTS
 */
export default gql`
mutation AccountUpdateMutation(
  $accountId: ID!,
  $displayName: String,
  $fullName: String,
  $bio: String,
  $email: EmailAddress,
  $website: URL,
  $isBlocked: Boolean,
) {
  AccountSetting(
    accountId: $accountId,
    displayName: $displayName,
    fullName: $fullName,
    bio: $bio,
    email: $email,
    website: $website,
    isBlocked: $isBlocked,
  ) {
    message
    status
  }
}
`
