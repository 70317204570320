/*
 * IMPORTS
 */
import { gql } from '@apollo/client' // Npm: Apollo client for handling graphql request.


/*
 * EXPORTS
 */
export default gql`
mutation MncUpdateMutation(
  $mncId: ID!,
  $mnc: String!,
  $network: String!
) {
  MncUpdate(
    mncId: $mncId,
    network: $network,
    mnc: $mnc
  ) {
    id
    message
    status
  }
}
`
