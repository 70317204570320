/*
 * IMPORTS
 */
import React from 'react' // Npm: react.js library.
import PropTypes from 'prop-types' // Npm: react.js library.
import { Flex } from '@chakra-ui/react' // Npm: Chakra UI components.


/*
 * OBJECTS
 */
const HSeparator = ({ rest }) => <Flex h='1px' w='100%' bg='rgba(135, 140, 189, 0.3)' {...rest}></Flex>
const VSeparator = ({ rest }) => <Flex w='1px' bg='rgba(135, 140, 189, 0.3)' {...rest}></Flex>


/*
 * PROPTYPES
 */
HSeparator.propTypes = {
  'rest': PropTypes.object
}
VSeparator.propTypes = {
  'rest': PropTypes.object
}


/*
 * EXPORTS
 */
export { HSeparator, VSeparator }
