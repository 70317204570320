/*
 * IMPORTS
 */
import { gql } from '@apollo/client' // Npm: Apollo client.


/*
 * EXPORTS
 */
export default gql`
mutation VendorAccountAddSenderIdMutation($vendorAccountId: ID!, $senderId: String!) {
    VendorAccountAddSenderId(vendorAccountId: $vendorAccountId, senderId: $senderId) {
        message
        status
    }
} 
`

