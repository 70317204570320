/*
 * IMPORTS
 */
import React from 'react' // Npm: react.js library.
import PropTypes from 'prop-types' // Npm: react.js library.
import _ from 'underscore' // Npm: underscore.js library.
import { TagsInput } from 'react-tag-input-component' // Npm: React tags input.
import { useDetectClickOutside } from 'react-detect-click-outside' // Npm: React detect click outside.
import {
  Button,
  Flex,
  FormControl,
  FormErrorMessage,
  FormHelperText,
  FormLabel,
  Input,
  Select,
  Text,
  useColorModeValue
} from '@chakra-ui/react' // Npm: Chakra UI components.


/*
 * STYLES
 */
import './index.css'
import { errorStyle, inputStyle, labelStyle } from './index.style'


/*
 * OBJECTS
 */
const MemoizedInput = ({
  name,
  label,
  placeholder,
  containerStyle,
  type,
  color,
  data,
  onChange,
  error,
  isRequired,
  isInvalid,
  disabled,
  isMultiple,
  ...props
}) => (
  <Flex width='100%' direction='column'>
    <FormControl isRequired={isRequired} color={color} isInvalid={isInvalid}>
      <Flex style={containerStyle} direction='column'>
        <FormControl
          className={isInvalid ? 'inputInvalid' : void 0}
          color={color}
          isInvalid={isInvalid}>
          <FormLabel
            style={labelStyle}>
            {isRequired ? <Text display='flex' flexDirection='row'>{label}<Text color='red'>*</Text></Text> : <Text>{label}</Text>}
          </FormLabel>
          {
            isMultiple ? (
              <TagsInput
                fontSize={['Clamp(13px, 1.5vw, 15px)']}
                style={isInvalid ? errorStyle : inputStyle}
                placeHolder={placeholder}
                value={data ?? []}
                name={name}
                onChange={i => onChange({ 'target': { name, 'value': i } })}
                type={type}
                disabled={disabled}
                separator={','}
                {...props}
              />
            ) : (
              <Input
                fontSize={['Clamp(13px, 1.5vw, 15px)']}
                style={isInvalid ? errorStyle : inputStyle}
                placeholder={placeholder}
                _placeholder={{ 'color': color ?? 'rgba(163, 174, 208, 1)' }}
                defaultValue={data}
                name={name}
                onChange={onChange}
                type={type}
                disabled={disabled}
                {...props}
              />
            )
          }
          {isInvalid ? (<FormErrorMessage>{error ? error : `${label} is required.`}</FormErrorMessage>) : (<FormHelperText />)}
        </FormControl>
      </Flex>
    </FormControl>
  </Flex>
)
const MemoizedSelect = ({
  name,
  label,
  disabled,
  containerStyle,
  color,
  placeholder,
  onChange,
  isInvalid,
  isRequired,
  data,
  options = [],
  ...props
}) => (
  <Flex style={containerStyle} direction='column' w='100%'>
    <FormControl color={color} isInvalid={isInvalid}>
      <FormLabel
        style={labelStyle}>
        {isRequired ? <Text display='flex' flexDirection='row'>{label}<Text color='red'>*</Text></Text> : <Text>{label}</Text>}
      </FormLabel>
      <Select
        style={isInvalid ? errorStyle : inputStyle}
        value={data}
        placeholder={placeholder}
        name={name}
        color={color}
        disabled={disabled}
        onChange={onChange}
        _placeholder={{ color }}
        {...props}>
        {options.map((item, index) => (
          <option key={index}>{item}</option>
        ))}
      </Select>
    </FormControl>
  </Flex>
)
const MemoizedSearchSelect = ({
  name,
  label,
  disabled,
  containerStyle,
  color,
  placeholder,
  onChange,
  isInvalid,
  isRequired,
  data,
  options = [],
  ...props
}) => {
  // Hook assignment.
  const [selectedOption, setSelectedOption] = React.useState('')
  const [search, setSearch] = React.useState([])
  const [value, setValue] = React.useState(data)
  const _selectedOptionRef = useDetectClickOutside({ onTriggered: () => setSearch([]) })
  const _shadow = useColorModeValue('14px 17px 40px 4px rgba(112, 144, 176, 0.18)', '14px 17px 40px 4px rgba(112, 144, 176, 0.06)')

  // Event handler.
  React.useEffect(() => {
    // Clear selected option on data change.
    setSelectedOption('')
  }, [value])

  // Return component.
  return (
    <Flex style={containerStyle} direction='column'>
      <FormControl isRequired={isRequired} color={color} isInvalid={isInvalid}>
        <FormLabel style={labelStyle} color={color}>{label}</FormLabel>
        <Input
          style={isInvalid ? errorStyle : inputStyle}
          placeholder={placeholder}
          _placeholder={{ 'color': color ?? 'rgba(163, 174, 208, 1)' }}
          defaultValue={selectedOption}
          name={name}
          disabled={disabled}
          value={_.isEmpty(selectedOption) ? value : selectedOption}
          onChange={i => {
            // Update value.
            setValue(i.target.value)

            // Update search.
            setSearch(options.filter(j => j.toLowerCase().startsWith(i.target.value.toLowerCase())))
          }}
          {...props}
        />
        <Flex ref={_selectedOptionRef} maxHeight='150px' overflowY='scroll' bg='white' flexDirection='column' flex={1} marginTop={5} boxShadow={_shadow} position='absolute' width='100%' zIndex={1000} borderRadius={18}>
          {
            !_.isEmpty(value) && search?.map((item, index) => (
              <Flex key={index} onClick={item.onClick} borderBottom='0.5px solid' borderBottomColor='gray.100' borderWidth={index === (search.length - 1) ? 0 : 0.5}>
                <Button
                  fontSize={15}
                  height={45}
                  fontWeight={500}
                  bg='rgba(163, 174, 208, 0.34)'
                  color='gray.600'
                  justifyContent='start'
                  width='100%'
                  borderRadius={0}
                  onClick={() => {
                    // Update selected option.
                    setSelectedOption(item)

                    // Update value.
                    setSearch([])

                    // Call all change with updates.
                    onChange({ 'target': { 'value': item } })
                  }}
                  _hover={{ 'bg': 'gray.100', 'color': 'gray.500' }}
                  _active={{ 'bg': 'gray.100' }}>
                  {item}
                </Button>
              </Flex>
            ))
          }
        </Flex>
      </FormControl>
    </Flex>
  )
}



/*
 * PROPTYPES
 */
MemoizedInput.propTypes = {
  'name': PropTypes.string,
  'label': PropTypes.string,
  'placeholder': PropTypes.string,
  'type': PropTypes.string,
  'data': PropTypes.any,
  'onChange': PropTypes.func,
  'isInvalid': PropTypes.bool,
  'error': PropTypes.string,
  'isRequired': PropTypes.bool,
  'disabled': PropTypes.bool,
  'containerStyle': PropTypes.object,
  'color': PropTypes.string,
  'isMultiple': PropTypes.bool
}
MemoizedSearchSelect.propTypes = {
  'name': PropTypes.string,
  'label': PropTypes.string,
  'placeholder': PropTypes.string,
  'onChange': PropTypes.func,
  'isInvalid': PropTypes.bool,
  'data': PropTypes.any,
  'options': PropTypes.array,
  'props': PropTypes.object,
  'isRequired': PropTypes.bool,
  'disabled': PropTypes.bool,
  'containerStyle': PropTypes.object,
  'color': PropTypes.string
}
MemoizedSelect.propTypes = {
  'name': PropTypes.string,
  'label': PropTypes.string,
  'placeholder': PropTypes.string,
  'onChange': PropTypes.func,
  'isInvalid': PropTypes.bool,
  'data': PropTypes.any,
  'options': PropTypes.array,
  'props': PropTypes.object,
  'isRequired': PropTypes.bool,
  'disabled': PropTypes.bool,
  'containerStyle': PropTypes.object,
  'color': PropTypes.string
}


/*
 * EXPORTS
 */
export { MemoizedInput, MemoizedSelect, MemoizedSearchSelect }
