/*
 * IMPORTS
 */
import { gql } from '@apollo/client' // Npm: Apollo client for handling graphql request.


/*
 * EXPORTS
 */
export default gql`
query BlockDestinationAddressReadQuery($take: PositiveInt, $skip: Int) @live {
  BlockDestinationAddressRead(take: $take, skip: $skip) {
    id,
    createdAt,
    updatedAt,
    message,
    phone,
    reason,
    _totalCount,
    status,
    Customer {
      id,
      displayName
    }
  }
}
`
